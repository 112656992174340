import { Button, Heading, Icon, Link, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text, VStack } from "@chakra-ui/react"
import { faQrcode } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { QRCodeSVG } from "qrcode.react"
import React from "react"
import { REACT_APP_TELEGRAM_BOT_URL } from "../../constants"
import { ExtendedStudentFragment } from "../../graphql"

export type SubscribePopoverProps = {
	student: ExtendedStudentFragment
}

export const SubscribePopover: React.FC<SubscribePopoverProps> = ({ student }) => {
	return (
		<Popover>
			<PopoverTrigger>
				<Button
					size="xs"
					variant="outline"
					colorScheme="orange"
					rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faQrcode} {...props} />} />}
				>
					Notifications
				</Button>
			</PopoverTrigger>
			<PopoverContent _focus={{ shadow: "xl", outline: "none", border: "none" }} border="none" outline="none" bgColor="white" rounded="xl" shadow="xl">
				<PopoverBody>
					<VStack w="full" align="center" py="4" spacing={2}>
						<Heading fontSize="xs" textAlign="center" fontWeight="semibold">
							Scan this QR code on your phone to subscribe to notifications for {student.name.split(" ")[0]} on Telegram
						</Heading>
						<QRCodeSVG
							value={`${REACT_APP_TELEGRAM_BOT_URL}?start=${student.username}`}
							size={180}
							imageSettings={{
								src: "/learntank-logo-2.png",
								excavate: false,
								height: 56,
								width: 56,
							}}
						/>

						<Text fontSize="xs" textAlign="center">
							or{" "}
							<Link href={`${REACT_APP_TELEGRAM_BOT_URL}?start=${student.username}`} isExternal color="primary.500" fontWeight="bold">
								click here
							</Link>
						</Text>
					</VStack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
