import { AspectRatio, Avatar, Center, Container, Flex, Heading, HStack, Icon, Spinner, Tag, TagLabel, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { Watch } from "react-feather"
import { useNavigate, useParams } from "react-router-dom"
import { DashboardHeader, Nav } from "../../components"
import { GRADIENT } from "../../constants"
import { useMeQuery, useStudentByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const Student: React.FC = () => {
	const { studentId = "" } = useParams<{ studentId: string }>()

	const [{ data: meData }] = useMeQuery()

	const [{ data, fetching, error }] = useStudentByIdQuery({
		variables: { studentId },
		pause: meData?.me?._id === studentId,
	})

	const navigate = useNavigate()

	useEffect(() => {
		if (meData?.me?._id === studentId) {
			navigate("/profile", { replace: true })
		}
	}, [meData, studentId])

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semisemibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.studentById ? (
						<VStack w="full" align="stretch">
							<HStack w="full" align="flex-start" spacing={4}>
								<AspectRatio w="full" maxW={{ base: "24", md: "36" }} ratio={1}>
									<Avatar src={data.studentById.picture?.url} name={data.studentById.name} />
								</AspectRatio>
								<VStack align="flex-start">
									<VStack align="flex-start" spacing="0">
										<Heading as="h1" fontSize="xl" color="text.400">
											{data.studentById.name}
										</Heading>
										<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.400" maxW="xs">
											@{data.studentById.username}
										</Heading>
									</VStack>

									<Flex flexWrap="wrap">
										<Tag m="1" colorScheme="purple" variant="solid" rounded="xl" size="sm">
											<TagLabel>
												{data.studentById.class.name} - {data.studentById.classSection.name}
											</TagLabel>
										</Tag>
										<Tag m="1" colorScheme="purple" variant="solid" rounded="xl" size="sm">
											<HStack>
												<TagLabel>{data.studentById.tag ? data.studentById.tag.tagDecimalId : "No Tag"}</TagLabel> <Icon as={Watch} />
											</HStack>
										</Tag>
									</Flex>
								</VStack>
							</HStack>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semisemibold" color="text.400">
								Couldn&apos;t find the student.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
