/* eslint-disable @typescript-eslint/ban-ts-comment */
import { cacheExchange as cE } from "@urql/exchange-graphcache"
import startOfDay from "date-fns/startOfDay"
import { betterQueryUpdate } from "../../utils"
import {
	AllFeedPostsDocument,
	AllFeedPostsQuery,
	AllFeedPostsQueryVariables,
	AllPaytankOrdersDocument,
	AllPaytankOrdersQuery,
	AllPaytankOrdersQueryVariables,
	AuthorTypes,
	BlockTagForPaytankMutation,
	CafeteriaRegistrationQuoteFragment,
	CafeteriaRegistrationQuoteFragmentDoc,
	CreateAddPaytankFundsOrderMutation,
	CreateFeedPostMutation,
	DeleteFeedPostMutation,
	DeleteFeedPostMutationVariables,
	DislikeFeedPostMutation,
	DislikeFeedPostMutationVariables,
	ExtendedFeedPostFragment,
	ExtendedFeedPostFragmentDoc,
	ExtendedPaytankOrderFragment,
	ExtendedPaytankOrderFragmentDoc,
	ExtendedStudentFragment,
	ExtendedStudentFragmentDoc,
	FinishAddPaytankFundsOrderMutation,
	FinishCafeteriaRegistrationOrderMutation,
	LikeFeedPostMutation,
	LikeFeedPostMutationVariables,
	LoginMutation,
	LoginWithAzureSsoMutation,
	LogoutMutation,
	MeDocument,
	MeQuery,
	MeUpdateSubscription,
	MyPaytankOrderSubscription,
	MyPaytankOrderSubscriptionVariables,
	TodayAttendanceOfMeDocument,
	TodayAttendanceOfMeQuery,
	TodayAttendanceUpdateOfMeSubscription,
	TodayAttendanceUpdateOfMeSubscriptionVariables,
	UnblockTagForPaytankMutation,
} from "../generated"
import schema from "../generated/schema.json"
import { basicPagination, searchPagination } from "../pagination"

export const cacheExchange = cE({
	schema: schema as any,
	resolvers: {
		Query: {
			allFeedPosts: basicPagination("feedPosts", "PaginatedFeedPostsResponse"),
			allCirculars: basicPagination("circulars", "PaginatedCircularsResponse"),
			searchCircular: searchPagination("circulars", "PaginatedCircularsResponse"),
			allPaytankOrders: basicPagination("paytankOrders", "PaginatedPaytankOrdersResponse"),
		},
	},
	updates: {
		Subscription: {
			meUpdate: (_result, _, cache) => {
				betterQueryUpdate<MeUpdateSubscription, MeQuery>(cache, { query: MeDocument }, _result, (result, query) => {
					if (result.meUpdate) {
						return {
							me: result.meUpdate,
						}
					}

					return query
				})
			},
			todayAttendanceUpdateOfStudent: (_result, _: TodayAttendanceUpdateOfMeSubscriptionVariables, cache) => {
				betterQueryUpdate<TodayAttendanceUpdateOfMeSubscription, TodayAttendanceOfMeQuery>(
					cache,
					{
						query: TodayAttendanceOfMeDocument,
					},
					_result,
					(result, query) => {
						if (result.todayAttendanceUpdateOfMe) {
							return {
								...query,
								todayAttendanceOfStudent: {
									...query?.todayAttendanceOfMe,
									...(result.todayAttendanceUpdateOfMe as any),
								},
							}
						}

						return query
					}
				)
			},
			myPaytankOrder: (_result, _: MyPaytankOrderSubscriptionVariables, cache) => {
				betterQueryUpdate<MyPaytankOrderSubscription, AllPaytankOrdersQuery>(
					cache,
					{
						query: AllPaytankOrdersDocument,
						variables: { pagination: { limit: 20, page: 1 } } as AllPaytankOrdersQueryVariables,
					},
					_result,
					(result, query) => {
						if (query.allPaytankOrders && result.myPaytankOrder) {
							const paytankOrders = [...(query.allPaytankOrders.paytankOrders || [])]

							const index = paytankOrders.findIndex((o) => o._id === result.myPaytankOrder?._id)

							if (typeof index === "number" && index >= 0) {
								paytankOrders[index] = result.myPaytankOrder
							} else {
								paytankOrders.unshift(result.myPaytankOrder)
							}

							return {
								...query,
								allPaytankOrders: {
									...query.allPaytankOrders,
									paytankOrders,
								},
							}
						}

						return query
					}
				)
			},
		},
		Mutation: {
			login: (_result, _, cache) => {
				betterQueryUpdate<LoginMutation, MeQuery>(cache, { query: MeDocument }, _result, (result, query) => {
					if (result.login.student) {
						return {
							me: result.login.student,
						}
					}

					return query
				})
			},
			loginWithAzureSSO: (_result, _, cache) => {
				betterQueryUpdate<LoginWithAzureSsoMutation, MeQuery>(cache, { query: MeDocument }, _result, (result, query) => {
					if (result.loginWithAzureSSO.student) {
						return {
							me: result.loginWithAzureSSO.student,
						}
					}

					return query
				})
			},

			logout: (result, _, cache) => {
				betterQueryUpdate<LogoutMutation, MeQuery>(cache, { query: MeDocument }, result, () => ({ me: null }))
			},
			createFeedPost: (_result, _, cache) => {
				betterQueryUpdate<CreateFeedPostMutation, AllFeedPostsQuery>(
					cache,
					{
						query: AllFeedPostsDocument,
						variables: {
							pagination: { limit: 20, page: 1 },
						} as AllFeedPostsQueryVariables,
					},
					_result,
					(result, query) => {
						if (result.createFeedPost.feedPost) {
							return {
								allFeedPosts: {
									...query?.allFeedPosts,
									feedPosts: [result.createFeedPost?.feedPost, ...(query?.allFeedPosts?.feedPosts ?? [])],
								},
							}
						}

						return query
					}
				)
			},
			likeFeedPost: (_result: LikeFeedPostMutation, variables: LikeFeedPostMutationVariables, cache) => {
				const { feedPostId } = variables

				const key = cache.keyOfEntity({
					__typename: "ExtendedFeedPost",
					_id: feedPostId,
				})

				if (key) {
					const feedPost = cache.readFragment<ExtendedFeedPostFragment>(ExtendedFeedPostFragmentDoc, key)

					if (feedPost) {
						cache.writeFragment(ExtendedFeedPostFragmentDoc, {
							__typename: "ExtendedFeedPost",
							...feedPost,
							..._result.likeFeedPost,
						})
					}
				}
			},
			dislikeFeedPost: (_result: DislikeFeedPostMutation, variables: DislikeFeedPostMutationVariables, cache) => {
				const { feedPostId } = variables

				const key = cache.keyOfEntity({
					__typename: "ExtendedFeedPost",
					_id: feedPostId,
				})

				if (key) {
					const feedPost = cache.readFragment<ExtendedFeedPostFragment>(ExtendedFeedPostFragmentDoc, key)

					if (feedPost) {
						cache.writeFragment(ExtendedFeedPostFragmentDoc, {
							...feedPost,
							..._result.dislikeFeedPost,
						})
					}
				}
			},
			deleteFeedPost: (_result: DeleteFeedPostMutation, variables: DeleteFeedPostMutationVariables, cache) => {
				const { feedPostId } = variables

				const key = cache.keyOfEntity({
					__typename: "ExtendedFeedPost",
					_id: feedPostId,
				})

				if (key) {
					cache.invalidate(key)
				}
			},
			createAddPaytankFundsOrder: (_result, _, cache) => {
				betterQueryUpdate<CreateAddPaytankFundsOrderMutation, AllPaytankOrdersQuery>(
					cache,
					{
						query: AllPaytankOrdersDocument,
						variables: {
							pagination: { limit: 20, page: 1 },
						} as AllPaytankOrdersQueryVariables,
					},
					_result,
					(result, query) => {
						if (result.createAddPaytankFundsOrder) {
							return {
								allPaytankOrders: {
									...query?.allPaytankOrders,
									paytankOrders: [result.createAddPaytankFundsOrder, ...(query?.allPaytankOrders?.paytankOrders ?? [])],
								},
							}
						}

						return query
					}
				)
			},
			finishAddPaytankFundsOrder: (result: FinishAddPaytankFundsOrderMutation, _, cache) => {
				cache.writeFragment<ExtendedPaytankOrderFragment>(ExtendedPaytankOrderFragmentDoc, result.finishAddPaytankFundsOrder)
			},
			blockTagForPaytank: (_result, _, cache) => {
				betterQueryUpdate<BlockTagForPaytankMutation, MeQuery>(cache, { query: MeDocument }, _result, (result, query) => {
					if (result.blockTagForPaytank && query?.me && query.me.tag) {
						return {
							...query,
							me: { ...query.me, tag: { ...query.me.tag, isBlockedForPaytank: true } },
						}
					}

					return query
				})
			},
			unblockTagForPaytank: (_result, _, cache) => {
				betterQueryUpdate<UnblockTagForPaytankMutation, MeQuery>(cache, { query: MeDocument }, _result, (result, query) => {
					if (result.unblockTagForPaytank && query?.me && query.me.tag) {
						return {
							...query,
							me: { ...query.me, tag: { ...query.me.tag, isBlockedForPaytank: false } },
						}
					}

					return query
				})
			},
			finishCafeteriaRegistrationOrder: (result: FinishCafeteriaRegistrationOrderMutation, _, cache) => {
				const quoteKey = cache.keyOfEntity({
					__typename: "CafeteriaRegistrationQuote",
					_id: result.finishCafeteriaRegistrationOrder.quoteId,
				})

				if (!quoteKey) return

				const quote = cache.readFragment<CafeteriaRegistrationQuoteFragment>(CafeteriaRegistrationQuoteFragmentDoc, quoteKey)

				if (!quote) return

				const today = startOfDay(new Date()).getTime()

				const studentKey = cache.keyOfEntity({
					__typename: "ExtendedStudent",
					_id: result.finishCafeteriaRegistrationOrder.studentId,
				})

				if (!studentKey) return

				const student = cache.readFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, studentKey)

				if (!student) return

				const updatedStudent = { ...student }

				quote.sessionWiseQuotes.forEach((s) => {
					if (new Date(s.schoolSessionStartAt).getTime() <= today && new Date(s.schoolSessionEndAt).getTime() >= today) {
						updatedStudent.cafeteriaRegistration = {
							__typename: "CafeteriaRegistrationLog",
							_id: "",
							studentId: student._id,
							schoolSessionId: s.schoolSessionId,
							since: s.schoolSessionStartAt,
							until: s.schoolSessionEndAt,
							createdAt: new Date(),
							updatedAt: new Date(),
						}
					} else {
						updatedStudent.upcomingCafeteriaRegistration = {
							__typename: "CafeteriaRegistrationLog",
							_id: "",
							studentId: student._id,
							schoolSessionId: s.schoolSessionId,
							since: s.schoolSessionStartAt,
							until: s.schoolSessionEndAt,
							createdAt: new Date(),
							updatedAt: new Date(),
						}
					}
				})

				cache.writeFragment<ExtendedStudentFragment>(ExtendedStudentFragmentDoc, updatedStudent)
			},
		},
	},
	optimistic: {
		// @ts-ignore
		likeFeedPost: (variables: LikeFeedPostMutationVariables, cache) => {
			const { feedPostId } = variables as LikeFeedPostMutationVariables

			const key = cache.keyOfEntity({
				__typename: "ExtendedFeedPost",
				_id: feedPostId,
			})

			if (key) {
				const feedPost = cache.readFragment<ExtendedFeedPostFragment>(ExtendedFeedPostFragmentDoc, key)

				const meQuery = cache.readQuery<MeQuery>({
					query: MeDocument,
				}) as MeQuery

				if (!meQuery) return

				const { me } = meQuery

				if (feedPost && me) {
					return {
						__typename: "ExtendedFeedPost",
						...feedPost,
						isLiked: true,
						likes: [
							...(feedPost.likes ?? []),
							{
								__typename: "FeedPostLike",
								author: {
									authorType: AuthorTypes.School,
									schoolId: me._id,
									studentId: null,
									teacherId: null,
									alumnusId: null,
								},
								createdAt: new Date(),
								updatedAt: new Date(),
							},
						],
					}
				}
			}
		},
		// @ts-ignore
		dislikeFeedPost: (variables, cache) => {
			const { feedPostId } = variables as DislikeFeedPostMutationVariables

			const key = cache.keyOfEntity({
				__typename: "ExtendedFeedPost",
				_id: feedPostId,
			})

			if (key) {
				const feedPost = cache.readFragment<ExtendedFeedPostFragment>(ExtendedFeedPostFragmentDoc, key)

				const meQuery = cache.readQuery<MeQuery>({
					query: MeDocument,
				}) as MeQuery

				if (!meQuery) return

				const { me } = meQuery

				if (feedPost && me) {
					const index = feedPost.likes?.findIndex(
						({ author: { authorType, studentId } }) => authorType === AuthorTypes.Student && String(studentId) === String(me._id)
					)

					feedPost.likes?.splice(index || 0, 1)

					return {
						__typename: "ExtendedFeedPost",
						...feedPost,
						isLiked: false,
					}
				}
			}
		},
	},
})
