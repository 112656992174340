import { VStack, Container, Heading, Text, List, ListItem, UnorderedList } from "@chakra-ui/react"
import React from "react"
import { Header } from "../components"
import { GRADIENT } from "../constants"

export const RefundAndCancellationPolicy: React.FC = () => {
	return (
		<VStack w="full" h="100vh" overflow="auto" bg={GRADIENT}>
			<Header />
			<Container flex="1" h="full" w="full" maxW="container.xl">
				<VStack w="full" align="stretch" pb="12" spacing={6}>
					<Heading fontSize="2xl">Refund & Cancellation Policy</Heading>
					<Text>
						We understand that plans may change and you may need to cancel or request a refund for a recharge made on the Razorpay account. Please
						read this policy carefully to understand our cancellation and refund process.
					</Text>
					<List spacing={16}>
						<ListItem>
							<Heading fontSize="md">Cancellation:</Heading>
							<Text>
								You may cancel a recharge made on the Razorpay account if the recharge has not yet been credited to your student&apos;s BLE
								card. Once a recharge has been credited to your student&apos;s BLE card, it cannot be cancelled. To cancel a recharge, please
								contact us at accounts@prometheusschool.com/itsupport@prometheusschool.com.
							</Text>
						</ListItem>
						<ListItem>
							<Heading fontSize="md">Refund:</Heading>
							<Text>
								If you cancel a recharge before it has been credited to your student&apos;s BLE card, we will refund the full amount paid to the
								payment method used to make the original payment. Refunds may take up to 4-5 business days to process.
							</Text>
						</ListItem>
					</List>

					<Text>
						However, please note that we do not offer refunds for recharges that have already been credited to your student&apos;s BLE card, except
						in the following circumstances:
					</Text>

					<UnorderedList>
						<ListItem>
							Technical Issues: If there was a technical issue with the Razorpay platform or our school&apos;s systems that prevented the recharge
							from being credited to your student&apos;s BLE card.
						</ListItem>
						<ListItem>
							Duplicate Payment: If you made a duplicate payment for the same recharge and both payments were credited to your student&apos;s BLE
							card.
						</ListItem>
					</UnorderedList>

					<Text fontSize="sm">* In case of the child&apos;s withdrawal from school, a complete refund will be initiated.</Text>
					<Text fontSize="sm">
						To request a refund for the above circumstances, please contact us at accounts@prometheusschool.com with the relevant details.
					</Text>
					<Text fontSize="sm">
						We reserve the right to modify this cancellation and refund policy at any time, so please check this policy periodically for updates.
					</Text>

					<Text fontWeight="semibold">Note: The minimum payment can be INR 1000 and upto INR 25000.</Text>
				</VStack>
			</Container>
		</VStack>
	)
}
