import { AspectRatio, Avatar, Center, chakra, Container, Heading, HStack, Link, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { DashboardHeader, Nav } from "../components"
import { StaffDetails } from "../components/staff"
import { GRADIENT } from "../constants"
import { useMyClassTeacherQuery } from "../graphql"
import { useDrawer } from "../hooks"

export const ClassTeacher: React.FC = () => {
	const [{ data, fetching, error }] = useMyClassTeacherQuery()

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 0}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.myClassTeacher ? (
						<VStack w="full" align="flex-start" spacing="4">
							<HStack w="full" align="flex-start" spacing={4}>
								<AspectRatio w="full" maxW={{ base: "24", md: "36" }} cursor="pointer" ratio={1}>
									<Avatar src={data.myClassTeacher.picture?.url} name={data.myClassTeacher.name} />
								</AspectRatio>
								<VStack align="flex-start">
									<VStack align="flex-start" spacing="0">
										<Heading as="h1" fontSize="xl" color="text.400">
											{data.myClassTeacher.name}
										</Heading>
										<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.400" maxW="xs">
											@{data.myClassTeacher.username}
										</Heading>
										<Text fontSize="sm" color="text.400" fontWeight="semibold">
											{data.myClassTeacher.roles && data.myClassTeacher.roles.length > 0 ? (
												data.myClassTeacher.roles.map((role, index) => (
													<chakra.span key={index}>
														{role.role} {role.class ? "|" : ""} {role.class?.name} - {role.classSection?.name}
														{index === (data.myClassTeacher.roles?.length || 0) - 1 && !data.myClassTeacher.roles?.[index + 1]
															? ""
															: ", "}
													</chakra.span>
												))
											) : (
												<chakra.span>Not Assigned</chakra.span>
											)}
										</Text>
									</VStack>
									{data.myClassTeacher.subjects.length && (
										<VStack align="flex-start" spacing={0}>
											<Text fontSize="xs" color="text.400">
												Teaches
											</Text>

											<Text fontSize="sm" color="text.400" fontWeight="semibold">
												{data.myClassTeacher.subjects.map((subject, index) => (
													<Link key={subject._id} as={NavLink} to={`/subjects/${subject._id}`}>
														{subject.name}
														{index === (data.myClassTeacher.subjects.length || 0) - 1 && !data.myClassTeacher.subjects[index + 1]
															? ""
															: ", "}
													</Link>
												))}
											</Text>
										</VStack>
									)}
								</VStack>
							</HStack>
							<StaffDetails staff={data.myClassTeacher} />
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the teacher.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
