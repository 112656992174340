import { As, Box, BoxProps, ComponentWithAs } from "@chakra-ui/react"
import { motion, MotionProps } from "framer-motion"
import React from "react"

const MotionBox = motion<BoxProps & MotionProps>(Box)

export type CardProps = { children: any } & As<BoxProps & MotionProps>

// eslint-disable-next-line react/display-name
export const Card: ComponentWithAs<CardProps> = React.forwardRef<unknown, CardProps>(({ children, ...props }, ref) => {
	return (
		<MotionBox ref={ref as any} bg="white.500" rounded="2xl" px="8" py="6" maxW="xs" cursor="pointer" whileHover={{ scale: 1.05 }} {...props}>
			{children}
		</MotionBox>
	)
}) as any
