import { Button, Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { AllTrendingSkillsQueryVariables, useSearchTrendingSkillQuery } from "../../graphql"
import { TrendingSkill } from "./TrendingSkill"

export type SearchResultTrendingSkillsProps = {
	searchKeyword: string
	topicId?: string
	forYou: boolean
}

export const SearchResultTrendingSkills: React.FC<SearchResultTrendingSkillsProps> = ({ searchKeyword, topicId, forYou }) => {
	const [pagination, setPagination] = useState<AllTrendingSkillsQueryVariables["pagination"]>({ limit: 20, page: 1 })
	const [{ data, fetching, error }] = useSearchTrendingSkillQuery({
		variables: { keyword: searchKeyword, topicId, forYou, pagination },
	})

	const nextPage = () => {
		setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
	}

	return (
		<VStack w="full" maxW="lg" align="stretch">
			<VStack w="full" align="stretch">
				{data?.searchTrendingSkill?.trendingSkills?.map((trendingSkill) => (
					<TrendingSkill key={trendingSkill._id} trendingSkill={trendingSkill} />
				))}
			</VStack>
			{fetching ? (
				<Center w="full" maxW="lg" py="8">
					<Spinner color="text.400" />
				</Center>
			) : data?.searchTrendingSkill.trendingSkills && data?.searchTrendingSkill.trendingSkills.length <= 0 ? (
				<Center w="full" maxW="lg" py="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any trending skills.
					</Text>
				</Center>
			) : data?.searchTrendingSkill?.hasNextPage ? (
				<Center w="full" maxW="lg" py="8">
					<Button variant="link" size="sm" colorScheme="blackAlpha" onClick={nextPage}>
						Show more
					</Button>
				</Center>
			) : error?.message ? (
				<Center w="full" maxW="lg" minH="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						{error?.message}
					</Text>
				</Center>
			) : (
				<Center w="full" maxW="lg" py="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						You have seen it all
					</Text>
				</Center>
			)}
		</VStack>
	)
}
