import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useSearchResourceQuery } from "../../graphql"
import { Resource } from "./Resource"

export type SearchResultResourcesProps = {
	searchKeyword: string
	classId?: string
	classSectionId?: string
	subjectId?: string
	topicId?: string
	subTopic?: string
}

export const SearchResultResources: React.FC<SearchResultResourcesProps> = ({ searchKeyword, classId, classSectionId, subjectId, topicId, subTopic }) => {
	const [{ data, fetching, error }] = useSearchResourceQuery({
		variables: { keyword: searchKeyword, classId, classSectionId, subjectId, topicId, subTopic },
	})

	return (
		<VStack w="full" maxW="lg" align="stretch">
			<VStack w="full" align="stretch">
				{data?.searchResource?.map((resource) => (
					<Resource key={resource._id} resource={resource} />
				))}
			</VStack>
			{fetching ? (
				<Center w="full" maxW="lg" py="8">
					<Spinner color="text.400" />
				</Center>
			) : data?.searchResource && data?.searchResource.length <= 0 ? (
				<Center w="full" maxW="lg" py="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any resources.
					</Text>
				</Center>
			) : error?.message ? (
				<Center w="full" maxW="lg" minH="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						{error.message}
					</Text>
				</Center>
			) : (
				<Center w="full" maxW="lg" py="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						You have seen it all
					</Text>
				</Center>
			)}
		</VStack>
	)
}
