import { AspectRatio, Avatar, Box, Center, chakra, Container, Grid, Heading, HStack, Link, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { NavLink, useParams } from "react-router-dom"
import { DashboardHeader, Nav } from "../../components"
import { StaffDetails } from "../../components/staff"
import { GRADIENT } from "../../constants"
import { useStaffByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const Staff: React.FC = () => {
	const { staffId = "" } = useParams<{ staffId: string }>()

	const [{ data, fetching, error }] = useStaffByIdQuery({
		variables: { staffId },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 0}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.staffById ? (
						<VStack w="full" align="flex-start" spacing="4">
							<HStack w="full" py="4" align="flex-start" spacing={4}>
								<AspectRatio w="full" maxW={{ base: "24", md: "36" }} cursor="pointer" ratio={1}>
									<Avatar src={data.staffById.picture?.url} name={data.staffById.name} />
								</AspectRatio>
								<VStack align="flex-start">
									<VStack align="flex-start" spacing="0">
										<Heading as="h1" fontSize="xl" color="text.400">
											{data.staffById.name}
										</Heading>
										<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.400" maxW="xs">
											@{data.staffById.username}
										</Heading>
										<Text fontSize="sm" color="text.400" fontWeight="semibold">
											{data.staffById.roles && data.staffById.roles.length > 0 ? (
												data.staffById.roles.map((role, index) => (
													<chakra.span key={index}>
														{role.role} {role.class ? "|" : ""} {role.class?.name} - {role.classSection?.name}
														{index === (data.staffById.roles?.length || 0) - 1 && !data.staffById.roles?.[index + 1] ? "" : ", "}
													</chakra.span>
												))
											) : (
												<chakra.span>Not Assigned</chakra.span>
											)}
										</Text>
									</VStack>
									<VStack align="flex-start" spacing={0}>
										<Text fontSize="xs" color="text.400">
											Teaches
										</Text>

										<Text fontSize="sm" color="text.400" fontWeight="semibold">
											{data.staffById.subjects.map((subject, index) => (
												<Link key={subject._id} as={NavLink} to={`/subjects/${subject._id}`}>
													{subject.name}
													{index === (data.staffById.subjects.length || 0) - 1 && !data.staffById.subjects[index + 1] ? "" : ", "}
												</Link>
											))}
										</Text>
									</VStack>
								</VStack>
							</HStack>
							<StaffDetails staff={data.staffById} />
							<VStack w="full" maxW="xl" align="flex-start">
								<Heading as="h2" fontSize="md" fontWeight="semisemibold" color="text.400">
									Past Roles
								</Heading>
								<Box bg="white.500" rounded="2xl" px="6" py="4" w="full" shadow="sm">
									<Grid w="full" templateColumns="repeat(2, 1fr)" gap={4}>
										{data.staffById.pastRoles && data.staffById.pastRoles.length > 0 ? (
											data.staffById.pastRoles.map((pastRole, index) => (
												<VStack key={index} align="flex-start" spacing={0}>
													<Text fontSize="sm" color="text.400" fontWeight="semibold">
														{pastRole.role}
													</Text>
													{pastRole.class && (
														<Text fontSize="xs" color="text.400" fontWeight="semibold">
															{pastRole.class.name} - {pastRole.classSection?.name}
														</Text>
													)}
													<Text fontSize="xs" color="text.400">
														{format(new Date(pastRole.assignedAt), "P")} - {format(new Date(pastRole.removedAt), "P")}
													</Text>
												</VStack>
											))
										) : (
											<chakra.span>Not Assigned</chakra.span>
										)}
									</Grid>
								</Box>
							</VStack>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the teacher.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
