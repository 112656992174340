import {
	Center,
	Container,
	Heading,
	HStack,
	Icon,
	Input,
	InputGroup,
	InputRightAddon,
	Select,
	Spinner,
	Stack,
	Tab,
	TabList,
	Tabs,
	Text,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { Nav, DashboardHeader, SearchResultTrendingSkills, AllTrendingSkills } from "../../components"
import { GRADIENT } from "../../constants"
import { TopicFragment, useAllTopicsQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const TrendingSkills: React.FC = () => {
	const { isShown } = useDrawer()

	const [searchKeyword, setSearchKeyword] = useState("")

	const [forYou, setForYou] = useState<boolean>(true)

	const [{ data: topicsData, fetching: fetchingTopics, error: topicsError }] = useAllTopicsQuery()

	const [topic, setTopic] = useState<TopicFragment>()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 0}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Trending Skills
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								{
									// TODO: add sub heading
								}
							</Heading>
						</VStack>
						<InputGroup maxW="sm" bg="white.500" rounded="xl" py="1">
							<Input
								placeholder="Search trending skills"
								border="none"
								_focus={{ border: "none" }}
								onChange={(e) => setSearchKeyword(e.target.value)}
							/>
							<InputRightAddon bg="transparent" border="none">
								<Icon as={(props: any) => <FontAwesomeIcon icon={faSearch} {...props} />} color="text.100" fontSize="lg" />
							</InputRightAddon>
						</InputGroup>
					</Stack>

					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between" spacing={4}>
						<Tabs variant="soft-rounded" colorScheme="green">
							<TabList>
								<Tab onClick={() => setForYou(true)} fontSize="sm">
									For You
								</Tab>
								<Tab onClick={() => setForYou(false)} fontSize="sm">
									All
								</Tab>
							</TabList>
						</Tabs>

						{fetchingTopics ? (
							<Center>
								<VStack w="full" color="blackAlpha.800">
									<Text>Loading topics</Text>
									<Spinner size="sm" />
								</VStack>
							</Center>
						) : topicsError ? (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
									Failed loading topics, try reloading the page
								</Text>
							</Center>
						) : topicsData?.allTopics.length ? (
							<HStack spacing={0}>
								<Select
									placeholder="Select topic"
									bg="white.500"
									roundedStart="xl"
									roundedEnd="xl"
									border="none"
									_focus={{ border: "none" }}
									w="-moz-fit-content"
									maxW="xs"
									value={JSON.stringify(topic)}
									onChange={(e) => {
										setTopic(e.target.value ? JSON.parse(e.target.value) : undefined)
									}}
									textTransform="capitalize"
								>
									{topicsData?.allTopics.map((s) => (
										<option key={s._id} value={JSON.stringify(s)}>
											{s.name}
										</option>
									))}
								</Select>
							</HStack>
						) : (
							<Center>
								<Text fontSize="md" fontWeight="semibold" color="text.400">
									Couldn&apos;t find any topics.
								</Text>
							</Center>
						)}
					</Stack>

					{searchKeyword ? (
						<SearchResultTrendingSkills searchKeyword={searchKeyword} topicId={topic?._id} forYou={forYou} />
					) : (
						<AllTrendingSkills topicId={topic?._id} forYou={forYou} />
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
