import Icon from "@chakra-ui/icon"
import { HStack, useBreakpointValue } from "@chakra-ui/react"
import { faChalkboardTeacher, faGrip, faNewspaper, faSchool, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { BottomNavigationTab } from "./BottomNavigationTab"

export const BottomNavigationTabs: React.FC = () => {
	const isMobile = useBreakpointValue({ base: true, sm: false })

	if (!isMobile) return <></>

	return (
		<HStack pos="fixed" bottom={0} left={0} right={0} bg="white" zIndex="sticky" spacing="0.5" boxShadow="dark-lg" justify="space-around">
			<BottomNavigationTab to="/feed">
				<Icon as={(props: any) => <FontAwesomeIcon icon={faGrip} {...props} />} fontSize={20} />
			</BottomNavigationTab>

			<BottomNavigationTab to="/profile">
				<Icon as={(props: any) => <FontAwesomeIcon icon={faUser} {...props} />} fontSize={20} />
			</BottomNavigationTab>

			<BottomNavigationTab to="/school">
				<Icon as={(props: any) => <FontAwesomeIcon icon={faSchool} {...props} />} fontSize={20} />
			</BottomNavigationTab>

			<BottomNavigationTab to="/classTeacher">
				<Icon as={(props: any) => <FontAwesomeIcon icon={faChalkboardTeacher} {...props} />} fontSize={20} />
			</BottomNavigationTab>

			<BottomNavigationTab to="/circulars">
				<Icon as={(props: any) => <FontAwesomeIcon icon={faNewspaper} {...props} />} fontSize={20} />
			</BottomNavigationTab>
		</HStack>
	)
}
