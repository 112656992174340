import { Button, useToast } from "@chakra-ui/react"
import React from "react"
import { useUnblockTagForPaytankMutation } from "../../graphql"

export const UnblockTagForPaytankButton: React.FC = () => {
	const [{ fetching }, unblockTag] = useUnblockTagForPaytankMutation()

	const toast = useToast()

	const handleClick = async () => {
		const { error, data } = await unblockTag()

		if (error) {
			toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.unblockTagForPaytank) {
			toast({
				description: "The tag is active for paytank now",
				status: "success",
			})
		}
	}

	return (
		<Button variant="link" size="sm" colorScheme="primary" onClick={handleClick} isLoading={fetching}>
			Unblock Tag
		</Button>
	)
}
