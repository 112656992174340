import { VStack, Container, Heading, Text, List, ListItem } from "@chakra-ui/react"
import React from "react"
import { Header } from "../components"
import { GRADIENT } from "../constants"

export const PrivacyPolicy: React.FC = () => {
	return (
		<VStack w="full" h="100vh" overflow="auto" bg={GRADIENT}>
			<Header />
			<Container flex="1" h="full" w="full" maxW="container.xl">
				<VStack w="full" align="stretch" pb="12" spacing={6}>
					<Heading fontSize="2xl">Privacy Policy</Heading>
					<Text>
						Prometheus school is committed to protecting the privacy and security of your personal information. This privacy policy explains how we
						collect, use, and protect your personal information when you use our Razorpay account.
					</Text>
					<List spacing={16}>
						<ListItem>
							<Heading fontSize="md">1. Information Collection</Heading>
							<Text>
								We collect personal information from you when you create an account on the Razorpay platform, such as your name, email address,
								phone number, and payment details. We may also collect information about your use of the Razorpay platform, such as your
								transaction history and IP address.
							</Text>
						</ListItem>
						<ListItem>
							<Heading fontSize="md">2. Use of Information</Heading>
							<Text>
								We use the personal information collected from you to provide and improve the services offered through our Razorpay account.
								This may include processing payments, providing customer support, and communicating with you about your account and
								transactions.
							</Text>
						</ListItem>
						<ListItem>
							<Heading fontSize="md">3. Disclosure of Information</Heading>
							<Text>
								We may disclose your personal information to third-party service providers who assist us with the operation of the Razorpay
								platform or who provide other services to us. We may also disclose your personal information to regulatory authorities as
								required by law.
							</Text>
						</ListItem>
						<ListItem>
							<Heading fontSize="md">4. Security</Heading>
							<Text>
								We take reasonable measures to protect the security and confidentiality of your personal information. However, no security
								measures are foolproof, and we cannot guarantee the security of your personal information.
							</Text>
						</ListItem>
						<ListItem>
							<Heading fontSize="md">5. Cookies</Heading>
							<Text>
								We may use cookies to collect information about your use of the Razorpay platform. Cookies are small text files that are stored
								on your device when you access the internet. You can control the use of cookies through your browser settings.
							</Text>
						</ListItem>
						<ListItem>
							<Heading fontSize="md">6. Limitation of Liability</Heading>
							<Text>
								Our school will not be liable for any damages or losses arising from your use of the Razorpay platform or associated services,
								including but not limited to direct, indirect, incidental, punitive, and consequential damages. This limitation of liability
								applies to the fullest extent permitted by law.
							</Text>
						</ListItem>
						<ListItem>
							<Heading fontSize="md">7. Governing Law and Jurisdiction</Heading>
							<Text>
								These terms and conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising under
								or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of Gautam Buddha
								Nagar, Noida, UP.
							</Text>
						</ListItem>
					</List>

					<Heading fontSize="sm">Contact Us</Heading>
					<Text fontSize="sm">If you have any questions or concerns about these policies, please contact us at info@prometheusschool.com</Text>

					<Heading fontSize="sm">Address</Heading>
					<Text fontSize="sm">I-7, Jaypee Wish Town, Sector 131, Noida 201304</Text>
				</VStack>
			</Container>
		</VStack>
	)
}
