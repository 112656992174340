import { Center, Heading, HStack, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { AllStaffsQueryVariables, useAllStaffsQuery } from "../../graphql"
import { StaffsTable } from "./StaffsTable"

export const AllStaffs: React.FC = () => {
	const [pagination, setPagination] = useState<AllStaffsQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useAllStaffsQuery({
		variables: { pagination },
	})

	const prevPage = () => {
		if (data?.allStaffs.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.allStaffs.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" justify="space-between">
				<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
					Showing {data?.allStaffs.total || "all"} staff of your school
				</Heading>
			</HStack>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.allStaffs.staffs && data?.allStaffs.staffs.length > 0 ? (
				<StaffsTable
					staffs={data.allStaffs.staffs}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.allStaffs.hasPrevPage}
					hasNextPage={data.allStaffs.hasNextPage}
					currentPage={data.allStaffs.currentPage}
					totalPages={data.allStaffs.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any staff.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
