import {
	Button,
	Checkbox,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Heading,
	Icon,
	Input,
	SlideFade,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { ChangePasswordMutationVariables, useChangePasswordMutation } from "../../graphql"

const validationSchema = Yup.object({
	currentPassword: Yup.string().min(6).required().label("Current Password"),
	newPassword: Yup.string().min(6).required().label("New Password"),
	shouldLogOutOnOtherDevices: Yup.boolean().label("Should log out"),
})

export const ChangePasswordForm: React.FC = () => {
	const [{ fetching }, changePassword] = useChangePasswordMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async (values: ChangePasswordMutationVariables) => {
		const { error } = await changePassword(values)

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		return navigate("/", { replace: true })
	}

	const formik = useFormik<ChangePasswordMutationVariables>({
		initialValues: { currentPassword: "", newPassword: "", shouldLogOutOnOtherDevices: false },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<form onSubmit={formik.handleSubmit}>
			<VStack align="flex-start" spacing="4">
				<Heading fontSize="lg" fontWeight="semibold" lineHeight="100%" color="#515151">
					Change Password
				</Heading>
				<FormControl isInvalid={Boolean(formik.errors.currentPassword && formik.touched.currentPassword)}>
					<SlideFade in={Boolean(formik.values.currentPassword)} unmountOnExit>
						<FormLabel htmlFor="currentPassword" fontSize="sm">
							Current Password
						</FormLabel>
					</SlideFade>
					<Input
						type="password"
						placeholder="Current Password"
						border="none"
						_focus={{ border: "none" }}
						maxW="sm"
						bg="white.500"
						rounded="xl"
						py="1"
						{...formik.getFieldProps("currentPassword")}
					/>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{formik.errors.currentPassword}</Text>
					</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.errors.newPassword && formik.touched.newPassword)}>
					<SlideFade in={Boolean(formik.values.newPassword)} unmountOnExit>
						<FormLabel htmlFor="newPassword" fontSize="sm">
							New Password
						</FormLabel>
					</SlideFade>
					<Input
						type="password"
						placeholder="New Password"
						border="none"
						_focus={{ border: "none" }}
						maxW="sm"
						bg="white.500"
						rounded="xl"
						py="1"
						{...formik.getFieldProps("newPassword")}
					/>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{formik.errors.newPassword}</Text>
					</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.errors.shouldLogOutOnOtherDevices && formik.touched.shouldLogOutOnOtherDevices)}>
					<Checkbox
						maxW="sm"
						rounded="xl"
						borderColor="purple.400"
						{...formik.getFieldProps("shouldLogOutOnOtherDevices")}
						isChecked={formik.values.shouldLogOutOnOtherDevices || false}
						onChange={(e) => formik.setFieldValue("shouldLogOutOnOtherDevices", e.target.checked)}
					>
						Log out on other devices
					</Checkbox>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{formik.errors.shouldLogOutOnOtherDevices}</Text>
					</FormErrorMessage>
				</FormControl>
				<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
					Change
				</Button>
			</VStack>
		</form>
	)
}
