/* eslint-disable @typescript-eslint/indent */
import { Alert, chakra, Divider, HStack, Tag, Text, VStack } from "@chakra-ui/react"
import { format, formatDistanceToNow } from "date-fns"
import React from "react"
import { ExtendedPointsRedeemTransactionFragment } from "../../graphql"

export type StudentPointsRedeemTransactionListItemProps = {
	transaction: ExtendedPointsRedeemTransactionFragment
}

export const StudentPointsRedeemTransactionListItem: React.FC<StudentPointsRedeemTransactionListItemProps> = ({ transaction }) => {
	return (
		<VStack w="full" align="stretch">
			<HStack flex={1} spacing={0} justify="space-between">
				{transaction.isRejected && transaction.rejectedAt ? (
					<Tag colorScheme="red" size="sm">
						Rejected {formatDistanceToNow(new Date(transaction.rejectedAt), { addSuffix: true })}
					</Tag>
				) : transaction.isApproved && transaction.approvedAt ? (
					<Tag colorScheme="green" size="sm">
						Approved {formatDistanceToNow(new Date(transaction.approvedAt), { addSuffix: true })}
					</Tag>
				) : (
					<Tag colorScheme="orange" size="sm">
						Pending
					</Tag>
				)}

				<Text fontSize="xs" color="blackAlpha.700">
					{format(new Date(transaction.createdAt), "dd/MM/yyyy p")}
				</Text>
			</HStack>
			<VStack w="full" align="stretch" spacing={0}>
				<Text fontSize="sm">
					<chakra.span fontSize="md" fontWeight="semibold" color="purple.500">
						-{transaction.pointsRedeemItem.points}
					</chakra.span>{" "}
					points
				</Text>

				<Text fontSize="sm" color="blackAlpha.700">
					{transaction.pointsRedeemItem.name}
				</Text>
			</VStack>

			{transaction.isRejected && transaction.remarks && (
				<Alert status="error" rounded="lg">
					<VStack w="full" align="flex-start" spacing={0}>
						<Text fontSize="xs" fontWeight="bold" color="red.800">
							Points have been reset
						</Text>
						<Text fontSize="xs">{transaction.remarks}</Text>
					</VStack>
				</Alert>
			)}

			<Divider />
		</VStack>
	)
}
