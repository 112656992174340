import { PublicClientApplication } from "@azure/msal-browser"
import { AZURE_AD_CLIENT_ID, SITE_URL } from "../constants"

export const msalInstance = new PublicClientApplication({
	auth: {
		clientId: AZURE_AD_CLIENT_ID,
		authority: "https://login.microsoftonline.com/common",
		redirectUri: SITE_URL,
	},
	cache: {
		cacheLocation: "localStorage",
	},
})
