/* eslint-disable @typescript-eslint/indent */
import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Badge,
	Button,
	Center,
	chakra,
	Checkbox,
	Divider,
	HStack,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import isNil from "lodash/isNil"
import omitBy from "lodash/omitBy"
import React, { useCallback, useMemo, useState } from "react"
import { ArrowRight } from "react-feather"
import { RAZORPAY_KEY_ID } from "../../constants"
import {
	AdmissionTypes,
	CafeteriaRegistrationSessionQuoteFragment,
	ExtendedStudentFragment,
	FinishCafeteriaRegistrationOrderMutationVariables,
	SchoolSession,
	useCafeteriaRegistrationQuoteQuery,
	useCreateCafeteriaRegistrationOrderMutation,
	useCurrentAndUpcomingSessionQuery,
	useFinishCafeteriaRegistrationOrderMutation,
} from "../../graphql"
import { getMonthForIndex, loadScript } from "../../utils"

export type CreateCafeteriaRegistrationOrderModalProps = {
	isOpen: boolean
	onClose: () => void
	student: ExtendedStudentFragment
}

export const CreateCafeteriaRegistrationOrderModal: React.FC<CreateCafeteriaRegistrationOrderModalProps> = ({ isOpen, onClose, student }) => {
	const [{ data: sessionsData, fetching: sessionsFetching, error: sessionsError }] = useCurrentAndUpcomingSessionQuery({
		pause: student.admissionType === AdmissionTypes.Staff,
	})

	const [selectedSessionIds, setSelectedSessionIds] = useState<string[]>([])

	const selectSessionId = (sessionId: string) => {
		setSelectedSessionIds((prev) => [...prev, sessionId])
	}

	const deselectSessionId = (sessionId: string) => {
		setSelectedSessionIds((prev) => prev.filter((sId) => sId !== sessionId))
	}

	const isSessionSelected = useCallback((sessionId: string) => selectedSessionIds.includes(sessionId), [selectedSessionIds])

	const [{ data: quoteData }] = useCafeteriaRegistrationQuoteQuery({
		variables: { sessionIds: selectedSessionIds },
		pause: student.admissionType === AdmissionTypes.Staff,
	})

	const [, createOrder] = useCreateCafeteriaRegistrationOrderMutation()
	const [, finishOrder] = useFinishCafeteriaRegistrationOrderMutation()

	const [error, setError] = useState("")
	const [isLoading, setIsLoading] = useState(false)

	const toast = useToast()

	const finishPayment = async (response: any, orderId: string, amount: number) => {
		setIsLoading(true)
		const input: FinishCafeteriaRegistrationOrderMutationVariables["input"] = {
			orderId,
			amount,
			razorpayPaymentId: response.razorpay_payment_id,
			razorpayOrderId: response.razorpay_order_id,
			razorpaySignature: response.razorpay_signature,
		}

		const { error, data } = await finishOrder({ input })

		if (error) {
			setIsLoading(false)
			return setError(error.message)
		}

		if (!data?.finishCafeteriaRegistrationOrder) {
			setIsLoading(false)
			return setError("Something went wrong, try again")
		}

		toast({
			title: "Payment successfull",
			description: "Successfully registered for Cafeteria",
			status: "success",
		})

		setIsLoading(false)
		onClose()
		return
	}

	const handlePayment = async () => {
		if (!quoteData) return

		setIsLoading(true)

		const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js")

		if (!res) {
			setIsLoading(false)
			setError("Razorpay SDK failed to load. Try again")
			return
		}

		const { error, data } = await createOrder({ quoteId: quoteData.cafeteriaRegistrationQuote._id })

		if (error) {
			setIsLoading(false)
			return setError(error.message)
		}

		if (!data?.createCafeteriaRegistrationOrder) {
			setIsLoading(false)
			return setError("Something went wrong, try again")
		}

		const { orderId } = data.createCafeteriaRegistrationOrder

		if (!orderId) {
			setIsLoading(false)
			return setError("Could not generate a valid order, please try again")
		}

		const prefill = omitBy(
			{
				name: student.name,
				email: student.email,
				contact: student.phone ? "91" + student.phone.toString() : undefined,
			},
			isNil
		)

		const readonly = omitBy(
			{
				name: Boolean(student.name),
				email: Boolean(student.email),
				contact: Boolean(student.phone),
			},
			isNil
		)

		const options = {
			key: RAZORPAY_KEY_ID,
			order_id: orderId,
			amount: (data.createCafeteriaRegistrationOrder.amount * 100).toString(),
			currency: "INR",
			name: "Prometheus School",
			prefill,
			readonly,
			handler: (response: any) => finishPayment(response, orderId, data.createCafeteriaRegistrationOrder.amount),
		}

		if (!(window as any).Razorpay) {
			setIsLoading(false)
			setError("Razorpay SDK failed to load. Try again")
			return
		}

		const rzrp = new (window as any).Razorpay(options)

		if (rzrp) {
			rzrp.open()
			rzrp.on("payment.failed", (response: any) => {
				setIsLoading(false)
				setError(response.error.description)
				return
			})
			setIsLoading(false)
			return
		}
	}

	const sessionsWithQuote = useMemo(() => {
		if (!sessionsData) return

		return {
			currentSession: sessionsData.currentAndUpcomingSession.currentSession
				? ({
						...sessionsData.currentAndUpcomingSession.currentSession,
						quote: quoteData?.cafeteriaRegistrationQuote.sessionWiseQuotes.find(
							(q) => q.schoolSessionId === sessionsData.currentAndUpcomingSession.currentSession?._id
						),
				  } as SchoolSession & { quote?: CafeteriaRegistrationSessionQuoteFragment })
				: undefined,
			upcomingSession: sessionsData.currentAndUpcomingSession.upcomingSession
				? ({
						...sessionsData.currentAndUpcomingSession.upcomingSession,
						quote: quoteData?.cafeteriaRegistrationQuote.sessionWiseQuotes.find(
							(q) => q.schoolSessionId === sessionsData.currentAndUpcomingSession.upcomingSession?._id
						),
				  } as SchoolSession & { quote?: CafeteriaRegistrationSessionQuoteFragment })
				: undefined,
		}
	}, [JSON.stringify(sessionsData), JSON.stringify(quoteData)])

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="md">
				<ModalOverlay>
					<ModalContent>
						<ModalHeader>Cafeteria Registration</ModalHeader>
						<ModalCloseButton />

						{student.admissionType === AdmissionTypes.Staff ? (
							<ModalBody as={VStack} w="full" align="stretch" px="8" pb="8">
								<Alert variant="subtle" status="info" flexDirection="column" rounded="xl">
									<AlertIcon />
									<AlertTitle>Not Applicable</AlertTitle>
									<AlertDescription fontSize="sm" textAlign="center">
										Cafeteria registration is not applicable for students with <strong>Staff</strong> admission type
									</AlertDescription>
								</Alert>
							</ModalBody>
						) : (
							<ModalBody as={VStack} w="full" align="stretch" px="8">
								{error && (
									<Alert variant="subtle" status="error" flexDirection="column" rounded="xl">
										<AlertIcon />
										<AlertTitle>Payment Failed</AlertTitle>
										<AlertDescription fontSize="sm" textAlign="center">
											{error}
										</AlertDescription>
									</Alert>
								)}

								{sessionsFetching ? (
									<Center w="full" py="4">
										<Spinner color="text.400" />
									</Center>
								) : sessionsError ? (
									<Center w="full" py="4">
										<Text fontSize="md" fontWeight="semisemibold" color="text.400">
											{sessionsError.message.replace("[GraphQL] ", "")}
										</Text>
									</Center>
								) : sessionsData?.currentAndUpcomingSession && sessionsWithQuote ? (
									<VStack w="full" align="stretch" spacing={12}>
										{sessionsWithQuote.currentSession && (
											<VStack>
												<VStack w="full" align="flex-end">
													{student.cafeteriaRegistration && (
														<Badge variant="solid" colorScheme="primary">
															<HStack>
																<Text>Already registered</Text>
																<Icon as={(props: any) => <FontAwesomeIcon icon={faCheckCircle} {...props} />} />
															</HStack>
														</Badge>
													)}
													<Checkbox
														w="full"
														display="flex"
														justifyContent="space-between"
														colorScheme="primary"
														isDisabled={!!student.cafeteriaRegistration}
														isChecked={isSessionSelected(sessionsWithQuote.currentSession._id)}
														onChange={(e) =>
															e.target.checked
																? selectSessionId(sessionsWithQuote.currentSession!._id)
																: deselectSessionId(sessionsWithQuote.currentSession!._id)
														}
													>
														<VStack spacing={0} align="flex-end">
															<Text fontSize="sm">
																{getMonthForIndex(sessionsWithQuote.currentSession.startMonth)}{" "}
																{sessionsWithQuote.currentSession.startDate.toString().padStart(2, "0")} -{" "}
																{getMonthForIndex(sessionsWithQuote.currentSession.endMonth)}{" "}
																{sessionsWithQuote.currentSession.endDate.toString().padStart(2, "0")}
															</Text>
															<HStack>
																<Text fontSize="md" fontWeight="semibold">
																	{sessionsWithQuote.currentSession.name}
																</Text>
																<Badge variant="solid" colorScheme="green">
																	Current
																</Badge>
															</HStack>
														</VStack>
													</Checkbox>
												</VStack>
												<Divider />
												{sessionsWithQuote.currentSession.quote && (
													<VStack w="full">
														<VStack w="full" spacing={4}>
															{sessionsWithQuote.currentSession.quote.breakup.map((item, index) => (
																<HStack key={index} w="full" justify="space-between">
																	<Text w="full" maxW="44" fontSize="sm">
																		{item.label}
																	</Text>
																	<Text fontWeight="semibold" color="primary.500">
																		{item.payable ? (
																			<chakra.span
																				textDecoration="line-through"
																				fontSize="smaller"
																				fontWeight="normal"
																				mr="2"
																			>
																				₹ {item.amount}
																			</chakra.span>
																		) : (
																			`₹ ${item.amount}`
																		)}{" "}
																		{item.payable ? `₹ ${item.payable}` : ""}
																	</Text>
																</HStack>
															))}
														</VStack>
														<Divider />

														<Text alignSelf="flex-end" fontSize="xl" color="primary.500" fontWeight="semibold">
															₹ {sessionsWithQuote.currentSession.quote.totalAmount.toFixed(2)}
														</Text>
													</VStack>
												)}
											</VStack>
										)}

										{sessionsWithQuote.upcomingSession && (
											<VStack>
												<VStack w="full" align="flex-end">
													{student.upcomingCafeteriaRegistration && (
														<Badge variant="solid" colorScheme="primary">
															<HStack>
																<Text>Already registered</Text>
																<Icon as={(props: any) => <FontAwesomeIcon icon={faCheckCircle} {...props} />} />
															</HStack>
														</Badge>
													)}
													<Checkbox
														w="full"
														display="flex"
														justifyContent="space-between"
														colorScheme="primary"
														isDisabled={!!student.upcomingCafeteriaRegistration}
														isChecked={isSessionSelected(sessionsWithQuote.upcomingSession._id)}
														onChange={(e) =>
															e.target.checked
																? selectSessionId(sessionsWithQuote.upcomingSession!._id)
																: deselectSessionId(sessionsWithQuote.upcomingSession!._id)
														}
													>
														<VStack spacing={0} align="flex-end">
															<Text fontSize="sm">
																{getMonthForIndex(sessionsWithQuote.upcomingSession.startMonth)}{" "}
																{sessionsWithQuote.upcomingSession.startDate.toString().padStart(2, "0")} -{" "}
																{getMonthForIndex(sessionsWithQuote.upcomingSession.endMonth)}{" "}
																{sessionsWithQuote.upcomingSession.endDate.toString().padStart(2, "0")}
															</Text>
															<HStack>
																<Text fontSize="md" fontWeight="semibold">
																	{sessionsWithQuote.upcomingSession.name}
																</Text>
																<Badge variant="solid" colorScheme="orange">
																	Upcoming
																</Badge>
															</HStack>
														</VStack>
													</Checkbox>
												</VStack>
												<Divider />
												{sessionsWithQuote.upcomingSession.quote && (
													<VStack w="full">
														<VStack w="full" spacing={4}>
															{sessionsWithQuote.upcomingSession.quote.breakup.map((item, index) => (
																<HStack key={index} w="full" justify="space-between">
																	<Text w="full" maxW="44" fontSize="sm">
																		{item.label}
																	</Text>
																	<Text fontWeight="semibold" color="primary.500">
																		{item.payable ? (
																			<chakra.span
																				textDecoration="line-through"
																				fontSize="smaller"
																				fontWeight="normal"
																				mr="2"
																			>
																				₹ {item.amount}
																			</chakra.span>
																		) : (
																			`₹ ${item.amount}`
																		)}{" "}
																		{item.payable ? `₹ ${item.payable}` : ""}
																	</Text>
																</HStack>
															))}
														</VStack>
														<Divider />
														<Text alignSelf="flex-end" fontSize="xl" color="primary.500" fontWeight="semibold">
															₹{sessionsWithQuote.upcomingSession.quote.totalAmount.toFixed(2)}
														</Text>
													</VStack>
												)}
											</VStack>
										)}
									</VStack>
								) : (
									<Center w="full" py="4">
										<Text fontSize="md" fontWeight="semisemibold" color="text.400">
											Couldn&apos;t find the quote for you.
										</Text>
									</Center>
								)}
							</ModalBody>
						)}

						{quoteData?.cafeteriaRegistrationQuote.sessionWiseQuotes.length ? (
							<ModalFooter>
								<Button
									type="submit"
									colorScheme="primary"
									size="md"
									rightIcon={<Icon as={ArrowRight} />}
									isLoading={isLoading}
									onClick={handlePayment}
								>
									Pay ₹{quoteData?.cafeteriaRegistrationQuote.totalAmount}
								</Button>
							</ModalFooter>
						) : (
							<></>
						)}
					</ModalContent>
				</ModalOverlay>
			</Modal>
		</>
	)
}
