import { VStack, Container, Heading, Text, List, ListItem } from "@chakra-ui/react"
import React from "react"
import { Header } from "../components"
import { GRADIENT } from "../constants"

export const TermsAndConditions: React.FC = () => {
	return (
		<VStack w="full" h="100vh" overflow="auto" bg={GRADIENT}>
			<Header />
			<Container flex="1" h="full" w="full" maxW="container.xl">
				<VStack w="full" align="stretch" pb="12" spacing={6}>
					<Heading fontSize="2xl">Terms & Conditions</Heading>
					<Text>
						Welcome to Prometheus School&apos;s Razorpay account, which provides parents/guardians of enrolled students with the ability to recharge
						their child&apos;s BLE card for tuckshop facility. By accessing or using our services, you agree to be bound by these terms and
						conditions.
					</Text>
					<List spacing={16}>
						<ListItem>
							<Heading fontSize="md">1. Description of Services</Heading>
							<Text>
								Prometheus School&apos;s Razorpay account provides parents/guardians of enrolled students with the ability to recharge their
								child&apos;s BLE card for tuckshop facility. The Razorpay platform allows users to make payments online using a variety of
								payment methods.
							</Text>
						</ListItem>
						<ListItem>
							<Heading fontSize="md">2. User Eligibility</Heading>
							<Text>
								To use our school&apos;s Razorpay account, you must be a parent or guardian of an enrolled student at our school. By using the
								Razorpay platform, you represent and warrant that you have the legal authority to agree to these terms and conditions on behalf
								of yourself and your child.
							</Text>
						</ListItem>
						<ListItem>
							<Heading fontSize="md">3. Account Registration</Heading>
							<Text>
								To use the Razorpay platform, you must create an account with your email address and choose a password. You are responsible for
								maintaining the confidentiality of your account information and password and for all activities that occur under your account.
							</Text>
						</ListItem>
						<ListItem>
							<Heading fontSize="md">4. Payment Terms</Heading>
							<Text>
								By using school&apos;s Razorpay account, you agree to pay all fees and charges associated with the services provided, including
								any applicable taxes. Payment methods may include credit cards, debit cards, and net banking. All payments are processed
								securely through the Razorpay platform.
							</Text>
						</ListItem>
						<ListItem>
							<Heading fontSize="md">5. User Responsibilities</Heading>
							<Text>
								You agree to use the Razorpay platform and associated services only for lawful purposes and in compliance with all applicable
								laws and regulations. You are responsible for maintaining the security of your account and for ensuring that all information
								provided to our school is accurate and up-to-date.
							</Text>
						</ListItem>
						<ListItem>
							<Heading fontSize="md">6. Limitation of Liability</Heading>
							<Text>
								Our school will not be liable for any damages or losses arising from your use of the Razorpay platform or associated services,
								including but not limited to direct, indirect, incidental, punitive, and consequential damages. This limitation of liability
								applies to the fullest extent permitted by law.
							</Text>
						</ListItem>
						<ListItem>
							<Heading fontSize="md">7. Governing Law and Jurisdiction</Heading>
							<Text>
								These terms and conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising under
								or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of Gautam Buddha
								Nagar, Noida, UP.
							</Text>
						</ListItem>
					</List>
				</VStack>
			</Container>
		</VStack>
	)
}
