import React from "react"
import { BottomNavigationTabs } from "./components"
import { useMeQuery, useMeUpdateSubscription } from "./graphql"
import { RootRouter } from "./routes"

export const App: React.FC = () => {
	const [{ data }] = useMeQuery()
	useMeUpdateSubscription({
		variables: { studentId: data?.me?._id || "" },
		pause: !data?.me?._id,
	})

	return (
		<div id="app">
			<RootRouter />
			{data?.me && <BottomNavigationTabs />}
		</div>
	)
}
