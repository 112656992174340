import {
	Alert,
	AlertDescription,
	Button,
	chakra,
	Divider,
	Heading,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
	VStack,
} from "@chakra-ui/react"
import React from "react"
import { ExtendedStudentFragment } from "../../graphql"
import { AddPaytankFundsModal } from "./AddPaytankFundsModal"
import { BlockTagForPaytankButton } from "./BlockTagForPaytankButton"
import { PaytankOrdersHistory } from "./PaytankOrdersHistory"
import { UnblockTagForPaytankButton } from "./UnblockTagForPaytankButton"

export type PaytankBalanceModalProps = {
	isOpen: boolean
	onClose: () => void
	student: ExtendedStudentFragment
}

export const PaytankBalanceModal: React.FC<PaytankBalanceModalProps> = ({ isOpen, onClose, student }) => {
	const { getButtonProps, ...disclosure } = useDisclosure()

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="3xl">
				<ModalOverlay>
					<ModalContent>
						<ModalBody as={VStack} w="full" spacing={4}>
							<Heading fontSize="lg">Paytank</Heading>
							{student.tag?.isBlockedForPaytank && (
								<Alert variant="subtle" status="error" flexDirection="column">
									<AlertDescription fontSize="sm" color="red.600">
										The tag is not active for paytank
									</AlertDescription>
									<UnblockTagForPaytankButton />
								</Alert>
							)}
							<VStack spacing={0}>
								<Heading color="primary.500">
									<chakra.span fontSize="xl">₹</chakra.span>
									{student.paytankBalance || 0}
								</Heading>
								<Text color="primary.500">Balance</Text>
							</VStack>
							<Button colorScheme="primary" size="sm" {...getButtonProps()}>
								Add Funds
							</Button>
							{!student.tag?.isBlockedForPaytank && <BlockTagForPaytankButton />}
							<Divider />
							<PaytankOrdersHistory />
						</ModalBody>
					</ModalContent>
				</ModalOverlay>
			</Modal>

			<AddPaytankFundsModal
				isOpen={disclosure.isOpen}
				onClose={() => {
					disclosure.onClose()
					onClose()
				}}
				student={student}
			/>
		</>
	)
}
