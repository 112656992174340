import { Center, Container, Grid, Heading, HStack, Img, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import { formatDistanceToNow } from "date-fns"
import React from "react"
import { useParams } from "react-router-dom"
import { DashboardHeader, Nav } from "../../components"
import { GRADIENT } from "../../constants"
import { useGalleryByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"
import { Navigation, Pagination, Scrollbar } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"
import "../../components/gallery/styles.css"

export const GalleryById: React.FC = () => {
	const { galleryId = "" } = useParams<{ galleryId: string }>()

	const [{ data, fetching, error }] = useGalleryByIdQuery({
		variables: { galleryId },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.galleryById ? (
						<VStack w="full" maxW="lg" align="flex-start" shadow="sm" rounded="xl" py={2} px={4} bg="whiteAlpha.600">
							<VStack w="full" align="flex-start" spacing={0}>
								<HStack w="full" justify="space-between">
									<HStack flex={1} spacing={0}>
										<VStack w="full" align="flex-start" spacing={2} pl={2}>
											<Heading fontSize="sm" fontWeight="semibold">
												{data.galleryById.title && (
													<Text fontSize="x-large" fontWeight="medium" whiteSpace="pre-line">
														{data.galleryById.title}
													</Text>
												)}
											</Heading>
										</VStack>
									</HStack>
								</HStack>
							</VStack>

							<VStack as="div" w="full" align="stretch" p={1}>
								<Text fontSize="12" color="blackAlpha.700">
									{formatDistanceToNow(new Date(data.galleryById.createdAt), {
										addSuffix: true,
									})}
								</Text>

								{data.galleryById.attachments?.videos?.length ? (
									<Grid>
										<Swiper
											pagination={{
												type: "progressbar",
												clickable: true,
											}}
											navigation={true}
											modules={[Pagination, Navigation, Scrollbar]}
											className="mySwiper"
											keyboard={{
												enabled: true,
											}}
										>
											{data.galleryById?.attachments.videos.map((video, index) => (
												<SwiperSlide key={index}>
													<video key={index} style={{ borderRadius: "12px" }} src={video.url} controls />
												</SwiperSlide>
											))}
										</Swiper>
									</Grid>
								) : (
									data.galleryById.attachments?.pictures?.length && (
										<Grid>
											<Swiper
												pagination={{
													type: "progressbar",
													clickable: true,
												}}
												navigation={true}
												modules={[Pagination, Navigation, Scrollbar]}
												className="mySwiper"
												keyboard={{
													enabled: true,
												}}
											>
												{data.galleryById?.attachments.pictures.map((picture, index) => (
													<SwiperSlide key={index}>
														<Img key={index} src={picture.url} />
													</SwiperSlide>
												))}
											</Swiper>
										</Grid>
									)
								)}
								{data.galleryById.description && (
									<Text fontSize="md" whiteSpace="pre-line">
										{data.galleryById.description}
									</Text>
								)}
							</VStack>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the gallery.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
