import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from "@chakra-ui/react"
import React from "react"
import { ExtendedStudentFragment } from "../../graphql"
import { RedeemPointsModal } from "./RedeemPointsModal"
import { StudentPenaltiesList } from "./StudentPenaltiesList"
import { StudentPointsRedeemTransactionList } from "./StudentPointsRedeemTransactionList"
import { StudentRewardsList } from "./StudentRewardsList"

export type StudentPointsModalProps = {
	isOpen: boolean
	onClose: () => void
	student: ExtendedStudentFragment
}

export const StudentPointsModal: React.FC<StudentPointsModalProps> = ({ isOpen, onClose, student }) => {
	const { getButtonProps, ...disclosure } = useDisclosure()
	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="xl">
				<ModalOverlay>
					<ModalContent>
						<ModalBody>
							<Tabs variant="soft-rounded" colorScheme="primary">
								<TabList>
									<Tab>Rewards</Tab>
									<Tab>Penalties</Tab>
									<Tab>Transactions</Tab>
								</TabList>
								<TabPanels>
									<TabPanel>
										<StudentRewardsList />
									</TabPanel>
									<TabPanel>
										<StudentPenaltiesList />
									</TabPanel>
									<TabPanel>
										<StudentPointsRedeemTransactionList />
									</TabPanel>
								</TabPanels>
							</Tabs>
						</ModalBody>
						<ModalFooter>
							<Button w="full" colorScheme="purple" isDisabled={(student.pointsBalance || 0) <= 0} {...getButtonProps()}>
								Redeem {student.pointsBalance} points
							</Button>
						</ModalFooter>
					</ModalContent>
				</ModalOverlay>
			</Modal>
			<RedeemPointsModal isOpen={disclosure.isOpen} onClose={disclosure.onClose} student={student} />
		</>
	)
}
