import { Box, Center, Heading, Icon, IconButton, Popover, PopoverBody, PopoverContent, PopoverTrigger, Spinner, Text, VStack } from "@chakra-ui/react"
import { faCheckCircle, faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef, useState } from "react"
import { useInViewport } from "react-in-viewport"
import { AllPointsRedeemItemsQueryVariables, ExtendedStudentFragment, PointsRedeemItemFragment, useAllPointsRedeemItemsQuery } from "../../graphql"

export type PointsRedeemItemSelectorProps = {
	onChange: (item: PointsRedeemItemFragment | undefined) => void
	student: ExtendedStudentFragment
}

export const PointsRedeemItemSelector: React.FC<PointsRedeemItemSelectorProps> = ({ onChange, student }) => {
	const [pagination, setPagination] = useState<AllPointsRedeemItemsQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useAllPointsRedeemItemsQuery({
		variables: { pagination },
	})

	const nextPage = () => {
		if (data?.allPointsRedeemItems.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	const bottomRef = useRef<HTMLElement>() as React.MutableRefObject<HTMLElement>
	const { inViewport } = useInViewport(bottomRef)

	const [selectedItem, setSelectedItem] = useState<PointsRedeemItemFragment | undefined>()

	useEffect(() => {
		if (inViewport && data?.allPointsRedeemItems.hasNextPage) nextPage()
	}, [inViewport, nextPage, data?.allPointsRedeemItems.hasNextPage])

	const selectItem = (item: PointsRedeemItemFragment) => {
		if ((student.pointsBalance || 0) < item.points) return
		setSelectedItem(item)
	}

	const deselectItem = () => {
		setSelectedItem(undefined)
	}

	useEffect(() => {
		onChange(selectedItem)
	}, [selectedItem])

	return (
		<VStack>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.allPointsRedeemItems.pointsRedeemItems && data?.allPointsRedeemItems.pointsRedeemItems.length > 0 ? (
				<VStack w="full" align="stretch" spacing={8}>
					{data.allPointsRedeemItems.pointsRedeemItems.map((item) => (
						<Center
							h="full"
							key={item._id}
							position="relative"
							m={1}
							rounded="2xl"
							px="8"
							py="6"
							maxW="xs"
							cursor={(student.pointsBalance || 0) > item.points ? "pointer" : "not-allowed"}
							onClick={selectedItem?._id === item._id ? () => deselectItem() : () => selectItem(item)}
							bgColor={selectedItem?._id === item._id ? "purple.100" : "white.500"}
							opacity={(student.pointsBalance || 0) >= item.points ? 1 : 0.7}
						>
							{selectedItem?._id === item._id && (
								<>
									<Box pos="absolute" top="-0.5" left="-0.5" w=".8em" h=".8em" bgColor="white" rounded="full" />
									<Icon
										pos="absolute"
										top="-1"
										left="-1"
										color="purple.400"
										fontSize="lg"
										as={(props: any) => <FontAwesomeIcon icon={faCheckCircle} {...props} />}
									/>
								</>
							)}
							<Popover>
								<PopoverTrigger>
									<IconButton
										zIndex={10}
										pos="absolute"
										top="2"
										right="2"
										aria-label="description"
										size="2xs"
										color="blackAlpha.700"
										as={(props: any) => <FontAwesomeIcon icon={faCircleInfo} {...props} />}
									/>
								</PopoverTrigger>
								<PopoverContent bgColor="white">
									<PopoverBody>
										<Text textAlign="center" fontSize="sm">
											{item.description}
										</Text>
									</PopoverBody>
								</PopoverContent>
							</Popover>
							<VStack spacing={0}>
								<Text fontSize="2xl" color="purple.500" fontWeight="bold">
									+{item.points}
								</Text>
								<Heading fontSize="sm" color="blackAlpha.700">
									{item.name}
								</Heading>
							</VStack>
						</Center>
					))}
					{data.allPointsRedeemItems.hasNextPage && <div ref={bottomRef as any} style={{ width: "100%", paddingBottom: "24px" }} />}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any items.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
