import { Button, Center, Grid, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { AllTrendingSkillsQueryVariables, useAllTrendingSkillsQuery } from "../../graphql"
import { useDrawer } from "../../hooks"
import { TrendingSkill } from "./TrendingSkill"

export type AllTrendingSkillsProps = {
	topicId?: string
	forYou: boolean
}

export const AllTrendingSkills: React.FC<AllTrendingSkillsProps> = ({ topicId, forYou }) => {
	const [pagination, setPagination] = useState<AllTrendingSkillsQueryVariables["pagination"]>({ limit: 20, page: 1 })
	const [{ data, fetching, error }] = useAllTrendingSkillsQuery({
		variables: { topicId, forYou, pagination },
	})

	const { isShown } = useDrawer()

	const nextPage = () => {
		setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
	}

	return (
		<VStack w="full" maxW="6xl" align="stretch">
			<Grid w="full" templateColumns={{ base: "1fr", lg: isShown ? "1fr" : "repeat(2, 1fr)", xl: "repeat(2, 1fr)" }} gap={4}>
				{data?.allTrendingSkills?.trendingSkills?.map((trendingSkill) => (
					<TrendingSkill key={trendingSkill._id} trendingSkill={trendingSkill} />
				))}
			</Grid>
			{fetching ? (
				<Center w="full" maxW="6xl" py="8">
					<Spinner color="text.400" />
				</Center>
			) : data?.allTrendingSkills.trendingSkills && data?.allTrendingSkills.trendingSkills.length <= 0 ? (
				<Center w="full" maxW="6xl" py="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any trending skills.
					</Text>
				</Center>
			) : data?.allTrendingSkills?.hasNextPage ? (
				<Center w="full" maxW="lg" py="8">
					<Button variant="link" size="sm" colorScheme="blackAlpha" onClick={nextPage}>
						Show more
					</Button>
				</Center>
			) : error?.message ? (
				<Center w="full" maxW="6xl" minH="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						{error.message}
					</Text>
				</Center>
			) : (
				<Center w="full" maxW="6xl" py="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						You have seen it all
					</Text>
				</Center>
			)}
		</VStack>
	)
}
