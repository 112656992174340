/* eslint-disable @typescript-eslint/indent */
import { Badge, Divider, Grid, Heading, HStack, Icon, Link, Text, VStack } from "@chakra-ui/react"
import { faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import toWords from "split-camelcase-to-words"
import { AdmissionTypes, ExtendedStudentFragment, useMyClassTeacherQuery } from "../../graphql"

export type StudentDetailsProps = {
	student: ExtendedStudentFragment
}

export const StudentDetails: React.FC<StudentDetailsProps> = ({ student }) => {
	const [{ data }] = useMyClassTeacherQuery()

	return (
		<VStack w="full" maxW="xl" align="flex-start">
			<HStack w="full">
				<Heading as="h2" fontSize="md" fontWeight="semisemibold" color="text.400">
					Student Details
				</Heading>
			</HStack>
			<VStack spacing={4} bg="white.500" rounded="2xl" px="6" py="4" w="full" shadow="sm">
				<Grid w="full" templateColumns="repeat(2, 1fr)" gap={4}>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Phone
						</Text>
						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{student.phone || "-"}
						</Text>
					</VStack>

					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Email
						</Text>
						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{student.email || "-"}
						</Text>
					</VStack>
					{data?.myClassTeacher && (
						<VStack align="flex-start" spacing={0}>
							<Text fontSize="xs" color="text.400">
								Class Teacher
							</Text>
							<Link as={NavLink} to="/classTeacher" fontSize="sm" color="text.400" fontWeight="semibold">
								{data.myClassTeacher.name || "-"}
							</Link>
						</VStack>
					)}
				</Grid>
				<Divider />
				<Grid w="full" templateColumns="repeat(2, 1fr)" gap={4}>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Admission ID
						</Text>
						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{student.admissionId}
						</Text>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Admission Date
						</Text>
						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{student.admissionDate ? format(new Date(student.admissionDate), "dd/MM/yyyy") : "-"}
						</Text>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Admission Type
						</Text>
						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{student.admissionType ? toWords(student.admissionType) : "-"}
						</Text>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Nationality
						</Text>
						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{student.nationality || "-"}
						</Text>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Date of birth
						</Text>
						<HStack>
							<Text fontSize="sm" color="text.400" fontWeight="semibold">
								{student.dateOfBirth ? format(new Date(student.dateOfBirth), "P") : "-"}
							</Text>
						</HStack>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Blood Group
						</Text>
						<HStack>
							<Text fontSize="sm" color="text.400" fontWeight="semibold">
								{student.bloodGroup?.replace("_NEG", "-")?.replace("_POS", "+") || "-"}
							</Text>
						</HStack>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Gender
						</Text>
						<HStack>
							<Text fontSize="sm" color="text.400" fontWeight="semibold">
								{student.gender || "-"}
							</Text>
						</HStack>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Address
						</Text>
						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{student.address || "-"}
						</Text>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							ALS
						</Text>
						<HStack color={student.isALS ? "green" : "red"}>
							<Text fontSize="sm" fontWeight="semibold">
								{student.isALS ? "Yes" : "No"}
							</Text>
							<Icon as={(props: any) => <FontAwesomeIcon icon={student.isALS ? faCheckCircle : faXmarkCircle} {...props} />} />
						</HStack>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							ELS
						</Text>
						<HStack color={student.isELS ? "green" : "red"}>
							<Text fontSize="sm" fontWeight="semibold">
								{student.isELS ? "Yes" : "No"}
							</Text>
							<Icon as={(props: any) => <FontAwesomeIcon icon={student.isELS ? faCheckCircle : faXmarkCircle} {...props} />} />
						</HStack>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Status
						</Text>
						<HStack color={student.status === "Active" ? "green" : "red"}>
							<Text fontSize="sm" fontWeight="semibold">
								{student.status === "Active" ? "Active" : "Inactive"}
							</Text>
							<Icon as={(props: any) => <FontAwesomeIcon icon={student.status === "Active" ? faCheckCircle : faXmarkCircle} {...props} />} />
						</HStack>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Cafeteria Registration
						</Text>
						{student.admissionType === AdmissionTypes.Staff ? (
							<Text fontSize="sm" color="text.400" fontWeight="semibold">
								Not applicable
							</Text>
						) : (
							<VStack align="flex-start" spacing={4}>
								{student.cafeteriaRegistration ? (
									<VStack w="full" align="flex-start" spacing={0}>
										<HStack color="green">
											<Text fontSize="sm" fontWeight="semibold">
												Registered
											</Text>
											<Icon as={(props: any) => <FontAwesomeIcon icon={faCheckCircle} {...props} />} />
											<Badge variant="solid" fontSize="x-small" colorScheme="green">
												Current
											</Badge>
										</HStack>

										<Text fontSize="xs" color="blackAlpha.700">
											<strong>{format(new Date(student.cafeteriaRegistration.since), "MMM dd yyyy")}</strong> to{" "}
											<strong>{format(new Date(student.cafeteriaRegistration.until), "MMM dd yyyy")}</strong>
										</Text>
									</VStack>
								) : (
									<HStack color="red">
										<Text fontSize="sm" fontWeight="semibold">
											Not Registered
										</Text>
										<Icon as={(props: any) => <FontAwesomeIcon icon={faXmarkCircle} {...props} />} />
										<Badge variant="solid" fontSize="x-small" colorScheme="red">
											Current
										</Badge>
									</HStack>
								)}

								{student.upcomingCafeteriaRegistration && (
									<VStack w="full" align="flex-start" spacing={0}>
										<HStack color="green">
											<Text fontSize="sm" fontWeight="semibold">
												Registered
											</Text>
											<Icon as={(props: any) => <FontAwesomeIcon icon={faCheckCircle} {...props} />} />
											<Badge variant="solid" fontSize="x-small" colorScheme="orange">
												Upcoming
											</Badge>
										</HStack>

										<Text fontSize="xs" color="blackAlpha.700">
											<strong>{format(new Date(student.upcomingCafeteriaRegistration.since), "MMM dd yyyy")}</strong> to{" "}
											<strong>{format(new Date(student.upcomingCafeteriaRegistration.until), "MMM dd yyyy")}</strong>
										</Text>
									</VStack>
								)}
							</VStack>
						)}
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Transport Mode
						</Text>

						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{student.transportMode || "-"}
						</Text>
					</VStack>
					<VStack align="flex-start" spacing={0} display={student.transportMode != "School Bus" ? "none" : "block"}>
						<Text fontSize="xs" color="text.400">
							Bus Route
						</Text>

						<Text fontSize="sm" color="text.400" fontWeight="semibold">
							{student.busRoute?.routeName}
						</Text>
					</VStack>
				</Grid>
				<Divider />
				<Grid w="full" templateColumns="repeat(2, 1fr)" gap={4}>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Father
						</Text>
						<VStack align="flex-start" spacing={0}>
							<Text fontSize="sm" color="text.400" fontWeight="semibold">
								{student.father?.name || "-"}
							</Text>
							<Text fontSize="xs" color="text.400" fontWeight="semibold">
								{student.father?.phone || ""}
							</Text>
							<Text fontSize="xs" color="text.400" fontWeight="semibold">
								{student.father?.email || ""}
							</Text>
							<Text fontSize="xs" color="text.400" fontWeight="semibold">
								{student.father?.nfcTagId || ""}
							</Text>
						</VStack>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Mother
						</Text>
						<VStack align="flex-start" spacing={0}>
							<Text fontSize="sm" color="text.400" fontWeight="semibold">
								{student.mother?.name || "-"}
							</Text>
							<Text fontSize="xs" color="text.400" fontWeight="semibold">
								{student.mother?.phone || ""}
							</Text>
							<Text fontSize="xs" color="text.400" fontWeight="semibold">
								{student.mother?.email || ""}
							</Text>
							<Text fontSize="xs" color="text.400" fontWeight="semibold">
								{student.mother?.nfcTagId || ""}
							</Text>
						</VStack>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Guardian 1
						</Text>
						<VStack align="flex-start" spacing={0}>
							<Text fontSize="sm" color="text.400" fontWeight="semibold">
								{student.guardian1?.name || ""}
							</Text>
							<Text fontSize="xs" color="text.400" fontWeight="semibold">
								{student.guardian1?.phone || ""}
							</Text>
							<Text fontSize="xs" color="text.400" fontWeight="semibold">
								{student.guardian1?.email || ""}
							</Text>
							<Text fontSize="xs" color="text.400" fontWeight="semibold">
								{student.guardian1?.nfcTagId || ""}
							</Text>
						</VStack>
					</VStack>
					<VStack align="flex-start" spacing={0}>
						<Text fontSize="xs" color="text.400">
							Guardian 2
						</Text>
						<VStack align="flex-start" spacing={0}>
							<Text fontSize="sm" color="text.400" fontWeight="semibold">
								{student.guardian2?.name || ""}
							</Text>
							<Text fontSize="xs" color="text.400" fontWeight="semibold">
								{student.guardian2?.phone || ""}
							</Text>
							<Text fontSize="xs" color="text.400" fontWeight="semibold">
								{student.guardian2?.email || ""}
							</Text>
							<Text fontSize="xs" color="text.400" fontWeight="semibold">
								{student.guardian2?.nfcTagId || ""}
							</Text>
						</VStack>
					</VStack>
				</Grid>
			</VStack>
		</VStack>
	)
}
