import { chakra, Container, HStack, Icon, Link, VStack } from "@chakra-ui/react"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { SCHOOL_SITE_URL, STAFF_SITE_URL } from "../../constants"
import { Logo } from "./Logo"

export const Header: React.FC = () => {
	return (
		<Container maxW="container.xl">
			<HStack justify="space-between" py="3">
				<Logo />
				<VStack align="flex-end">
					<HStack as="nav" display={{ base: "none", md: "flex" }} spacing="6">
						<HStack fontSize="xs" color="primary.500">
							<chakra.span>Log in as</chakra.span>
							<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} />
						</HStack>
						<Link href={SCHOOL_SITE_URL} isExternal>
							School
						</Link>
						<Link href={STAFF_SITE_URL} isExternal>
							Staff
						</Link>
					</HStack>
					<HStack as="nav" display={{ base: "none", md: "flex" }} spacing="6">
						<Link href="/termsAndConditions">Terms & Conditions</Link>
						<Link href="/privacy">Privacy Policy</Link>
						<Link href="/refundAndCancellation">Refund & Cancellation</Link>
					</HStack>
				</VStack>
			</HStack>
		</Container>
	)
}
