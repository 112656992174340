/* eslint-disable @typescript-eslint/indent */
import { Alert, Button, Center, Heading, HStack, Icon, Spinner, Text, VStack } from "@chakra-ui/react"
import { faAdd, faMinus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import { startCase } from "lodash"
import React, { useState } from "react"
import {
	AllPaytankOrdersQueryVariables,
	PaymentOrderStatus,
	PaytankOrderTypes,
	useAllPaytankOrdersQuery,
	useMeQuery,
	useMyPaytankOrderSubscription,
} from "../../graphql"

export const PaytankOrdersHistory: React.FC = () => {
	const [{ data: meData }] = useMeQuery()

	useMyPaytankOrderSubscription({ variables: { studentId: meData?.me?._id || "" }, pause: !meData?.me?._id })

	const [pagination, setPagination] = useState<AllPaytankOrdersQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useAllPaytankOrdersQuery({
		variables: { pagination },
	})

	const nextPage = () => {
		setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
	}

	return (
		<VStack w="full" align="stretch">
			<Heading fontSize="sm">History</Heading>
			<VStack w="full" align="stretch">
				{data?.allPaytankOrders?.paytankOrders?.map((order) => (
					<HStack
						key={order._id}
						justify="space-between"
						bgColor={
							order.status === PaymentOrderStatus.Initiated
								? "yellow.100"
								: order.status === PaymentOrderStatus.Failed
								? "red.100"
								: "primary.100"
						}
						color={order.status === PaymentOrderStatus.Failed ? "red.600" : "primary.600"}
						p="4"
						rounded="xl"
					>
						<VStack align="stretch" spacing={0}>
							<HStack>
								<Icon
									color={order.type === PaytankOrderTypes.Credit ? "green.600" : "red.600"}
									as={(props: any) => <FontAwesomeIcon icon={order.type === PaytankOrderTypes.Credit ? faAdd : faMinus} {...props} />}
								/>
								<Heading color={order.type === PaytankOrderTypes.Credit ? "green.600" : "red.600"} fontSize="md">
									₹{order.amount}
								</Heading>
							</HStack>
							{order.merchant && (
								<Text fontSize="sm" fontWeight="semibold" maxW="sm">
									{order.merchant.name}
								</Text>
							)}
							{order.description && (
								<Text fontSize="xs" maxW="36">
									{order.description}
								</Text>
							)}
						</VStack>

						<VStack spacing={0}>
							<Text fontSize="sm" fontWeight="semibold">
								{order.status}
							</Text>
							{order.failureReason && <Text fontSize="xs">{startCase(order.failureReason)}</Text>}
							{(order.refundId || order.refundAmount || order.refundCreatedAt || order.refundFailedAt || order.refundProcessedAt) && (
								<Alert as={VStack} variant="subtle" status="error" spacing={0} mt="1" p="2" rounded="xl">
									<Heading fontSize="xs" color="red.600">
										Refund {order.refundId ? order.refundId : ""} {order.refundAmount ? " of ₹" + order.refundAmount : ""}{" "}
										{order.refundProcessedAt ? "processed" : order.refundFailedAt ? "failed" : "created"}
									</Heading>
									<Text fontSize="xs" color="red.600">
										{[order.refundCreatedAt, order.refundProcessedAt, order.refundFailedAt]
											.filter(Boolean)
											.map((date) => format(new Date(date), "MMM dd, yyyy p"))
											.join(" -> ")}
									</Text>
								</Alert>
							)}
						</VStack>
						<VStack spacing={0} align="flex-end">
							<Heading fontSize="xs">{order.orderId || order._id}</Heading>
							{order.paymentId && <Heading fontSize="xs">{order.paymentId}</Heading>}
							<Text>{format(new Date(order.createdAt), "dd/MM/yyyy p")}</Text>
						</VStack>
					</HStack>
				))}
			</VStack>
			{fetching ? (
				<Center w="full" py="8">
					<Spinner color="text.400" />
				</Center>
			) : data?.allPaytankOrders?.paytankOrders && data.allPaytankOrders.paytankOrders.length <= 0 ? (
				<Center w="full" py="8">
					<Text fontSize="sm" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any transactions.
					</Text>
				</Center>
			) : data?.allPaytankOrders?.hasNextPage ? (
				<Center w="full" py="8">
					<Button variant="link" size="sm" colorScheme="blackAlpha" onClick={nextPage}>
						Show more
					</Button>
				</Center>
			) : (
				error?.message && (
					<Center w="full" minH="8">
						<Text fontSize="sm" fontWeight="semibold" color="red.400">
							{error.message.replace("[GraphQL] ", "")}
						</Text>
					</Center>
				)
			)}
		</VStack>
	)
}
