import {
	AspectRatio,
	AspectRatioProps,
	Box,
	ButtonGroup,
	Center,
	Container,
	Grid,
	Heading,
	HStack,
	Icon,
	IconButton,
	Spinner,
	Text,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion, MotionProps } from "framer-motion"
import React from "react"
import { Card, DashboardHeader, Image, Nav } from "../components"
import { GRADIENT } from "../constants"
import { useMySchoolQuery, useSchoolStatisticsQuery } from "../graphql"
import { useDrawer } from "../hooks"

const MotionAspectRatio = motion<AspectRatioProps & MotionProps>(AspectRatio)

export const School: React.FC = () => {
	const { isShown, show, hide } = useDrawer()

	const [{ data, fetching }] = useMySchoolQuery()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [{ data: statisticsData, fetching: fetchingStatistics, error: statisticsError }] = useSchoolStatisticsQuery()

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0" overflow="hidden">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					py={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					{fetching ? (
						<Center w="full" py="16">
							<VStack>
								<Spinner size="sm" color="text.400" />
								<Text fontSize="xs" color="text.400">
									Loading dashboard
								</Text>
							</VStack>
						</Center>
					) : data?.mySchool ? (
						<>
							{isMobile ? (
								<DashboardHeader />
							) : (
								<ButtonGroup p="4" pos="absolute" top="2px" left="2px" zIndex={99}>
									<IconButton aria-label="hide-drawer-btn" onClick={hide} isDisabled={!(!isMobile && isShown)} colorScheme="white">
										<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
									</IconButton>
									<IconButton aria-label="show-drawer-btn" onClick={show} isDisabled={!isMobile && isShown} colorScheme="white">
										<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
									</IconButton>
								</ButtonGroup>
							)}
							<Box mt={isMobile ? "12px !important" : "-16px !important"} w="full" position="relative">
								<AspectRatio
									bg="blackAlpha.300"
									roundedTopLeft={!isMobile && isShown ? "3xl" : "none"}
									ratio={isMobile ? 3 / 1 : 5 / 1}
									transition="all 200ms ease-in-out"
									w="full"
									cursor="pointer"
									zIndex={1}
								>
									<Image
										roundedTopLeft={!isMobile && isShown ? "3xl" : "none"}
										src={data.mySchool.cover?.url}
										alt="cover"
										style={{ objectFit: "cover" }}
									/>
								</AspectRatio>
							</Box>

							<HStack
								pos="relative"
								w="full"
								align={isMobile ? "center" : "flex-end"}
								px={isMobile ? 2 : 4}
								pt="4"
								spacing={4}
								mt={{
									base: "0 !important",
									md: "-80px !important",
									lg: "-120px !important",
								}}
							>
								<MotionAspectRatio
									ratio={1}
									w="full"
									maxW={{ base: 32, md: 36, xl: 48 }}
									rounded="2xl"
									shadow="2xl"
									whileHover={{ scale: 1.04 }}
									cursor="pointer"
									bgColor="whiteAlpha.500"
									zIndex={2}
								>
									{data?.mySchool?.picture ? (
										<Image src={data.mySchool.picture.url} alt="picture" rounded="2xl" style={{ objectFit: "contain" }} />
									) : (
										<></>
									)}
								</MotionAspectRatio>

								<VStack align="flex-start" pb="2">
									<Heading as="h1" fontSize={{ base: "lg", md: "xl" }} color="text.500">
										{data?.mySchool?.name}
									</Heading>
									<Heading as="h2" fontSize={{ base: "12", md: "xs" }} fontWeight="normal" color="text.400" maxW="xs">
										{data?.mySchool &&
											`${data?.mySchool?.location.city}, ${data?.mySchool?.location.state}, ${data?.mySchool?.location.address} - ${data?.mySchool?.location.pin}`}
									</Heading>
								</VStack>
							</HStack>
							<VStack w="full" maxW="xl" align="flex-start" px="4" spacing={4}>
								<Heading as="h2" fontSize="md" fontWeight="semibold" color="text.500">
									Statistics
								</Heading>
								{statisticsError ? (
									<Center py="4">
										<Text fontSize="md" fontWeight="semibold" color="text.400">
											{statisticsError.message.replace("[GraphQL ]", "")}
										</Text>
									</Center>
								) : (
									<Grid
										templateColumns={{
											base: "repeat(2, 1fr)",
											md: isShown ? "repeat(2, 1fr)" : "repeat(4, 1fr)",
											lg: "repeat(4, 1fr)",
											xl: "repeat(5, 1fr)",
										}}
										gap="4"
									>
										<Card>
											<VStack align="flex-start" spacing="6">
												<VStack align="flex-start" spacing="0">
													{fetchingStatistics ? (
														<Center py="2">
															<Spinner size="md" />
														</Center>
													) : (
														<Text fontSize="3xl" fontWeight="semibold">
															{statisticsData?.schoolStatistics.classes}
														</Text>
													)}
													<Text fontSize="xs" color="text.400">
														Classes
													</Text>
												</VStack>
											</VStack>
										</Card>

										<Card>
											<VStack align="flex-start" spacing="6">
												<VStack align="flex-start" spacing="0">
													{fetchingStatistics ? (
														<Center py="2">
															<Spinner size="md" />
														</Center>
													) : (
														<Text fontSize="3xl" fontWeight="semibold">
															{statisticsData?.schoolStatistics.subjects}
														</Text>
													)}
													<Text fontSize="xs" color="text.400">
														Subjects
													</Text>
												</VStack>
											</VStack>
										</Card>

										<Card>
											<VStack align="flex-start" spacing="6">
												<VStack align="flex-start" spacing="0">
													{fetchingStatistics ? (
														<Center py="2">
															<Spinner size="md" />
														</Center>
													) : (
														<Text fontSize="3xl" fontWeight="semibold">
															{statisticsData?.schoolStatistics.teachingStaff}
														</Text>
													)}
													<Text fontSize="xs" color="text.400">
														Teaching Staff
													</Text>
												</VStack>
											</VStack>
										</Card>

										<Card>
											<VStack align="flex-start" spacing="6">
												<VStack align="flex-start" spacing="0">
													{fetchingStatistics ? (
														<Center py="2">
															<Spinner size="md" />
														</Center>
													) : (
														<Text fontSize="3xl" fontWeight="semibold">
															{statisticsData?.schoolStatistics.nonTeachingStaff}
														</Text>
													)}
													<Text fontSize="xs" color="text.400">
														Non Teaching Staff
													</Text>
												</VStack>
											</VStack>
										</Card>

										<Card>
											<VStack align="flex-start" spacing="6">
												<VStack align="flex-start" spacing="0">
													{fetchingStatistics ? (
														<Center py="2">
															<Spinner size="md" />
														</Center>
													) : (
														<Text fontSize="3xl" fontWeight="semibold">
															{statisticsData?.schoolStatistics.students}
														</Text>
													)}
													<Text fontSize="xs" color="text.400">
														Students
													</Text>
												</VStack>
											</VStack>
										</Card>
									</Grid>
								)}
							</VStack>
						</>
					) : (
						<Center w="full" py="16">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Could not load your school
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
