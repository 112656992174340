/* eslint-disable @typescript-eslint/indent */
import { AspectRatio, Flex, Heading, HStack, Image, Tag, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import YouTube from "react-youtube"
import { AuthorTypes, ExtendedTrendingSkillFragment } from "../../graphql"

export type TrendingSkillProps = {
	trendingSkill: ExtendedTrendingSkillFragment
}

const youtube_parser = (url: any) => {
	const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
	const match = url.match(regExp)
	return match && match[7].length == 11 ? match[7] : false
}

export const TrendingSkill: React.FC<TrendingSkillProps> = ({ trendingSkill }) => {
	const isMobile = useBreakpointValue({ base: true, sm: false })

	const opts = {
		height: isMobile ? "300px" : "300px",
		width: isMobile ? "350px" : "400px",
	}

	const author = useMemo<{
		title: string
		subtitle: string
		picture: string
		link: string
	}>(
		() => ({
			title:
				trendingSkill.author.authorType === AuthorTypes.Student
					? `${trendingSkill.author.student?.name}`
					: trendingSkill.author.authorType === AuthorTypes.School
					? `${trendingSkill.author.school?.name}`
					: trendingSkill.author.authorType === AuthorTypes.Staff
					? `${trendingSkill.author.staff?.name}`
					: "",
			subtitle:
				trendingSkill.author.authorType === AuthorTypes.Student
					? `@${trendingSkill.author.student?.username}`
					: trendingSkill.author.authorType === AuthorTypes.School
					? `${trendingSkill.author.school?.location.city}, ${trendingSkill.author.school?.location.state}, ${trendingSkill.author.school?.location.address} - ${trendingSkill.author.school?.location.pin}`
					: trendingSkill.author.authorType === AuthorTypes.Staff
					? `@${trendingSkill.author.staff?.username}`
					: "",
			picture:
				trendingSkill.author.authorType === AuthorTypes.Student
					? trendingSkill.author.student?.picture?.variants?.thumbnail || trendingSkill.author.student?.picture?.url || ""
					: trendingSkill.author.authorType === AuthorTypes.School
					? trendingSkill.author.school?.picture?.variants?.thumbnail || trendingSkill.author.school?.picture?.url || ""
					: trendingSkill.author.authorType === AuthorTypes.Staff
					? trendingSkill.author.staff?.picture?.variants?.thumbnail || trendingSkill.author.staff?.picture?.url || ""
					: "",
			link:
				trendingSkill.author.authorType === AuthorTypes.Student && trendingSkill.author.student
					? `/students/${trendingSkill.author.student._id}`
					: trendingSkill.author.authorType === AuthorTypes.School
					? "/dashboard"
					: trendingSkill.author.authorType === AuthorTypes.Staff
					? `/staff/${trendingSkill.author.staffId}`
					: "",
		}),
		[trendingSkill]
	)
	const navigate = useNavigate()
	return (
		<VStack
			w="full"
			maxW="md"
			align="center"
			spacing={4}
			shadow="sm"
			rounded="xl"
			py={2}
			px={4}
			bgColor="whiteAlpha.600"
			onClick={() => navigate(`/trendingSkills/${trendingSkill._id}`)}
			style={{ cursor: "pointer" }}
		>
			<VStack w="full" align="flex-start" spacing={0}>
				<HStack w="full" justify="space-between">
					<HStack flex={1} spacing={0}>
						<AspectRatio ratio={1} w="12" rounded="xl" shadow="xl">
							<Image rounded="xl" src={author.picture || "https://picsum.photos/200"} alt={author.title} />
						</AspectRatio>
						<VStack w="full" align="flex-start" spacing={0} pl={2}>
							<Heading fontSize="sm" fontWeight="semibold">
								{author.title}
							</Heading>
							{author.subtitle && (
								<Heading fontSize="xs" fontWeight="normal" color="blackAlpha.700">
									{author.subtitle}
								</Heading>
							)}
						</VStack>
					</HStack>
				</HStack>
			</VStack>
			<YouTube videoId={youtube_parser(trendingSkill.links)} opts={opts} />
			<VStack w="full" align="stretch" alignSelf="flex-start">
				<Heading fontSize="md">{trendingSkill.name}</Heading>
				<Text fontSize="sm">
					{trendingSkill.description.length > 200 ? trendingSkill.description.slice(0, 200) + "..." : trendingSkill.description}
				</Text>
				<Flex flexWrap="wrap">
					<Tag m="1" colorScheme="purple" textTransform="capitalize">
						{trendingSkill.topic.name}
					</Tag>
					<Tag m="1" colorScheme="purple">
						Age: {trendingSkill.minAge}-{trendingSkill.maxAge}
					</Tag>
				</Flex>
			</VStack>
		</VStack>
	)
}
