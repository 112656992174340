import { MsalProvider } from "@azure/msal-react"
import { ChakraProvider } from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "urql"
import { App } from "./App"
import { ContextProvider } from "./context"
import { createUrqlClient } from "./graphql"
import { msalInstance } from "./msal"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import "./styles/index.css"
import { theme } from "./theme"

const container = document.getElementById("root")
if (!container) throw new Error("Failed to find the root element")
const root = ReactDOM.createRoot(container)

const urqlClient = createUrqlClient()

root.render(
	<MsalProvider instance={msalInstance}>
		<React.StrictMode>
			<ChakraProvider theme={theme}>
				<Provider value={urqlClient}>
					<BrowserRouter>
						<ContextProvider>
							<App />
						</ContextProvider>
					</BrowserRouter>
				</Provider>
			</ChakraProvider>
		</React.StrictMode>
	</MsalProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
