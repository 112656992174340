import FullCalendar, { EventClickArg } from "@fullcalendar/react"
import {
	Center,
	Container,
	Heading,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Stack,
	Tag,
	Text,
	useBreakpointValue,
	useDisclosure,
	VStack,
} from "@chakra-ui/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import { format } from "date-fns"
import React, { useState } from "react"
import { DashboardHeader, Nav } from "../../components"
import { GRADIENT } from "../../constants"
import { AllHolidaysQueryVariables, useAllHolidaysQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

import "@fullcalendar/common/main.css"

import "./custom.css"

export const Holidays: React.FC = () => {
	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [pagination] = useState<AllHolidaysQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useAllHolidaysQuery({
		variables: { pagination },
	})

	const { isOpen, onOpen, onClose } = useDisclosure()

	const [eventClickInfo, setEventClickInfo] = useState<EventClickArg>()

	const handleEventClick = async (clickInfo: EventClickArg) => {
		onOpen()
		setEventClickInfo(clickInfo)
	}

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />
					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Calendar
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								{
									// TODO: add sub heading
								}
							</Heading>
						</VStack>
					</Stack>
					{fetching ? (
						<Center py="4">
							<Spinner color="text.400" />
						</Center>
					) : data?.allHolidays.holidays ? (
						<>
							<FullCalendar
								headerToolbar={{ left: "prev,next,today", center: "title", right: "dayGridMonth,timeGridWeek,timeGridDay" }}
								initialView="dayGridMonth"
								plugins={[dayGridPlugin, timeGridPlugin]}
								height="auto"
								events={data.allHolidays.holidays.map(({ name, startDate, endDate, _id, eventType }) => {
									return {
										title: name,
										start: format(new Date(startDate), "yyyy-MM-dd"),
										end: format(new Date(endDate), "yyyy-MM-dd"),
										id: _id,
										backgroundColor: eventType == "Holiday" ? "#3a8f67" : "",
										borderColor: eventType == "Holiday" ? "#3a8f67" : "",
									}
								})}
								eventContent={renderEventContent}
								eventClick={handleEventClick}
							/>

							<Modal isCentered isOpen={isOpen} onClose={onClose}>
								<ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) hue-rotate(10deg)" />
								<ModalContent>
									<ModalHeader bg={eventClickInfo?.event._def.ui.backgroundColor == "#3a8f67" ? "#60b38c" : "#7eaae2"} borderRadius="md">
										<Tag>{eventClickInfo?.event.title}</Tag>
									</ModalHeader>
									<ModalBody>
										<Heading as="h4" size="sm" fontWeight="medium" color="blackAlpha.700">
											{eventClickInfo?.event._instance?.range.start.toDateString()}
										</Heading>
									</ModalBody>
									<ModalCloseButton />
								</ModalContent>
							</Modal>
						</>
					) : (
						<Center py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find any holidays.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}

function renderEventContent(eventInfo: any) {
	return (
		<>
			<div style={{ textAlign: "center" }}>{eventInfo.event.title}</div>
		</>
	)
}
