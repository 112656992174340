import gql from "graphql-tag"
import * as Urql from "urql"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string
	String: string
	Boolean: boolean
	Int: number
	Float: number
	DateTime: any
}

export enum AdmissionTypes {
	NonResidentIndian = "NonResidentIndian",
	ResidentIndian = "ResidentIndian",
	Staff = "Staff",
}

export type AmountBreakup = {
	__typename?: "AmountBreakup"
	amount: Scalars["Float"]
	label: Scalars["String"]
	payable?: Maybe<Scalars["Float"]>
}

export type AttendanceTiming = {
	__typename?: "AttendanceTiming"
	inAt: Scalars["String"]
	inStartBeforeMinutes: Scalars["Float"]
	notifyTeacherAfterMinutes: Scalars["Float"]
	outAt: Scalars["String"]
	outStartBeforeMinutes: Scalars["Float"]
}

export type AuthResponse = {
	__typename?: "AuthResponse"
	errors?: Maybe<Array<FieldError>>
	sessionId?: Maybe<Scalars["String"]>
	student?: Maybe<ExtendedStudent>
}

export type Author = {
	__typename?: "Author"
	authorType: AuthorTypes
	schoolId?: Maybe<Scalars["String"]>
	staffId?: Maybe<Scalars["String"]>
	studentId?: Maybe<Scalars["String"]>
}

export enum AuthorTypes {
	School = "School",
	Staff = "Staff",
	Student = "Student",
}

export enum BloodGroups {
	AbNeg = "AB_NEG",
	AbPos = "AB_POS",
	ANeg = "A_NEG",
	APos = "A_POS",
	BNeg = "B_NEG",
	BPos = "B_POS",
	ONeg = "O_NEG",
	OPos = "O_POS",
}

export type BusRoute = {
	__typename?: "BusRoute"
	_id: Scalars["String"]
	busConductorName: Scalars["String"]
	busConductorPhone?: Maybe<Scalars["String"]>
	busDriverName: Scalars["String"]
	busDriverPhone?: Maybe<Scalars["String"]>
	createdAt: Scalars["DateTime"]
	routeName: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	vehicleNo?: Maybe<Scalars["String"]>
}

export type CafeteriaRegistrationLog = {
	__typename?: "CafeteriaRegistrationLog"
	_id: Scalars["String"]
	cafeteriaRegistrationOrderId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	schoolSessionId: Scalars["String"]
	since: Scalars["DateTime"]
	studentId: Scalars["String"]
	until: Scalars["DateTime"]
	updatedAt: Scalars["DateTime"]
}

export type CafeteriaRegistrationOrder = {
	__typename?: "CafeteriaRegistrationOrder"
	_id: Scalars["String"]
	amount: Scalars["Float"]
	createdAt: Scalars["DateTime"]
	failureReason?: Maybe<Scalars["String"]>
	orderId?: Maybe<Scalars["String"]>
	paymentId?: Maybe<Scalars["String"]>
	quoteId: Scalars["String"]
	refundAmount?: Maybe<Scalars["Float"]>
	refundCreatedAt?: Maybe<Scalars["DateTime"]>
	refundFailedAt?: Maybe<Scalars["DateTime"]>
	refundId?: Maybe<Scalars["String"]>
	refundProcessedAt?: Maybe<Scalars["DateTime"]>
	status: PaymentOrderStatus
	studentId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type CafeteriaRegistrationQuote = {
	__typename?: "CafeteriaRegistrationQuote"
	_id: Scalars["String"]
	cafeteriaRegistrationChargeId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	sessionWiseQuotes: Array<CafeteriaRegistrationSessionQuote>
	studentId: Scalars["String"]
	totalAmount: Scalars["Float"]
	updatedAt: Scalars["DateTime"]
}

export type CafeteriaRegistrationSessionQuote = {
	__typename?: "CafeteriaRegistrationSessionQuote"
	breakup: Array<AmountBreakup>
	isPartial: Scalars["Boolean"]
	schoolSessionEndAt: Scalars["DateTime"]
	schoolSessionId: Scalars["String"]
	schoolSessionStartAt: Scalars["DateTime"]
	totalAmount: Scalars["Float"]
}

export type Circular = {
	__typename?: "Circular"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	message: Scalars["String"]
	targets: Array<UserTargets>
	title: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type Class = {
	__typename?: "Class"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type ClassSection = {
	__typename?: "ClassSection"
	_id: Scalars["String"]
	classId: Scalars["String"]
	classTeacherId?: Maybe<Scalars["String"]>
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	schoolTiming?: Maybe<SchoolTiming>
	schoolTimingId?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
}

export type Department = {
	__typename?: "Department"
	_id: Scalars["String"]
	attendanceTiming: StaffAttendanceTiming
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	type: DepartmentTypes
	updatedAt: Scalars["DateTime"]
}

export enum DepartmentTypes {
	NonTeaching = "NonTeaching",
	Teaching = "Teaching",
}

export type EditStudentInput = {
	address?: InputMaybe<Scalars["String"]>
	bloodGroup?: InputMaybe<BloodGroups>
	dateOfBirth?: InputMaybe<Scalars["DateTime"]>
	email?: InputMaybe<Scalars["String"]>
	father?: InputMaybe<ParentInput>
	gender?: InputMaybe<Genders>
	guardian1?: InputMaybe<ParentInput>
	guardian2?: InputMaybe<ParentInput>
	mother?: InputMaybe<ParentInput>
	name?: InputMaybe<Scalars["String"]>
	phone?: InputMaybe<Scalars["String"]>
}

export type EmbeddedPdf = {
	__typename?: "EmbeddedPDF"
	createdAt: Scalars["DateTime"]
	key: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	url: Scalars["String"]
}

export type EmbeddedPicture = {
	__typename?: "EmbeddedPicture"
	createdAt: Scalars["DateTime"]
	key: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	url: Scalars["String"]
	variants?: Maybe<PictureVariants>
}

export type EmbeddedVideo = {
	__typename?: "EmbeddedVideo"
	createdAt: Scalars["DateTime"]
	key: Scalars["String"]
	thumbnail?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
	url: Scalars["String"]
	variants?: Maybe<VideoVariants>
}

export type ExtendedAuthor = {
	__typename?: "ExtendedAuthor"
	authorType: AuthorTypes
	school?: Maybe<School>
	schoolId?: Maybe<Scalars["String"]>
	staff?: Maybe<Staff>
	staffId?: Maybe<Scalars["String"]>
	student?: Maybe<Student>
	studentId?: Maybe<Scalars["String"]>
}

export type ExtendedClass = {
	__typename?: "ExtendedClass"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	sections: Array<ExtendedClassSection>
	updatedAt: Scalars["DateTime"]
}

export type ExtendedClassSection = {
	__typename?: "ExtendedClassSection"
	_id: Scalars["String"]
	classId: Scalars["String"]
	classTeacher?: Maybe<Staff>
	classTeacherId?: Maybe<Scalars["String"]>
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	schoolTiming?: Maybe<SchoolTiming>
	schoolTimingId?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
}

export type ExtendedFeedPost = {
	__typename?: "ExtendedFeedPost"
	_id: Scalars["String"]
	attachments?: Maybe<MediaAttachments>
	author: ExtendedAuthor
	createdAt: Scalars["DateTime"]
	isApproved?: Maybe<Scalars["Boolean"]>
	isLiked?: Maybe<Scalars["Boolean"]>
	likes?: Maybe<Array<FeedPostLike>>
	links?: Maybe<Links>
	text?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
}

export type ExtendedHouse = {
	__typename?: "ExtendedHouse"
	_id: Scalars["String"]
	captain?: Maybe<Student>
	captainId?: Maybe<Scalars["String"]>
	colorHex: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	pointsBalance?: Maybe<Scalars["Float"]>
	updatedAt: Scalars["DateTime"]
}

export type ExtendedPaytankOrder = {
	__typename?: "ExtendedPaytankOrder"
	_id: Scalars["String"]
	amount: Scalars["Float"]
	createdAt: Scalars["DateTime"]
	description?: Maybe<Scalars["String"]>
	failureReason?: Maybe<PaytankFailureReasons>
	merchant?: Maybe<PaytankMerchant>
	merchantId?: Maybe<Scalars["String"]>
	orderId?: Maybe<Scalars["String"]>
	paymentId?: Maybe<Scalars["String"]>
	refundAmount?: Maybe<Scalars["Float"]>
	refundCreatedAt?: Maybe<Scalars["DateTime"]>
	refundFailedAt?: Maybe<Scalars["DateTime"]>
	refundId?: Maybe<Scalars["String"]>
	refundProcessedAt?: Maybe<Scalars["DateTime"]>
	status: PaymentOrderStatus
	student: Student
	studentId: Scalars["String"]
	type: PaytankOrderTypes
	updatedAt: Scalars["DateTime"]
}

export type ExtendedPenalty = {
	__typename?: "ExtendedPenalty"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	from: ExtendedAuthor
	penaltyItemIds: Array<Scalars["String"]>
	penaltyItems: Array<ExtendedPenaltyItem>
	to: Array<ExtendedAuthor>
	totalPoints: Scalars["Float"]
	updatedAt: Scalars["DateTime"]
}

export type ExtendedPenaltyItem = {
	__typename?: "ExtendedPenaltyItem"
	_id: Scalars["String"]
	category: PenaltyCategory
	categoryId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	points: Scalars["Float"]
	updatedAt: Scalars["DateTime"]
}

export type ExtendedPointsRedeemTransaction = {
	__typename?: "ExtendedPointsRedeemTransaction"
	_id: Scalars["String"]
	approvedAt?: Maybe<Scalars["DateTime"]>
	arePointsRefunded?: Maybe<Scalars["Boolean"]>
	author: ExtendedAuthor
	createdAt: Scalars["DateTime"]
	isApproved?: Maybe<Scalars["Boolean"]>
	isRejected?: Maybe<Scalars["Boolean"]>
	pointsRedeemItem: PointsRedeemItem
	pointsRedeemItemId: Scalars["String"]
	rejectedAt?: Maybe<Scalars["DateTime"]>
	remarks?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
}

export type ExtendedResource = {
	__typename?: "ExtendedResource"
	_id: Scalars["String"]
	attachments: MediaAttachments
	author: ExtendedAuthor
	class: Class
	classId: Scalars["String"]
	classSection?: Maybe<ClassSection>
	classSectionId?: Maybe<Scalars["String"]>
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	subTopic?: Maybe<Scalars["String"]>
	subject: Subject
	subjectId: Scalars["String"]
	targets: Array<UserTargets>
	topic: Topic
	topicId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type ExtendedReward = {
	__typename?: "ExtendedReward"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	from: ExtendedAuthor
	rewardItemIds: Array<Scalars["String"]>
	rewardItems: Array<ExtendedRewardItem>
	to: Array<ExtendedAuthor>
	totalPoints: Scalars["Float"]
	updatedAt: Scalars["DateTime"]
}

export type ExtendedRewardItem = {
	__typename?: "ExtendedRewardItem"
	_id: Scalars["String"]
	category: RewardCategory
	categoryId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	points: Scalars["Float"]
	updatedAt: Scalars["DateTime"]
}

export type ExtendedStaff = {
	__typename?: "ExtendedStaff"
	_id: Scalars["String"]
	address?: Maybe<Scalars["String"]>
	bloodGroup?: Maybe<BloodGroups>
	createdAt: Scalars["DateTime"]
	dateOfBirth?: Maybe<Scalars["DateTime"]>
	department: Department
	departmentId: Scalars["String"]
	designation: Scalars["String"]
	email?: Maybe<Scalars["String"]>
	gender?: Maybe<Genders>
	isWhitelisted?: Maybe<Scalars["Boolean"]>
	name: Scalars["String"]
	pastRoles?: Maybe<Array<ExtendedStaffRole>>
	phone?: Maybe<Scalars["String"]>
	picture?: Maybe<EmbeddedPicture>
	roles?: Maybe<Array<ExtendedStaffRole>>
	staffId: Scalars["String"]
	subjectIds?: Maybe<Array<Scalars["String"]>>
	subjects: Array<Subject>
	tag?: Maybe<Tag>
	tagId?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
	username: Scalars["String"]
}

export type ExtendedStaffRole = {
	__typename?: "ExtendedStaffRole"
	assignedAt: Scalars["DateTime"]
	class?: Maybe<Class>
	classId?: Maybe<Scalars["String"]>
	classSection?: Maybe<ClassSection>
	classSectionId?: Maybe<Scalars["String"]>
	removedAt?: Maybe<Scalars["DateTime"]>
	role: StaffRoles
}

export type ExtendedStudent = {
	__typename?: "ExtendedStudent"
	_id: Scalars["String"]
	address?: Maybe<Scalars["String"]>
	admissionDate?: Maybe<Scalars["DateTime"]>
	admissionId: Scalars["String"]
	admissionType?: Maybe<AdmissionTypes>
	bloodGroup?: Maybe<BloodGroups>
	busRoute?: Maybe<BusRoute>
	busRouteId?: Maybe<Scalars["String"]>
	cafeteriaRegistration?: Maybe<CafeteriaRegistrationLog>
	class: Class
	classId: Scalars["String"]
	classSection: ClassSection
	classSectionId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	dateOfBirth?: Maybe<Scalars["DateTime"]>
	email?: Maybe<Scalars["String"]>
	father?: Maybe<Parent>
	gender?: Maybe<Genders>
	guardian1?: Maybe<Parent>
	guardian2?: Maybe<Parent>
	house?: Maybe<House>
	houseId?: Maybe<Scalars["String"]>
	isALS?: Maybe<Scalars["Boolean"]>
	isELS?: Maybe<Scalars["Boolean"]>
	isWhitelisted?: Maybe<Scalars["Boolean"]>
	mother?: Maybe<Parent>
	name: Scalars["String"]
	nationality?: Maybe<Scalars["String"]>
	paytankBalance?: Maybe<Scalars["Float"]>
	phone: Scalars["String"]
	picture?: Maybe<EmbeddedPicture>
	pointsBalance?: Maybe<Scalars["Float"]>
	schoolTimingId?: Maybe<Scalars["String"]>
	status?: Maybe<Scalars["String"]>
	tag?: Maybe<Tag>
	tagId?: Maybe<Scalars["String"]>
	transportMode?: Maybe<Scalars["String"]>
	upcomingCafeteriaRegistration?: Maybe<CafeteriaRegistrationLog>
	updatedAt: Scalars["DateTime"]
	username: Scalars["String"]
}

export type ExtendedStudentAttendance = {
	__typename?: "ExtendedStudentAttendance"
	_id: Scalars["String"]
	attendanceMarkedBy?: Maybe<ExtendedAuthor>
	class: Class
	classId: Scalars["String"]
	classSection: ClassSection
	classSectionId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	inAt: Scalars["DateTime"]
	isAbsent?: Maybe<Scalars["Boolean"]>
	isAttendanceMarkedByClassTeacher?: Maybe<Scalars["Boolean"]>
	isFinal?: Maybe<Scalars["Boolean"]>
	isInManual?: Maybe<Scalars["Boolean"]>
	isInRecorded?: Maybe<Scalars["Boolean"]>
	isLate?: Maybe<Scalars["Boolean"]>
	isOutManual?: Maybe<Scalars["Boolean"]>
	isOutRecorded?: Maybe<Scalars["Boolean"]>
	lateByMinutes?: Maybe<Scalars["Float"]>
	outAt: Scalars["DateTime"]
	rssi: Scalars["Float"]
	shouldNotifyIn: Scalars["Boolean"]
	student: ExtendedStudent
	studentId: Scalars["String"]
	temperature: Scalars["Float"]
	timing: AttendanceTiming
	updatedAt: Scalars["DateTime"]
}

export type ExtendedTrendingSkill = {
	__typename?: "ExtendedTrendingSkill"
	_id: Scalars["String"]
	author: ExtendedAuthor
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	links: Scalars["String"]
	maxAge?: Maybe<Scalars["Float"]>
	minAge?: Maybe<Scalars["Float"]>
	name: Scalars["String"]
	topic: Topic
	topicId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type FeedPostInput = {
	attachments?: InputMaybe<MediaAttachmentsInput>
	text?: InputMaybe<Scalars["String"]>
}

export type FeedPostLike = {
	__typename?: "FeedPostLike"
	author: Author
	createdAt: Scalars["DateTime"]
	updatedAt: Scalars["DateTime"]
}

export type FeedPostWithFieldErrorsResponse = {
	__typename?: "FeedPostWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	feedPost?: Maybe<ExtendedFeedPost>
}

export type FieldError = {
	__typename?: "FieldError"
	error: Scalars["String"]
	field: Scalars["String"]
}

export type FinishPaymentOrderInput = {
	amount: Scalars["Float"]
	orderId: Scalars["String"]
	razorpayOrderId: Scalars["String"]
	razorpayPaymentId: Scalars["String"]
	razorpaySignature: Scalars["String"]
}

export type FinishUploadInput = {
	key: Scalars["String"]
}

export type FinishUploadResponse = {
	__typename?: "FinishUploadResponse"
	type: MediaTypes
	url: Scalars["String"]
}

export type Gallery = {
	__typename?: "Gallery"
	_id: Scalars["String"]
	attachments: MediaAttachments
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	title: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export enum Genders {
	Female = "Female",
	Male = "Male",
	Others = "Others",
}

export type Holiday = {
	__typename?: "Holiday"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	endDate: Scalars["DateTime"]
	eventType?: Maybe<Scalars["String"]>
	name: Scalars["String"]
	startDate: Scalars["DateTime"]
	updatedAt: Scalars["DateTime"]
}

export type House = {
	__typename?: "House"
	_id: Scalars["String"]
	captainId?: Maybe<Scalars["String"]>
	colorHex: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	pointsBalance?: Maybe<Scalars["Float"]>
	updatedAt: Scalars["DateTime"]
}

export type Links = {
	__typename?: "Links"
	custom?: Maybe<Array<Scalars["String"]>>
	youtube?: Maybe<Array<Scalars["String"]>>
}

export type Location = {
	__typename?: "Location"
	address: Scalars["String"]
	city: Scalars["String"]
	pin: Scalars["Float"]
	state: Scalars["String"]
}

export type MediaAttachments = {
	__typename?: "MediaAttachments"
	pdfs?: Maybe<Array<EmbeddedPdf>>
	pictures?: Maybe<Array<EmbeddedPicture>>
	videos?: Maybe<Array<EmbeddedVideo>>
}

export type MediaAttachmentsInput = {
	pdfs?: InputMaybe<Array<PdfInput>>
	pictures?: InputMaybe<Array<PictureInput>>
	videos?: InputMaybe<Array<VideoInput>>
}

export enum MediaTypes {
	Doc = "DOC",
	Image = "Image",
	Pdf = "PDF",
	Video = "Video",
}

export type Mutation = {
	__typename?: "Mutation"
	blockTagForPaytank: Scalars["Boolean"]
	changePassword: Scalars["Boolean"]
	createAddPaytankFundsOrder: ExtendedPaytankOrder
	createCafeteriaRegistrationOrder: CafeteriaRegistrationOrder
	createFeedPost: FeedPostWithFieldErrorsResponse
	deleteFeedPost: Scalars["Boolean"]
	dislikeFeedPost: ExtendedFeedPost
	editProfile: StudentWithFieldErrorsResponse
	finishAddPaytankFundsOrder: ExtendedPaytankOrder
	finishCafeteriaRegistrationOrder: CafeteriaRegistrationOrder
	finishPdfUpload: FinishUploadResponse
	finishPictureUpload: FinishUploadResponse
	finishVideoUpload: FinishUploadResponse
	likeFeedPost: ExtendedFeedPost
	login: AuthResponse
	loginWithAzureSSO: AuthResponse
	logout: Scalars["Boolean"]
	redeemPoints: PointsRedeemTransaction
	setAvatar: EmbeddedPicture
	signPdfUpload: UploadSignatureResponse
	signPictureUpload: UploadSignatureResponse
	signVideoUpload: UploadSignatureResponse
	unblockTagForPaytank: Scalars["Boolean"]
}

export type MutationChangePasswordArgs = {
	currentPassword: Scalars["String"]
	newPassword: Scalars["String"]
	shouldLogOutOnOtherDevices?: InputMaybe<Scalars["Boolean"]>
}

export type MutationCreateAddPaytankFundsOrderArgs = {
	amount: Scalars["Float"]
	description?: InputMaybe<Scalars["String"]>
}

export type MutationCreateCafeteriaRegistrationOrderArgs = {
	quoteId: Scalars["String"]
}

export type MutationCreateFeedPostArgs = {
	input: FeedPostInput
}

export type MutationDeleteFeedPostArgs = {
	feedPostId: Scalars["String"]
}

export type MutationDislikeFeedPostArgs = {
	feedPostId: Scalars["String"]
}

export type MutationEditProfileArgs = {
	input: EditStudentInput
}

export type MutationFinishAddPaytankFundsOrderArgs = {
	input: FinishPaymentOrderInput
}

export type MutationFinishCafeteriaRegistrationOrderArgs = {
	input: FinishPaymentOrderInput
}

export type MutationFinishPdfUploadArgs = {
	input: FinishUploadInput
}

export type MutationFinishPictureUploadArgs = {
	input: FinishUploadInput
}

export type MutationFinishVideoUploadArgs = {
	input: FinishUploadInput
}

export type MutationLikeFeedPostArgs = {
	feedPostId: Scalars["String"]
}

export type MutationLoginArgs = {
	password: Scalars["String"]
	username: Scalars["String"]
}

export type MutationLoginWithAzureSsoArgs = {
	accessToken: Scalars["String"]
	tenantId: Scalars["String"]
}

export type MutationRedeemPointsArgs = {
	pointsRedeemItemId: Scalars["String"]
}

export type MutationSetAvatarArgs = {
	key: Scalars["String"]
	url: Scalars["String"]
}

export type MutationSignPictureUploadArgs = {
	format: Scalars["String"]
}

export type MutationSignVideoUploadArgs = {
	format: Scalars["String"]
}

export type PdfInput = {
	key: Scalars["String"]
	url: Scalars["String"]
}

export type PaginatedCircularsResponse = {
	__typename?: "PaginatedCircularsResponse"
	circulars?: Maybe<Array<Circular>>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedFeedPostsResponse = {
	__typename?: "PaginatedFeedPostsResponse"
	currentPage: Scalars["Float"]
	feedPosts?: Maybe<Array<ExtendedFeedPost>>
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedGalleryResponse = {
	__typename?: "PaginatedGalleryResponse"
	currentPage: Scalars["Float"]
	gallery?: Maybe<Array<Gallery>>
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedHolidaysResponse = {
	__typename?: "PaginatedHolidaysResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	holidays?: Maybe<Array<Holiday>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedHousesResponse = {
	__typename?: "PaginatedHousesResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	houses?: Maybe<Array<ExtendedHouse>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedInput = {
	limit?: InputMaybe<Scalars["Int"]>
	page?: InputMaybe<Scalars["Float"]>
}

export type PaginatedPaytankOrdersResponse = {
	__typename?: "PaginatedPaytankOrdersResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	paytankOrders?: Maybe<Array<ExtendedPaytankOrder>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedPointsRedeemItemsResponse = {
	__typename?: "PaginatedPointsRedeemItemsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	pointsRedeemItems?: Maybe<Array<PointsRedeemItem>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedStaffResponse = {
	__typename?: "PaginatedStaffResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	staffs?: Maybe<Array<ExtendedStaff>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedStudentAttendancesResponse = {
	__typename?: "PaginatedStudentAttendancesResponse"
	attendances?: Maybe<Array<ExtendedStudentAttendance>>
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedStudentsResponse = {
	__typename?: "PaginatedStudentsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	students?: Maybe<Array<ExtendedStudent>>
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
}

export type PaginatedTrendingSkillsResponse = {
	__typename?: "PaginatedTrendingSkillsResponse"
	currentPage: Scalars["Float"]
	hasNextPage: Scalars["Boolean"]
	hasPrevPage: Scalars["Boolean"]
	total: Scalars["Float"]
	totalPages: Scalars["Float"]
	trendingSkills?: Maybe<Array<ExtendedTrendingSkill>>
}

export type Parent = {
	__typename?: "Parent"
	email?: Maybe<Scalars["String"]>
	name?: Maybe<Scalars["String"]>
	nfcTagId?: Maybe<Scalars["String"]>
	phone?: Maybe<Scalars["String"]>
	picture?: Maybe<EmbeddedPicture>
}

export type ParentInput = {
	email?: InputMaybe<Scalars["String"]>
	name?: InputMaybe<Scalars["String"]>
	nfcTagId?: InputMaybe<Scalars["String"]>
	phone?: InputMaybe<Scalars["String"]>
}

export enum PaymentOrderStatus {
	Failed = "Failed",
	Initiated = "Initiated",
	Success = "Success",
}

export enum PaytankFailureReasons {
	BlockedTag = "BlockedTag",
	InsufficientBalance = "InsufficientBalance",
	RazorpayError = "RazorpayError",
	Unknown = "Unknown",
}

export type PaytankMerchant = {
	__typename?: "PaytankMerchant"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description?: Maybe<Scalars["String"]>
	name: Scalars["String"]
	typeId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	username: Scalars["String"]
}

export enum PaytankOrderTypes {
	Credit = "Credit",
	Debit = "Debit",
}

export type PenaltyCategory = {
	__typename?: "PenaltyCategory"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type PictureInput = {
	key: Scalars["String"]
	url: Scalars["String"]
}

export type PictureVariants = {
	__typename?: "PictureVariants"
	large?: Maybe<Scalars["String"]>
	small?: Maybe<Scalars["String"]>
	thumbnail?: Maybe<Scalars["String"]>
}

export type PointsRedeemItem = {
	__typename?: "PointsRedeemItem"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	points: Scalars["Float"]
	updatedAt: Scalars["DateTime"]
}

export type PointsRedeemTransaction = {
	__typename?: "PointsRedeemTransaction"
	_id: Scalars["String"]
	approvedAt?: Maybe<Scalars["DateTime"]>
	arePointsRefunded?: Maybe<Scalars["Boolean"]>
	author: Author
	createdAt: Scalars["DateTime"]
	isApproved?: Maybe<Scalars["Boolean"]>
	isRejected?: Maybe<Scalars["Boolean"]>
	pointsRedeemItemId: Scalars["String"]
	rejectedAt?: Maybe<Scalars["DateTime"]>
	remarks?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
}

export type Query = {
	__typename?: "Query"
	allAttendancesOfMe: PaginatedStudentAttendancesResponse
	allCirculars: PaginatedCircularsResponse
	allFeedPosts: PaginatedFeedPostsResponse
	allGallery: PaginatedGalleryResponse
	allHolidays: PaginatedHolidaysResponse
	allHouses: PaginatedHousesResponse
	allPaytankOrders: PaginatedPaytankOrdersResponse
	allPointsRedeemItems: PaginatedPointsRedeemItemsResponse
	allResources: Array<ExtendedResource>
	allStaffs: PaginatedStaffResponse
	allStudents: PaginatedStudentsResponse
	allSubjects: Array<Subject>
	allTopics: Array<Topic>
	allTrendingSkills: PaginatedTrendingSkillsResponse
	busRouteById: BusRoute
	busRoutes: Array<BusRoute>
	cafeteriaRegistrationQuote: CafeteriaRegistrationQuote
	circularById: Circular
	classById: ExtendedClass
	classes: Array<ExtendedClass>
	currentAndUpcomingSession: SchoolSessionWithStatusResponse
	feedPostById: ExtendedFeedPost
	galleryById: Gallery
	holidayById: Holiday
	houseById: ExtendedHouse
	houses: Array<House>
	housesWithMostPoints: Array<House>
	me?: Maybe<ExtendedStudent>
	myClassTeacher: ExtendedStaff
	myFeedPosts: PaginatedFeedPostsResponse
	myPenalties: Array<ExtendedPenalty>
	myPointsRedeemTransactions: Array<ExtendedPointsRedeemTransaction>
	myRewards: Array<ExtendedReward>
	mySchool?: Maybe<School>
	pointsRedeemItems: Array<PointsRedeemItem>
	resourceById: ExtendedResource
	schoolStatistics: StatisticsResponse
	searchCircular: PaginatedCircularsResponse
	searchGallery: PaginatedGalleryResponse
	searchHoliday: PaginatedHolidaysResponse
	searchHouses: PaginatedHousesResponse
	searchResource: Array<ExtendedResource>
	searchStaffs: PaginatedStaffResponse
	searchStudents: PaginatedStudentsResponse
	searchTrendingSkill: PaginatedTrendingSkillsResponse
	staffById: ExtendedStaff
	studentById: ExtendedStudent
	studentsWithMostPoints: Array<Student>
	todayAttendanceOfMe?: Maybe<ExtendedStudentAttendance>
	todayBusAttendanceOfMe?: Maybe<StudentBusAttendance>
	trendingSkillById: ExtendedTrendingSkill
}

export type QueryAllAttendancesOfMeArgs = {
	pagination: PaginatedInput
}

export type QueryAllCircularsArgs = {
	pagination: PaginatedInput
}

export type QueryAllFeedPostsArgs = {
	pagination: PaginatedInput
}

export type QueryAllGalleryArgs = {
	pagination: PaginatedInput
}

export type QueryAllHolidaysArgs = {
	pagination: PaginatedInput
}

export type QueryAllHousesArgs = {
	pagination: PaginatedInput
}

export type QueryAllPaytankOrdersArgs = {
	pagination: PaginatedInput
}

export type QueryAllPointsRedeemItemsArgs = {
	pagination: PaginatedInput
}

export type QueryAllResourcesArgs = {
	classId?: InputMaybe<Scalars["String"]>
	classSectionId?: InputMaybe<Scalars["String"]>
	subTopic?: InputMaybe<Scalars["String"]>
	subjectId?: InputMaybe<Scalars["String"]>
	topicId?: InputMaybe<Scalars["String"]>
}

export type QueryAllStaffsArgs = {
	pagination: PaginatedInput
}

export type QueryAllStudentsArgs = {
	classId?: InputMaybe<Scalars["String"]>
	classSectionId?: InputMaybe<Scalars["String"]>
	pagination: PaginatedInput
}

export type QueryAllTrendingSkillsArgs = {
	forYou: Scalars["Boolean"]
	pagination: PaginatedInput
	topicId?: InputMaybe<Scalars["String"]>
}

export type QueryBusRouteByIdArgs = {
	busRouteId: Scalars["String"]
}

export type QueryCafeteriaRegistrationQuoteArgs = {
	sessionIds: Array<Scalars["String"]>
}

export type QueryCircularByIdArgs = {
	circularId: Scalars["String"]
}

export type QueryClassByIdArgs = {
	classId: Scalars["String"]
}

export type QueryFeedPostByIdArgs = {
	feedPostId: Scalars["String"]
}

export type QueryGalleryByIdArgs = {
	galleryId: Scalars["String"]
}

export type QueryHolidayByIdArgs = {
	holidayId: Scalars["String"]
}

export type QueryHouseByIdArgs = {
	houseId: Scalars["String"]
}

export type QueryMyFeedPostsArgs = {
	pagination: PaginatedInput
}

export type QueryResourceByIdArgs = {
	resourceId: Scalars["String"]
}

export type QuerySearchCircularArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchGalleryArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchHolidayArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchHousesArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchResourceArgs = {
	classId?: InputMaybe<Scalars["String"]>
	classSectionId?: InputMaybe<Scalars["String"]>
	keyword: Scalars["String"]
	subTopic?: InputMaybe<Scalars["String"]>
	subjectId?: InputMaybe<Scalars["String"]>
	topicId?: InputMaybe<Scalars["String"]>
}

export type QuerySearchStaffsArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchStudentsArgs = {
	keyword: Scalars["String"]
	pagination: PaginatedInput
}

export type QuerySearchTrendingSkillArgs = {
	forYou: Scalars["Boolean"]
	keyword: Scalars["String"]
	pagination: PaginatedInput
	topicId?: InputMaybe<Scalars["String"]>
}

export type QueryStaffByIdArgs = {
	staffId: Scalars["String"]
}

export type QueryStudentByIdArgs = {
	studentId: Scalars["String"]
}

export type QueryTrendingSkillByIdArgs = {
	trendingSkillId: Scalars["String"]
}

export type RewardCategory = {
	__typename?: "RewardCategory"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	description: Scalars["String"]
	name: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type School = {
	__typename?: "School"
	_id: Scalars["String"]
	affiliationCode: Scalars["String"]
	cover?: Maybe<EmbeddedPicture>
	createdAt: Scalars["DateTime"]
	email: Scalars["String"]
	location: Location
	name: Scalars["String"]
	phone: Scalars["String"]
	picture?: Maybe<EmbeddedPicture>
	schoolCode: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type SchoolSession = {
	__typename?: "SchoolSession"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	endDate: Scalars["Int"]
	endMonth: Scalars["Int"]
	isDeleted?: Maybe<Scalars["Boolean"]>
	name: Scalars["String"]
	startDate: Scalars["Int"]
	startMonth: Scalars["Int"]
	updatedAt: Scalars["DateTime"]
}

export type SchoolSessionWithStatusResponse = {
	__typename?: "SchoolSessionWithStatusResponse"
	currentSession?: Maybe<SchoolSession>
	upcomingSession?: Maybe<SchoolSession>
}

export type SchoolTiming = {
	__typename?: "SchoolTiming"
	_id: Scalars["String"]
	attendanceTiming: AttendanceTiming
	createdAt: Scalars["DateTime"]
	isDeleted?: Maybe<Scalars["Boolean"]>
	name: Scalars["String"]
	updatedAt: Scalars["DateTime"]
	workingWeekdays?: Maybe<Array<Scalars["String"]>>
}

export type Staff = {
	__typename?: "Staff"
	_id: Scalars["String"]
	address?: Maybe<Scalars["String"]>
	bloodGroup?: Maybe<BloodGroups>
	createdAt: Scalars["DateTime"]
	dateOfBirth?: Maybe<Scalars["DateTime"]>
	departmentId: Scalars["String"]
	designation: Scalars["String"]
	email?: Maybe<Scalars["String"]>
	gender?: Maybe<Genders>
	isWhitelisted?: Maybe<Scalars["Boolean"]>
	name: Scalars["String"]
	pastRoles?: Maybe<Array<StaffRole>>
	phone?: Maybe<Scalars["String"]>
	picture?: Maybe<EmbeddedPicture>
	roles?: Maybe<Array<StaffRole>>
	staffId: Scalars["String"]
	subjectIds?: Maybe<Array<Scalars["String"]>>
	tagId?: Maybe<Scalars["String"]>
	updatedAt: Scalars["DateTime"]
	username: Scalars["String"]
}

export type StaffAttendanceTiming = {
	__typename?: "StaffAttendanceTiming"
	inAt: Scalars["String"]
	inStartBeforeMinutes: Scalars["Float"]
	outAt: Scalars["String"]
	outStartBeforeMinutes: Scalars["Float"]
}

export type StaffRole = {
	__typename?: "StaffRole"
	assignedAt: Scalars["DateTime"]
	classId?: Maybe<Scalars["String"]>
	classSectionId?: Maybe<Scalars["String"]>
	removedAt?: Maybe<Scalars["DateTime"]>
	role: StaffRoles
}

export enum StaffRoles {
	ClassTeacher = "ClassTeacher",
}

export type StatisticsResponse = {
	__typename?: "StatisticsResponse"
	classes?: Maybe<Scalars["Float"]>
	nonTeachingStaff?: Maybe<Scalars["Float"]>
	students?: Maybe<Scalars["Float"]>
	subjects?: Maybe<Scalars["Float"]>
	teachingStaff?: Maybe<Scalars["Float"]>
}

export type Student = {
	__typename?: "Student"
	_id: Scalars["String"]
	address?: Maybe<Scalars["String"]>
	admissionDate?: Maybe<Scalars["DateTime"]>
	admissionId: Scalars["String"]
	admissionType?: Maybe<AdmissionTypes>
	bloodGroup?: Maybe<BloodGroups>
	busRouteId?: Maybe<Scalars["String"]>
	cafeteriaRegistration?: Maybe<CafeteriaRegistrationLog>
	classId: Scalars["String"]
	classSectionId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	dateOfBirth?: Maybe<Scalars["DateTime"]>
	email?: Maybe<Scalars["String"]>
	father?: Maybe<Parent>
	gender?: Maybe<Genders>
	guardian1?: Maybe<Parent>
	guardian2?: Maybe<Parent>
	houseId?: Maybe<Scalars["String"]>
	isALS?: Maybe<Scalars["Boolean"]>
	isELS?: Maybe<Scalars["Boolean"]>
	isWhitelisted?: Maybe<Scalars["Boolean"]>
	mother?: Maybe<Parent>
	name: Scalars["String"]
	nationality?: Maybe<Scalars["String"]>
	paytankBalance?: Maybe<Scalars["Float"]>
	phone: Scalars["String"]
	picture?: Maybe<EmbeddedPicture>
	pointsBalance?: Maybe<Scalars["Float"]>
	schoolTimingId?: Maybe<Scalars["String"]>
	status?: Maybe<Scalars["String"]>
	tagId?: Maybe<Scalars["String"]>
	transportMode?: Maybe<Scalars["String"]>
	upcomingCafeteriaRegistration?: Maybe<CafeteriaRegistrationLog>
	updatedAt: Scalars["DateTime"]
	username: Scalars["String"]
}

export type StudentAttendance = {
	__typename?: "StudentAttendance"
	_id: Scalars["String"]
	attendanceMarkedBy?: Maybe<Author>
	classId: Scalars["String"]
	classSectionId: Scalars["String"]
	createdAt: Scalars["DateTime"]
	inAt: Scalars["DateTime"]
	isAttendanceMarkedByClassTeacher?: Maybe<Scalars["Boolean"]>
	isFinal?: Maybe<Scalars["Boolean"]>
	isInManual?: Maybe<Scalars["Boolean"]>
	isInRecorded?: Maybe<Scalars["Boolean"]>
	isLate?: Maybe<Scalars["Boolean"]>
	isOutManual?: Maybe<Scalars["Boolean"]>
	isOutRecorded?: Maybe<Scalars["Boolean"]>
	lateByMinutes?: Maybe<Scalars["Float"]>
	outAt: Scalars["DateTime"]
	rssi: Scalars["Float"]
	shouldNotifyIn: Scalars["Boolean"]
	studentId: Scalars["String"]
	temperature: Scalars["Float"]
	timing: AttendanceTiming
	updatedAt: Scalars["DateTime"]
}

export type StudentBusAttendance = {
	__typename?: "StudentBusAttendance"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	homeDropAt?: Maybe<Scalars["DateTime"]>
	homePickupAt?: Maybe<Scalars["DateTime"]>
	schoolDropAt?: Maybe<Scalars["DateTime"]>
	schoolPickupAt?: Maybe<Scalars["DateTime"]>
	studentId: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type StudentWithFieldErrorsResponse = {
	__typename?: "StudentWithFieldErrorsResponse"
	errors?: Maybe<Array<FieldError>>
	student?: Maybe<ExtendedStudent>
}

export type Subject = {
	__typename?: "Subject"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	updatedAt: Scalars["DateTime"]
}

export type Subscription = {
	__typename?: "Subscription"
	meUpdate?: Maybe<ExtendedStudent>
	myPaytankOrder?: Maybe<ExtendedPaytankOrder>
	todayAttendanceUpdateOfMe: StudentAttendance
	todayBusAttendanceUpdateOfMe: StudentBusAttendance
}

export type SubscriptionMeUpdateArgs = {
	studentId: Scalars["String"]
}

export type SubscriptionMyPaytankOrderArgs = {
	studentId: Scalars["String"]
}

export type SubscriptionTodayAttendanceUpdateOfMeArgs = {
	studentId: Scalars["String"]
}

export type SubscriptionTodayBusAttendanceUpdateOfMeArgs = {
	studentId: Scalars["String"]
}

export type Tag = {
	__typename?: "Tag"
	_id: Scalars["String"]
	author: Author
	createdAt: Scalars["DateTime"]
	expiryAt: Scalars["DateTime"]
	isBlockedForPaytank?: Maybe<Scalars["Boolean"]>
	tagDecimalId: Scalars["String"]
	tagHexId: Scalars["String"]
	type: TagTypes
	updatedAt: Scalars["DateTime"]
}

export enum TagTypes {
	Band = "Band",
	Card = "Card",
}

export type Topic = {
	__typename?: "Topic"
	_id: Scalars["String"]
	createdAt: Scalars["DateTime"]
	name: Scalars["String"]
	subTopics?: Maybe<Array<Scalars["String"]>>
	updatedAt: Scalars["DateTime"]
}

export type UploadSignatureResponse = {
	__typename?: "UploadSignatureResponse"
	key: Scalars["String"]
	signedUrl: Scalars["String"]
}

export enum UserTargets {
	Staffs = "Staffs",
	Students = "Students",
}

export type VideoInput = {
	key: Scalars["String"]
	url: Scalars["String"]
}

export type VideoVariants = {
	__typename?: "VideoVariants"
	large?: Maybe<Scalars["String"]>
	small?: Maybe<Scalars["String"]>
}

export type AmountBreakupFragment = { __typename?: "AmountBreakup"; label: string; amount: number; payable?: number | null }

export type AttendanceTimingFragment = {
	__typename?: "AttendanceTiming"
	inAt: string
	inStartBeforeMinutes: number
	notifyTeacherAfterMinutes: number
	outAt: string
	outStartBeforeMinutes: number
}

export type AuthorFragment = { __typename?: "Author"; authorType: AuthorTypes; studentId?: string | null; staffId?: string | null; schoolId?: string | null }

export type BusRouteFragment = {
	__typename?: "BusRoute"
	_id: string
	routeName: string
	vehicleNo?: string | null
	busDriverName: string
	busDriverPhone?: string | null
	busConductorName: string
	busConductorPhone?: string | null
	createdAt: any
	updatedAt: any
}

export type CafeteriaRegistrationLogFragment = {
	__typename?: "CafeteriaRegistrationLog"
	_id: string
	studentId: string
	schoolSessionId: string
	since: any
	until: any
	createdAt: any
	updatedAt: any
}

export type CafeteriaRegistrationOrderFragment = {
	__typename?: "CafeteriaRegistrationOrder"
	_id: string
	studentId: string
	amount: number
	status: PaymentOrderStatus
	failureReason?: string | null
	quoteId: string
	orderId?: string | null
	paymentId?: string | null
	createdAt: any
	updatedAt: any
}

export type CafeteriaRegistrationQuoteFragment = {
	__typename?: "CafeteriaRegistrationQuote"
	_id: string
	studentId: string
	totalAmount: number
	createdAt: any
	updatedAt: any
	sessionWiseQuotes: Array<{
		__typename?: "CafeteriaRegistrationSessionQuote"
		schoolSessionId: string
		schoolSessionStartAt: any
		schoolSessionEndAt: any
		totalAmount: number
		breakup: Array<{ __typename?: "AmountBreakup"; label: string; amount: number; payable?: number | null }>
	}>
}

export type CafeteriaRegistrationSessionQuoteFragment = {
	__typename?: "CafeteriaRegistrationSessionQuote"
	schoolSessionId: string
	schoolSessionStartAt: any
	schoolSessionEndAt: any
	totalAmount: number
	breakup: Array<{ __typename?: "AmountBreakup"; label: string; amount: number; payable?: number | null }>
}

export type CircularFragment = {
	__typename?: "Circular"
	_id: string
	title: string
	message: string
	targets: Array<UserTargets>
	createdAt: any
	updatedAt: any
}

export type ClassFragment = { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }

export type ClassSectionFragment = {
	__typename?: "ClassSection"
	_id: string
	classId: string
	name: string
	classTeacherId?: string | null
	createdAt: any
	updatedAt: any
}

export type DepartmentFragment = {
	__typename?: "Department"
	_id: string
	name: string
	type: DepartmentTypes
	createdAt: any
	updatedAt: any
	attendanceTiming: { __typename?: "StaffAttendanceTiming"; inAt: string; inStartBeforeMinutes: number; outAt: string; outStartBeforeMinutes: number }
}

export type EmbeddedPdfFragment = { __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }

export type EmbeddedPictureFragment = {
	__typename?: "EmbeddedPicture"
	key: string
	url: string
	createdAt: any
	updatedAt: any
	variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
}

export type EmbeddedVideoFragment = {
	__typename?: "EmbeddedVideo"
	key: string
	url: string
	thumbnail?: string | null
	createdAt: any
	updatedAt: any
	variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
}

export type ExtendedAuthorFragment = {
	__typename?: "ExtendedAuthor"
	authorType: AuthorTypes
	studentId?: string | null
	staffId?: string | null
	schoolId?: string | null
	student?: {
		__typename?: "Student"
		_id: string
		name: string
		username: string
		email?: string | null
		phone: string
		admissionId: string
		admissionDate?: any | null
		admissionType?: AdmissionTypes | null
		classId: string
		classSectionId: string
		tagId?: string | null
		houseId?: string | null
		isALS?: boolean | null
		isELS?: boolean | null
		status?: string | null
		nationality?: string | null
		gender?: Genders | null
		dateOfBirth?: any | null
		address?: string | null
		bloodGroup?: BloodGroups | null
		isWhitelisted?: boolean | null
		transportMode?: string | null
		busRouteId?: string | null
		pointsBalance?: number | null
		paytankBalance?: number | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
		father?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		mother?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		guardian1?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		guardian2?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
	} | null
	staff?: {
		__typename?: "Staff"
		_id: string
		name: string
		email?: string | null
		phone?: string | null
		staffId: string
		designation: string
		username: string
		departmentId: string
		tagId?: string | null
		gender?: Genders | null
		dateOfBirth?: any | null
		address?: string | null
		bloodGroup?: BloodGroups | null
		subjectIds?: Array<string> | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
	} | null
	school?: {
		__typename?: "School"
		_id: string
		name: string
		schoolCode: string
		affiliationCode: string
		email: string
		phone: string
		createdAt: any
		updatedAt: any
		location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
		cover?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
	} | null
}

export type ExtendedClassFragment = {
	__typename?: "ExtendedClass"
	_id: string
	name: string
	createdAt: any
	updatedAt: any
	sections: Array<{
		__typename?: "ExtendedClassSection"
		_id: string
		classId: string
		name: string
		classTeacherId?: string | null
		createdAt: any
		updatedAt: any
		classTeacher?: {
			__typename?: "Staff"
			_id: string
			name: string
			email?: string | null
			phone?: string | null
			staffId: string
			designation: string
			username: string
			departmentId: string
			tagId?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			subjectIds?: Array<string> | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
	}>
}

export type ExtendedClassSectionFragment = {
	__typename?: "ExtendedClassSection"
	_id: string
	classId: string
	name: string
	classTeacherId?: string | null
	createdAt: any
	updatedAt: any
	classTeacher?: {
		__typename?: "Staff"
		_id: string
		name: string
		email?: string | null
		phone?: string | null
		staffId: string
		designation: string
		username: string
		departmentId: string
		tagId?: string | null
		gender?: Genders | null
		dateOfBirth?: any | null
		address?: string | null
		bloodGroup?: BloodGroups | null
		subjectIds?: Array<string> | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
	} | null
}

export type ExtendedFeedPostFragment = {
	__typename?: "ExtendedFeedPost"
	_id: string
	text?: string | null
	isApproved?: boolean | null
	isLiked?: boolean | null
	createdAt: any
	updatedAt: any
	author: {
		__typename?: "ExtendedAuthor"
		authorType: AuthorTypes
		studentId?: string | null
		staffId?: string | null
		schoolId?: string | null
		student?: {
			__typename?: "Student"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		} | null
		staff?: {
			__typename?: "Staff"
			_id: string
			name: string
			email?: string | null
			phone?: string | null
			staffId: string
			designation: string
			username: string
			departmentId: string
			tagId?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			subjectIds?: Array<string> | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		school?: {
			__typename?: "School"
			_id: string
			name: string
			schoolCode: string
			affiliationCode: string
			email: string
			phone: string
			createdAt: any
			updatedAt: any
			location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			cover?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
	}
	links?: { __typename?: "Links"; youtube?: Array<string> | null; custom?: Array<string> | null } | null
	attachments?: {
		__typename?: "MediaAttachments"
		pictures?: Array<{
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		}> | null
		videos?: Array<{
			__typename?: "EmbeddedVideo"
			key: string
			url: string
			thumbnail?: string | null
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
		}> | null
		pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
	} | null
	likes?: Array<{
		__typename?: "FeedPostLike"
		createdAt: any
		updatedAt: any
		author: { __typename?: "Author"; authorType: AuthorTypes; studentId?: string | null; staffId?: string | null; schoolId?: string | null }
	}> | null
}

export type ExtendedPaytankOrderFragment = {
	__typename?: "ExtendedPaytankOrder"
	_id: string
	type: PaytankOrderTypes
	merchantId?: string | null
	studentId: string
	amount: number
	description?: string | null
	status: PaymentOrderStatus
	failureReason?: PaytankFailureReasons | null
	orderId?: string | null
	paymentId?: string | null
	refundId?: string | null
	refundAmount?: number | null
	refundCreatedAt?: any | null
	refundProcessedAt?: any | null
	refundFailedAt?: any | null
	createdAt: any
	updatedAt: any
	merchant?: {
		__typename?: "PaytankMerchant"
		_id: string
		name: string
		description?: string | null
		username: string
		typeId: string
		createdAt: any
		updatedAt: any
	} | null
	student: {
		__typename?: "Student"
		_id: string
		name: string
		username: string
		email?: string | null
		phone: string
		admissionId: string
		admissionDate?: any | null
		admissionType?: AdmissionTypes | null
		classId: string
		classSectionId: string
		tagId?: string | null
		houseId?: string | null
		isALS?: boolean | null
		isELS?: boolean | null
		status?: string | null
		nationality?: string | null
		gender?: Genders | null
		dateOfBirth?: any | null
		address?: string | null
		bloodGroup?: BloodGroups | null
		isWhitelisted?: boolean | null
		transportMode?: string | null
		busRouteId?: string | null
		pointsBalance?: number | null
		paytankBalance?: number | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
		father?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		mother?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		guardian1?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		guardian2?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
	}
}

export type ExtendedPenaltyFragment = {
	__typename?: "ExtendedPenalty"
	_id: string
	penaltyItemIds: Array<string>
	totalPoints: number
	createdAt: any
	updatedAt: any
	from: {
		__typename?: "ExtendedAuthor"
		authorType: AuthorTypes
		studentId?: string | null
		staffId?: string | null
		schoolId?: string | null
		student?: {
			__typename?: "Student"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		} | null
		staff?: {
			__typename?: "Staff"
			_id: string
			name: string
			email?: string | null
			phone?: string | null
			staffId: string
			designation: string
			username: string
			departmentId: string
			tagId?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			subjectIds?: Array<string> | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		school?: {
			__typename?: "School"
			_id: string
			name: string
			schoolCode: string
			affiliationCode: string
			email: string
			phone: string
			createdAt: any
			updatedAt: any
			location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			cover?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
	}
	to: Array<{
		__typename?: "ExtendedAuthor"
		authorType: AuthorTypes
		studentId?: string | null
		staffId?: string | null
		schoolId?: string | null
		student?: {
			__typename?: "Student"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		} | null
		staff?: {
			__typename?: "Staff"
			_id: string
			name: string
			email?: string | null
			phone?: string | null
			staffId: string
			designation: string
			username: string
			departmentId: string
			tagId?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			subjectIds?: Array<string> | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		school?: {
			__typename?: "School"
			_id: string
			name: string
			schoolCode: string
			affiliationCode: string
			email: string
			phone: string
			createdAt: any
			updatedAt: any
			location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			cover?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
	}>
	penaltyItems: Array<{
		__typename?: "ExtendedPenaltyItem"
		_id: string
		name: string
		description: string
		points: number
		categoryId: string
		createdAt: any
		updatedAt: any
		category: { __typename?: "PenaltyCategory"; _id: string; name: string; description: string; createdAt: any; updatedAt: any }
	}>
}

export type ExtendedPenaltyItemFragment = {
	__typename?: "ExtendedPenaltyItem"
	_id: string
	name: string
	description: string
	points: number
	categoryId: string
	createdAt: any
	updatedAt: any
	category: { __typename?: "PenaltyCategory"; _id: string; name: string; description: string; createdAt: any; updatedAt: any }
}

export type ExtendedPointsRedeemTransactionFragment = {
	__typename?: "ExtendedPointsRedeemTransaction"
	_id: string
	pointsRedeemItemId: string
	isApproved?: boolean | null
	approvedAt?: any | null
	isRejected?: boolean | null
	rejectedAt?: any | null
	remarks?: string | null
	createdAt: any
	updatedAt: any
	author: {
		__typename?: "ExtendedAuthor"
		authorType: AuthorTypes
		studentId?: string | null
		staffId?: string | null
		schoolId?: string | null
		student?: {
			__typename?: "Student"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		} | null
		staff?: {
			__typename?: "Staff"
			_id: string
			name: string
			email?: string | null
			phone?: string | null
			staffId: string
			designation: string
			username: string
			departmentId: string
			tagId?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			subjectIds?: Array<string> | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		school?: {
			__typename?: "School"
			_id: string
			name: string
			schoolCode: string
			affiliationCode: string
			email: string
			phone: string
			createdAt: any
			updatedAt: any
			location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			cover?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
	}
	pointsRedeemItem: { __typename?: "PointsRedeemItem"; _id: string; name: string; description: string; points: number; createdAt: any; updatedAt: any }
}

export type ExtendedResourceFragment = {
	__typename?: "ExtendedResource"
	_id: string
	targets: Array<UserTargets>
	name: string
	description: string
	classId: string
	classSectionId?: string | null
	subjectId: string
	topicId: string
	subTopic?: string | null
	createdAt: any
	updatedAt: any
	author: {
		__typename?: "ExtendedAuthor"
		authorType: AuthorTypes
		studentId?: string | null
		staffId?: string | null
		schoolId?: string | null
		student?: {
			__typename?: "Student"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		} | null
		staff?: {
			__typename?: "Staff"
			_id: string
			name: string
			email?: string | null
			phone?: string | null
			staffId: string
			designation: string
			username: string
			departmentId: string
			tagId?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			subjectIds?: Array<string> | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		school?: {
			__typename?: "School"
			_id: string
			name: string
			schoolCode: string
			affiliationCode: string
			email: string
			phone: string
			createdAt: any
			updatedAt: any
			location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			cover?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
	}
	class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
	classSection?: {
		__typename?: "ClassSection"
		_id: string
		classId: string
		name: string
		classTeacherId?: string | null
		createdAt: any
		updatedAt: any
	} | null
	subject: { __typename?: "Subject"; _id: string; name: string; createdAt: any; updatedAt: any }
	topic: { __typename?: "Topic"; _id: string; name: string; subTopics?: Array<string> | null; createdAt: any; updatedAt: any }
	attachments: {
		__typename?: "MediaAttachments"
		pictures?: Array<{
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		}> | null
		videos?: Array<{
			__typename?: "EmbeddedVideo"
			key: string
			url: string
			thumbnail?: string | null
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
		}> | null
		pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
	}
}

export type ExtendedRewardFragment = {
	__typename?: "ExtendedReward"
	_id: string
	rewardItemIds: Array<string>
	totalPoints: number
	createdAt: any
	updatedAt: any
	from: {
		__typename?: "ExtendedAuthor"
		authorType: AuthorTypes
		studentId?: string | null
		staffId?: string | null
		schoolId?: string | null
		student?: {
			__typename?: "Student"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		} | null
		staff?: {
			__typename?: "Staff"
			_id: string
			name: string
			email?: string | null
			phone?: string | null
			staffId: string
			designation: string
			username: string
			departmentId: string
			tagId?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			subjectIds?: Array<string> | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		school?: {
			__typename?: "School"
			_id: string
			name: string
			schoolCode: string
			affiliationCode: string
			email: string
			phone: string
			createdAt: any
			updatedAt: any
			location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			cover?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
	}
	to: Array<{
		__typename?: "ExtendedAuthor"
		authorType: AuthorTypes
		studentId?: string | null
		staffId?: string | null
		schoolId?: string | null
		student?: {
			__typename?: "Student"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		} | null
		staff?: {
			__typename?: "Staff"
			_id: string
			name: string
			email?: string | null
			phone?: string | null
			staffId: string
			designation: string
			username: string
			departmentId: string
			tagId?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			subjectIds?: Array<string> | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		school?: {
			__typename?: "School"
			_id: string
			name: string
			schoolCode: string
			affiliationCode: string
			email: string
			phone: string
			createdAt: any
			updatedAt: any
			location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			cover?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
	}>
	rewardItems: Array<{
		__typename?: "ExtendedRewardItem"
		_id: string
		name: string
		description: string
		points: number
		categoryId: string
		createdAt: any
		updatedAt: any
		category: { __typename?: "RewardCategory"; _id: string; name: string; description: string; createdAt: any; updatedAt: any }
	}>
}

export type ExtendedRewardItemFragment = {
	__typename?: "ExtendedRewardItem"
	_id: string
	name: string
	description: string
	points: number
	categoryId: string
	createdAt: any
	updatedAt: any
	category: { __typename?: "RewardCategory"; _id: string; name: string; description: string; createdAt: any; updatedAt: any }
}

export type ExtendedStaffFragment = {
	__typename?: "ExtendedStaff"
	_id: string
	name: string
	email?: string | null
	phone?: string | null
	username: string
	staffId: string
	designation: string
	departmentId: string
	tagId?: string | null
	gender?: Genders | null
	dateOfBirth?: any | null
	address?: string | null
	bloodGroup?: BloodGroups | null
	subjectIds?: Array<string> | null
	createdAt: any
	updatedAt: any
	picture?: {
		__typename?: "EmbeddedPicture"
		key: string
		url: string
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
	} | null
	department: {
		__typename?: "Department"
		_id: string
		name: string
		type: DepartmentTypes
		createdAt: any
		updatedAt: any
		attendanceTiming: { __typename?: "StaffAttendanceTiming"; inAt: string; inStartBeforeMinutes: number; outAt: string; outStartBeforeMinutes: number }
	}
	tag?: {
		__typename?: "Tag"
		_id: string
		tagHexId: string
		tagDecimalId: string
		expiryAt: any
		isBlockedForPaytank?: boolean | null
		createdAt: any
		updatedAt: any
	} | null
	subjects: Array<{ __typename?: "Subject"; _id: string; name: string; createdAt: any; updatedAt: any }>
	roles?: Array<{
		__typename?: "ExtendedStaffRole"
		role: StaffRoles
		classId?: string | null
		classSectionId?: string | null
		assignedAt: any
		removedAt?: any | null
		class?: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any } | null
		classSection?: {
			__typename?: "ClassSection"
			_id: string
			classId: string
			name: string
			classTeacherId?: string | null
			createdAt: any
			updatedAt: any
		} | null
	}> | null
	pastRoles?: Array<{
		__typename?: "ExtendedStaffRole"
		role: StaffRoles
		classId?: string | null
		classSectionId?: string | null
		assignedAt: any
		removedAt?: any | null
		class?: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any } | null
		classSection?: {
			__typename?: "ClassSection"
			_id: string
			classId: string
			name: string
			classTeacherId?: string | null
			createdAt: any
			updatedAt: any
		} | null
	}> | null
}

export type ExtendedStaffRoleFragment = {
	__typename?: "ExtendedStaffRole"
	role: StaffRoles
	classId?: string | null
	classSectionId?: string | null
	assignedAt: any
	removedAt?: any | null
	class?: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any } | null
	classSection?: {
		__typename?: "ClassSection"
		_id: string
		classId: string
		name: string
		classTeacherId?: string | null
		createdAt: any
		updatedAt: any
	} | null
}

export type ExtendedStudentFragment = {
	__typename?: "ExtendedStudent"
	_id: string
	name: string
	username: string
	email?: string | null
	phone: string
	admissionId: string
	admissionDate?: any | null
	admissionType?: AdmissionTypes | null
	classId: string
	classSectionId: string
	tagId?: string | null
	houseId?: string | null
	isALS?: boolean | null
	isELS?: boolean | null
	status?: string | null
	nationality?: string | null
	gender?: Genders | null
	dateOfBirth?: any | null
	address?: string | null
	bloodGroup?: BloodGroups | null
	isWhitelisted?: boolean | null
	transportMode?: string | null
	busRouteId?: string | null
	pointsBalance?: number | null
	paytankBalance?: number | null
	createdAt: any
	updatedAt: any
	picture?: {
		__typename?: "EmbeddedPicture"
		key: string
		url: string
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
	} | null
	class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
	classSection: { __typename?: "ClassSection"; _id: string; classId: string; name: string; classTeacherId?: string | null; createdAt: any; updatedAt: any }
	tag?: {
		__typename?: "Tag"
		_id: string
		tagHexId: string
		tagDecimalId: string
		expiryAt: any
		isBlockedForPaytank?: boolean | null
		createdAt: any
		updatedAt: any
	} | null
	house?: {
		__typename?: "House"
		_id: string
		name: string
		colorHex: string
		captainId?: string | null
		pointsBalance?: number | null
		createdAt: any
		updatedAt: any
	} | null
	father?: {
		__typename?: "Parent"
		name?: string | null
		phone?: string | null
		email?: string | null
		nfcTagId?: string | null
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
	} | null
	mother?: {
		__typename?: "Parent"
		name?: string | null
		phone?: string | null
		email?: string | null
		nfcTagId?: string | null
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
	} | null
	guardian1?: {
		__typename?: "Parent"
		name?: string | null
		phone?: string | null
		email?: string | null
		nfcTagId?: string | null
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
	} | null
	guardian2?: {
		__typename?: "Parent"
		name?: string | null
		phone?: string | null
		email?: string | null
		nfcTagId?: string | null
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
	} | null
	busRoute?: {
		__typename?: "BusRoute"
		_id: string
		routeName: string
		vehicleNo?: string | null
		busDriverName: string
		busDriverPhone?: string | null
		busConductorName: string
		busConductorPhone?: string | null
		createdAt: any
		updatedAt: any
	} | null
	cafeteriaRegistration?: {
		__typename?: "CafeteriaRegistrationLog"
		_id: string
		studentId: string
		schoolSessionId: string
		since: any
		until: any
		createdAt: any
		updatedAt: any
	} | null
	upcomingCafeteriaRegistration?: {
		__typename?: "CafeteriaRegistrationLog"
		_id: string
		studentId: string
		schoolSessionId: string
		since: any
		until: any
		createdAt: any
		updatedAt: any
	} | null
}

export type ExtendedStudentAttendanceFragment = {
	__typename?: "ExtendedStudentAttendance"
	_id: string
	studentId: string
	classId: string
	classSectionId: string
	inAt: any
	outAt: any
	rssi: number
	temperature: number
	isFinal?: boolean | null
	isInRecorded?: boolean | null
	isOutRecorded?: boolean | null
	isInManual?: boolean | null
	isOutManual?: boolean | null
	isLate?: boolean | null
	isAbsent?: boolean | null
	lateByMinutes?: number | null
	isAttendanceMarkedByClassTeacher?: boolean | null
	createdAt: any
	updatedAt: any
	student: {
		__typename?: "ExtendedStudent"
		_id: string
		name: string
		username: string
		email?: string | null
		phone: string
		admissionId: string
		admissionDate?: any | null
		admissionType?: AdmissionTypes | null
		classId: string
		classSectionId: string
		tagId?: string | null
		houseId?: string | null
		isALS?: boolean | null
		isELS?: boolean | null
		status?: string | null
		nationality?: string | null
		gender?: Genders | null
		dateOfBirth?: any | null
		address?: string | null
		bloodGroup?: BloodGroups | null
		isWhitelisted?: boolean | null
		transportMode?: string | null
		busRouteId?: string | null
		pointsBalance?: number | null
		paytankBalance?: number | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
		class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
		classSection: {
			__typename?: "ClassSection"
			_id: string
			classId: string
			name: string
			classTeacherId?: string | null
			createdAt: any
			updatedAt: any
		}
		tag?: {
			__typename?: "Tag"
			_id: string
			tagHexId: string
			tagDecimalId: string
			expiryAt: any
			isBlockedForPaytank?: boolean | null
			createdAt: any
			updatedAt: any
		} | null
		house?: {
			__typename?: "House"
			_id: string
			name: string
			colorHex: string
			captainId?: string | null
			pointsBalance?: number | null
			createdAt: any
			updatedAt: any
		} | null
		father?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		mother?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		guardian1?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		guardian2?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		busRoute?: {
			__typename?: "BusRoute"
			_id: string
			routeName: string
			vehicleNo?: string | null
			busDriverName: string
			busDriverPhone?: string | null
			busConductorName: string
			busConductorPhone?: string | null
			createdAt: any
			updatedAt: any
		} | null
		cafeteriaRegistration?: {
			__typename?: "CafeteriaRegistrationLog"
			_id: string
			studentId: string
			schoolSessionId: string
			since: any
			until: any
			createdAt: any
			updatedAt: any
		} | null
		upcomingCafeteriaRegistration?: {
			__typename?: "CafeteriaRegistrationLog"
			_id: string
			studentId: string
			schoolSessionId: string
			since: any
			until: any
			createdAt: any
			updatedAt: any
		} | null
	}
	class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
	classSection: { __typename?: "ClassSection"; _id: string; classId: string; name: string; classTeacherId?: string | null; createdAt: any; updatedAt: any }
	timing: {
		__typename?: "AttendanceTiming"
		inAt: string
		inStartBeforeMinutes: number
		notifyTeacherAfterMinutes: number
		outAt: string
		outStartBeforeMinutes: number
	}
	attendanceMarkedBy?: {
		__typename?: "ExtendedAuthor"
		authorType: AuthorTypes
		studentId?: string | null
		staffId?: string | null
		schoolId?: string | null
		student?: {
			__typename?: "Student"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		} | null
		staff?: {
			__typename?: "Staff"
			_id: string
			name: string
			email?: string | null
			phone?: string | null
			staffId: string
			designation: string
			username: string
			departmentId: string
			tagId?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			subjectIds?: Array<string> | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		school?: {
			__typename?: "School"
			_id: string
			name: string
			schoolCode: string
			affiliationCode: string
			email: string
			phone: string
			createdAt: any
			updatedAt: any
			location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			cover?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
	} | null
}

export type ExtendedTrendingSkillFragment = {
	__typename?: "ExtendedTrendingSkill"
	_id: string
	name: string
	description: string
	topicId: string
	minAge?: number | null
	maxAge?: number | null
	links: string
	createdAt: any
	updatedAt: any
	author: {
		__typename?: "ExtendedAuthor"
		authorType: AuthorTypes
		studentId?: string | null
		staffId?: string | null
		schoolId?: string | null
		student?: {
			__typename?: "Student"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		} | null
		staff?: {
			__typename?: "Staff"
			_id: string
			name: string
			email?: string | null
			phone?: string | null
			staffId: string
			designation: string
			username: string
			departmentId: string
			tagId?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			subjectIds?: Array<string> | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		school?: {
			__typename?: "School"
			_id: string
			name: string
			schoolCode: string
			affiliationCode: string
			email: string
			phone: string
			createdAt: any
			updatedAt: any
			location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			cover?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
	}
	topic: { __typename?: "Topic"; _id: string; name: string; subTopics?: Array<string> | null; createdAt: any; updatedAt: any }
}

export type FeedPostLikeFragment = {
	__typename?: "FeedPostLike"
	createdAt: any
	updatedAt: any
	author: { __typename?: "Author"; authorType: AuthorTypes; studentId?: string | null; staffId?: string | null; schoolId?: string | null }
}

export type FieldErrorFragment = { __typename?: "FieldError"; field: string; error: string }

export type GalleryFragment = {
	__typename?: "Gallery"
	_id: string
	title: string
	description: string
	createdAt: any
	updatedAt: any
	attachments: {
		__typename?: "MediaAttachments"
		pictures?: Array<{
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		}> | null
		videos?: Array<{
			__typename?: "EmbeddedVideo"
			key: string
			url: string
			thumbnail?: string | null
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
		}> | null
		pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
	}
}

export type HolidayFragment = {
	__typename?: "Holiday"
	_id: string
	name: string
	eventType?: string | null
	startDate: any
	endDate: any
	createdAt: any
	updatedAt: any
}

export type HouseFragment = {
	__typename?: "House"
	_id: string
	name: string
	colorHex: string
	captainId?: string | null
	pointsBalance?: number | null
	createdAt: any
	updatedAt: any
}

export type LinksFragment = { __typename?: "Links"; youtube?: Array<string> | null; custom?: Array<string> | null }

export type MediaAttachmentsFragment = {
	__typename?: "MediaAttachments"
	pictures?: Array<{
		__typename?: "EmbeddedPicture"
		key: string
		url: string
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
	}> | null
	videos?: Array<{
		__typename?: "EmbeddedVideo"
		key: string
		url: string
		thumbnail?: string | null
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
	}> | null
	pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
}

export type ParentFragment = {
	__typename?: "Parent"
	name?: string | null
	phone?: string | null
	email?: string | null
	nfcTagId?: string | null
	picture?: {
		__typename?: "EmbeddedPicture"
		key: string
		url: string
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
	} | null
}

export type PaytankMerchantFragment = {
	__typename?: "PaytankMerchant"
	_id: string
	name: string
	description?: string | null
	username: string
	typeId: string
	createdAt: any
	updatedAt: any
}

export type PenaltyCategoryFragment = { __typename?: "PenaltyCategory"; _id: string; name: string; description: string; createdAt: any; updatedAt: any }

export type PointsRedeemItemFragment = {
	__typename?: "PointsRedeemItem"
	_id: string
	name: string
	description: string
	points: number
	createdAt: any
	updatedAt: any
}

export type PointsRedeemTransactionFragment = {
	__typename?: "PointsRedeemTransaction"
	_id: string
	pointsRedeemItemId: string
	isApproved?: boolean | null
	approvedAt?: any | null
	isRejected?: boolean | null
	rejectedAt?: any | null
	remarks?: string | null
	createdAt: any
	updatedAt: any
	author: { __typename?: "Author"; authorType: AuthorTypes; studentId?: string | null; staffId?: string | null; schoolId?: string | null }
}

export type RewardCategoryFragment = { __typename?: "RewardCategory"; _id: string; name: string; description: string; createdAt: any; updatedAt: any }

export type SchoolSessionFragment = {
	__typename?: "SchoolSession"
	_id: string
	name: string
	startMonth: number
	startDate: number
	endMonth: number
	endDate: number
	createdAt: any
	updatedAt: any
}

export type StaffFragment = {
	__typename?: "Staff"
	_id: string
	name: string
	email?: string | null
	phone?: string | null
	staffId: string
	designation: string
	username: string
	departmentId: string
	tagId?: string | null
	gender?: Genders | null
	dateOfBirth?: any | null
	address?: string | null
	bloodGroup?: BloodGroups | null
	subjectIds?: Array<string> | null
	createdAt: any
	updatedAt: any
	picture?: {
		__typename?: "EmbeddedPicture"
		key: string
		url: string
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
	} | null
}

export type StaffAttendanceTimingFragment = {
	__typename?: "StaffAttendanceTiming"
	inAt: string
	inStartBeforeMinutes: number
	outAt: string
	outStartBeforeMinutes: number
}

export type StudentFragment = {
	__typename?: "Student"
	_id: string
	name: string
	username: string
	email?: string | null
	phone: string
	admissionId: string
	admissionDate?: any | null
	admissionType?: AdmissionTypes | null
	classId: string
	classSectionId: string
	tagId?: string | null
	houseId?: string | null
	isALS?: boolean | null
	isELS?: boolean | null
	status?: string | null
	nationality?: string | null
	gender?: Genders | null
	dateOfBirth?: any | null
	address?: string | null
	bloodGroup?: BloodGroups | null
	isWhitelisted?: boolean | null
	transportMode?: string | null
	busRouteId?: string | null
	pointsBalance?: number | null
	paytankBalance?: number | null
	createdAt: any
	updatedAt: any
	picture?: {
		__typename?: "EmbeddedPicture"
		key: string
		url: string
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
	} | null
	father?: {
		__typename?: "Parent"
		name?: string | null
		phone?: string | null
		email?: string | null
		nfcTagId?: string | null
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
	} | null
	mother?: {
		__typename?: "Parent"
		name?: string | null
		phone?: string | null
		email?: string | null
		nfcTagId?: string | null
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
	} | null
	guardian1?: {
		__typename?: "Parent"
		name?: string | null
		phone?: string | null
		email?: string | null
		nfcTagId?: string | null
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
	} | null
	guardian2?: {
		__typename?: "Parent"
		name?: string | null
		phone?: string | null
		email?: string | null
		nfcTagId?: string | null
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
	} | null
}

export type StudentAttendanceFragment = {
	__typename?: "StudentAttendance"
	_id: string
	studentId: string
	inAt: any
	outAt: any
	rssi: number
	temperature: number
	isFinal?: boolean | null
	isInRecorded?: boolean | null
	isOutRecorded?: boolean | null
	isInManual?: boolean | null
	isOutManual?: boolean | null
	isLate?: boolean | null
	lateByMinutes?: number | null
	isAttendanceMarkedByClassTeacher?: boolean | null
	createdAt: any
	updatedAt: any
	timing: {
		__typename?: "AttendanceTiming"
		inAt: string
		inStartBeforeMinutes: number
		notifyTeacherAfterMinutes: number
		outAt: string
		outStartBeforeMinutes: number
	}
}

export type StudentBusAttendanceFragment = {
	__typename?: "StudentBusAttendance"
	_id: string
	studentId: string
	homePickupAt?: any | null
	schoolDropAt?: any | null
	schoolPickupAt?: any | null
	homeDropAt?: any | null
	createdAt: any
	updatedAt: any
}

export type SubjectFragment = { __typename?: "Subject"; _id: string; name: string; createdAt: any; updatedAt: any }

export type TagFragment = {
	__typename?: "Tag"
	_id: string
	tagHexId: string
	tagDecimalId: string
	expiryAt: any
	isBlockedForPaytank?: boolean | null
	createdAt: any
	updatedAt: any
}

export type TopicFragment = { __typename?: "Topic"; _id: string; name: string; subTopics?: Array<string> | null; createdAt: any; updatedAt: any }

export type SchoolFragment = {
	__typename?: "School"
	_id: string
	name: string
	schoolCode: string
	affiliationCode: string
	email: string
	phone: string
	createdAt: any
	updatedAt: any
	location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
	picture?: {
		__typename?: "EmbeddedPicture"
		key: string
		url: string
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
	} | null
	cover?: {
		__typename?: "EmbeddedPicture"
		key: string
		url: string
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
	} | null
}

export type BlockTagForPaytankMutationVariables = Exact<{ [key: string]: never }>

export type BlockTagForPaytankMutation = { __typename?: "Mutation"; blockTagForPaytank: boolean }

export type ChangePasswordMutationVariables = Exact<{
	currentPassword: Scalars["String"]
	newPassword: Scalars["String"]
	shouldLogOutOnOtherDevices?: InputMaybe<Scalars["Boolean"]>
}>

export type ChangePasswordMutation = { __typename?: "Mutation"; changePassword: boolean }

export type CreateAddPaytankFundsOrderMutationVariables = Exact<{
	amount: Scalars["Float"]
	description?: InputMaybe<Scalars["String"]>
}>

export type CreateAddPaytankFundsOrderMutation = {
	__typename?: "Mutation"
	createAddPaytankFundsOrder: {
		__typename?: "ExtendedPaytankOrder"
		_id: string
		type: PaytankOrderTypes
		merchantId?: string | null
		studentId: string
		amount: number
		description?: string | null
		status: PaymentOrderStatus
		failureReason?: PaytankFailureReasons | null
		orderId?: string | null
		paymentId?: string | null
		refundId?: string | null
		refundAmount?: number | null
		refundCreatedAt?: any | null
		refundProcessedAt?: any | null
		refundFailedAt?: any | null
		createdAt: any
		updatedAt: any
		merchant?: {
			__typename?: "PaytankMerchant"
			_id: string
			name: string
			description?: string | null
			username: string
			typeId: string
			createdAt: any
			updatedAt: any
		} | null
		student: {
			__typename?: "Student"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}
	}
}

export type CreateCafeteriaRegistrationOrderMutationVariables = Exact<{
	quoteId: Scalars["String"]
}>

export type CreateCafeteriaRegistrationOrderMutation = {
	__typename?: "Mutation"
	createCafeteriaRegistrationOrder: {
		__typename?: "CafeteriaRegistrationOrder"
		_id: string
		studentId: string
		amount: number
		status: PaymentOrderStatus
		failureReason?: string | null
		quoteId: string
		orderId?: string | null
		paymentId?: string | null
		createdAt: any
		updatedAt: any
	}
}

export type CreateFeedPostMutationVariables = Exact<{
	input: FeedPostInput
}>

export type CreateFeedPostMutation = {
	__typename?: "Mutation"
	createFeedPost: {
		__typename?: "FeedPostWithFieldErrorsResponse"
		errors?: Array<{ __typename?: "FieldError"; field: string; error: string }> | null
		feedPost?: {
			__typename?: "ExtendedFeedPost"
			_id: string
			text?: string | null
			isApproved?: boolean | null
			isLiked?: boolean | null
			createdAt: any
			updatedAt: any
			author: {
				__typename?: "ExtendedAuthor"
				authorType: AuthorTypes
				studentId?: string | null
				staffId?: string | null
				schoolId?: string | null
				student?: {
					__typename?: "Student"
					_id: string
					name: string
					username: string
					email?: string | null
					phone: string
					admissionId: string
					admissionDate?: any | null
					admissionType?: AdmissionTypes | null
					classId: string
					classSectionId: string
					tagId?: string | null
					houseId?: string | null
					isALS?: boolean | null
					isELS?: boolean | null
					status?: string | null
					nationality?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					isWhitelisted?: boolean | null
					transportMode?: string | null
					busRouteId?: string | null
					pointsBalance?: number | null
					paytankBalance?: number | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
					father?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					mother?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					guardian1?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					guardian2?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
				} | null
				staff?: {
					__typename?: "Staff"
					_id: string
					name: string
					email?: string | null
					phone?: string | null
					staffId: string
					designation: string
					username: string
					departmentId: string
					tagId?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					subjectIds?: Array<string> | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				school?: {
					__typename?: "School"
					_id: string
					name: string
					schoolCode: string
					affiliationCode: string
					email: string
					phone: string
					createdAt: any
					updatedAt: any
					location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
					cover?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			}
			links?: { __typename?: "Links"; youtube?: Array<string> | null; custom?: Array<string> | null } | null
			attachments?: {
				__typename?: "MediaAttachments"
				pictures?: Array<{
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				}> | null
				videos?: Array<{
					__typename?: "EmbeddedVideo"
					key: string
					url: string
					thumbnail?: string | null
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
				}> | null
				pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
			} | null
			likes?: Array<{
				__typename?: "FeedPostLike"
				createdAt: any
				updatedAt: any
				author: { __typename?: "Author"; authorType: AuthorTypes; studentId?: string | null; staffId?: string | null; schoolId?: string | null }
			}> | null
		} | null
	}
}

export type DeleteFeedPostMutationVariables = Exact<{
	feedPostId: Scalars["String"]
}>

export type DeleteFeedPostMutation = { __typename?: "Mutation"; deleteFeedPost: boolean }

export type DislikeFeedPostMutationVariables = Exact<{
	feedPostId: Scalars["String"]
}>

export type DislikeFeedPostMutation = {
	__typename?: "Mutation"
	dislikeFeedPost: {
		__typename?: "ExtendedFeedPost"
		_id: string
		text?: string | null
		isApproved?: boolean | null
		isLiked?: boolean | null
		createdAt: any
		updatedAt: any
		author: {
			__typename?: "ExtendedAuthor"
			authorType: AuthorTypes
			studentId?: string | null
			staffId?: string | null
			schoolId?: string | null
			student?: {
				__typename?: "Student"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				pointsBalance?: number | null
				paytankBalance?: number | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				father?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				mother?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian1?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian2?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			} | null
			staff?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			school?: {
				__typename?: "School"
				_id: string
				name: string
				schoolCode: string
				affiliationCode: string
				email: string
				phone: string
				createdAt: any
				updatedAt: any
				location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				cover?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}
		links?: { __typename?: "Links"; youtube?: Array<string> | null; custom?: Array<string> | null } | null
		attachments?: {
			__typename?: "MediaAttachments"
			pictures?: Array<{
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			}> | null
			videos?: Array<{
				__typename?: "EmbeddedVideo"
				key: string
				url: string
				thumbnail?: string | null
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
			}> | null
			pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
		} | null
		likes?: Array<{
			__typename?: "FeedPostLike"
			createdAt: any
			updatedAt: any
			author: { __typename?: "Author"; authorType: AuthorTypes; studentId?: string | null; staffId?: string | null; schoolId?: string | null }
		}> | null
	}
}

export type EditProfileMutationVariables = Exact<{
	input: EditStudentInput
}>

export type EditProfileMutation = {
	__typename?: "Mutation"
	editProfile: {
		__typename?: "StudentWithFieldErrorsResponse"
		errors?: Array<{ __typename?: "FieldError"; field: string; error: string }> | null
		student?: {
			__typename?: "ExtendedStudent"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
			classSection: {
				__typename?: "ClassSection"
				_id: string
				classId: string
				name: string
				classTeacherId?: string | null
				createdAt: any
				updatedAt: any
			}
			tag?: {
				__typename?: "Tag"
				_id: string
				tagHexId: string
				tagDecimalId: string
				expiryAt: any
				isBlockedForPaytank?: boolean | null
				createdAt: any
				updatedAt: any
			} | null
			house?: {
				__typename?: "House"
				_id: string
				name: string
				colorHex: string
				captainId?: string | null
				pointsBalance?: number | null
				createdAt: any
				updatedAt: any
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			busRoute?: {
				__typename?: "BusRoute"
				_id: string
				routeName: string
				vehicleNo?: string | null
				busDriverName: string
				busDriverPhone?: string | null
				busConductorName: string
				busConductorPhone?: string | null
				createdAt: any
				updatedAt: any
			} | null
			cafeteriaRegistration?: {
				__typename?: "CafeteriaRegistrationLog"
				_id: string
				studentId: string
				schoolSessionId: string
				since: any
				until: any
				createdAt: any
				updatedAt: any
			} | null
			upcomingCafeteriaRegistration?: {
				__typename?: "CafeteriaRegistrationLog"
				_id: string
				studentId: string
				schoolSessionId: string
				since: any
				until: any
				createdAt: any
				updatedAt: any
			} | null
		} | null
	}
}

export type FinishAddPaytankFundsOrderMutationVariables = Exact<{
	input: FinishPaymentOrderInput
}>

export type FinishAddPaytankFundsOrderMutation = {
	__typename?: "Mutation"
	finishAddPaytankFundsOrder: {
		__typename?: "ExtendedPaytankOrder"
		_id: string
		type: PaytankOrderTypes
		merchantId?: string | null
		studentId: string
		amount: number
		description?: string | null
		status: PaymentOrderStatus
		failureReason?: PaytankFailureReasons | null
		orderId?: string | null
		paymentId?: string | null
		refundId?: string | null
		refundAmount?: number | null
		refundCreatedAt?: any | null
		refundProcessedAt?: any | null
		refundFailedAt?: any | null
		createdAt: any
		updatedAt: any
		merchant?: {
			__typename?: "PaytankMerchant"
			_id: string
			name: string
			description?: string | null
			username: string
			typeId: string
			createdAt: any
			updatedAt: any
		} | null
		student: {
			__typename?: "Student"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}
	}
}

export type FinishCafeteriaRegistrationOrderMutationVariables = Exact<{
	input: FinishPaymentOrderInput
}>

export type FinishCafeteriaRegistrationOrderMutation = {
	__typename?: "Mutation"
	finishCafeteriaRegistrationOrder: {
		__typename?: "CafeteriaRegistrationOrder"
		_id: string
		studentId: string
		amount: number
		status: PaymentOrderStatus
		failureReason?: string | null
		quoteId: string
		orderId?: string | null
		paymentId?: string | null
		createdAt: any
		updatedAt: any
	}
}

export type FinishPdfUploadMutationVariables = Exact<{
	input: FinishUploadInput
}>

export type FinishPdfUploadMutation = { __typename?: "Mutation"; finishPdfUpload: { __typename?: "FinishUploadResponse"; type: MediaTypes; url: string } }

export type FinishPictureUploadMutationVariables = Exact<{
	input: FinishUploadInput
}>

export type FinishPictureUploadMutation = {
	__typename?: "Mutation"
	finishPictureUpload: { __typename?: "FinishUploadResponse"; type: MediaTypes; url: string }
}

export type FinishVideoUploadMutationVariables = Exact<{
	input: FinishUploadInput
}>

export type FinishVideoUploadMutation = { __typename?: "Mutation"; finishVideoUpload: { __typename?: "FinishUploadResponse"; type: MediaTypes; url: string } }

export type LikeFeedPostMutationVariables = Exact<{
	feedPostId: Scalars["String"]
}>

export type LikeFeedPostMutation = {
	__typename?: "Mutation"
	likeFeedPost: {
		__typename?: "ExtendedFeedPost"
		_id: string
		text?: string | null
		isApproved?: boolean | null
		isLiked?: boolean | null
		createdAt: any
		updatedAt: any
		author: {
			__typename?: "ExtendedAuthor"
			authorType: AuthorTypes
			studentId?: string | null
			staffId?: string | null
			schoolId?: string | null
			student?: {
				__typename?: "Student"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				pointsBalance?: number | null
				paytankBalance?: number | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				father?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				mother?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian1?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian2?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			} | null
			staff?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			school?: {
				__typename?: "School"
				_id: string
				name: string
				schoolCode: string
				affiliationCode: string
				email: string
				phone: string
				createdAt: any
				updatedAt: any
				location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				cover?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}
		links?: { __typename?: "Links"; youtube?: Array<string> | null; custom?: Array<string> | null } | null
		attachments?: {
			__typename?: "MediaAttachments"
			pictures?: Array<{
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			}> | null
			videos?: Array<{
				__typename?: "EmbeddedVideo"
				key: string
				url: string
				thumbnail?: string | null
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
			}> | null
			pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
		} | null
		likes?: Array<{
			__typename?: "FeedPostLike"
			createdAt: any
			updatedAt: any
			author: { __typename?: "Author"; authorType: AuthorTypes; studentId?: string | null; staffId?: string | null; schoolId?: string | null }
		}> | null
	}
}

export type LoginMutationVariables = Exact<{
	username: Scalars["String"]
	password: Scalars["String"]
}>

export type LoginMutation = {
	__typename?: "Mutation"
	login: {
		__typename?: "AuthResponse"
		sessionId?: string | null
		errors?: Array<{ __typename?: "FieldError"; field: string; error: string }> | null
		student?: {
			__typename?: "ExtendedStudent"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
			classSection: {
				__typename?: "ClassSection"
				_id: string
				classId: string
				name: string
				classTeacherId?: string | null
				createdAt: any
				updatedAt: any
			}
			tag?: {
				__typename?: "Tag"
				_id: string
				tagHexId: string
				tagDecimalId: string
				expiryAt: any
				isBlockedForPaytank?: boolean | null
				createdAt: any
				updatedAt: any
			} | null
			house?: {
				__typename?: "House"
				_id: string
				name: string
				colorHex: string
				captainId?: string | null
				pointsBalance?: number | null
				createdAt: any
				updatedAt: any
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			busRoute?: {
				__typename?: "BusRoute"
				_id: string
				routeName: string
				vehicleNo?: string | null
				busDriverName: string
				busDriverPhone?: string | null
				busConductorName: string
				busConductorPhone?: string | null
				createdAt: any
				updatedAt: any
			} | null
			cafeteriaRegistration?: {
				__typename?: "CafeteriaRegistrationLog"
				_id: string
				studentId: string
				schoolSessionId: string
				since: any
				until: any
				createdAt: any
				updatedAt: any
			} | null
			upcomingCafeteriaRegistration?: {
				__typename?: "CafeteriaRegistrationLog"
				_id: string
				studentId: string
				schoolSessionId: string
				since: any
				until: any
				createdAt: any
				updatedAt: any
			} | null
		} | null
	}
}

export type LoginWithAzureSsoMutationVariables = Exact<{
	accessToken: Scalars["String"]
	tenantId: Scalars["String"]
}>

export type LoginWithAzureSsoMutation = {
	__typename?: "Mutation"
	loginWithAzureSSO: {
		__typename?: "AuthResponse"
		sessionId?: string | null
		errors?: Array<{ __typename?: "FieldError"; field: string; error: string }> | null
		student?: {
			__typename?: "ExtendedStudent"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
			classSection: {
				__typename?: "ClassSection"
				_id: string
				classId: string
				name: string
				classTeacherId?: string | null
				createdAt: any
				updatedAt: any
			}
			tag?: {
				__typename?: "Tag"
				_id: string
				tagHexId: string
				tagDecimalId: string
				expiryAt: any
				isBlockedForPaytank?: boolean | null
				createdAt: any
				updatedAt: any
			} | null
			house?: {
				__typename?: "House"
				_id: string
				name: string
				colorHex: string
				captainId?: string | null
				pointsBalance?: number | null
				createdAt: any
				updatedAt: any
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			busRoute?: {
				__typename?: "BusRoute"
				_id: string
				routeName: string
				vehicleNo?: string | null
				busDriverName: string
				busDriverPhone?: string | null
				busConductorName: string
				busConductorPhone?: string | null
				createdAt: any
				updatedAt: any
			} | null
			cafeteriaRegistration?: {
				__typename?: "CafeteriaRegistrationLog"
				_id: string
				studentId: string
				schoolSessionId: string
				since: any
				until: any
				createdAt: any
				updatedAt: any
			} | null
			upcomingCafeteriaRegistration?: {
				__typename?: "CafeteriaRegistrationLog"
				_id: string
				studentId: string
				schoolSessionId: string
				since: any
				until: any
				createdAt: any
				updatedAt: any
			} | null
		} | null
	}
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = { __typename?: "Mutation"; logout: boolean }

export type RedeemPointsMutationVariables = Exact<{
	pointsRedeemItemId: Scalars["String"]
}>

export type RedeemPointsMutation = {
	__typename?: "Mutation"
	redeemPoints: {
		__typename?: "PointsRedeemTransaction"
		_id: string
		pointsRedeemItemId: string
		isApproved?: boolean | null
		approvedAt?: any | null
		isRejected?: boolean | null
		rejectedAt?: any | null
		remarks?: string | null
		createdAt: any
		updatedAt: any
		author: { __typename?: "Author"; authorType: AuthorTypes; studentId?: string | null; staffId?: string | null; schoolId?: string | null }
	}
}

export type SetAvatarMutationVariables = Exact<{
	key: Scalars["String"]
	url: Scalars["String"]
}>

export type SetAvatarMutation = {
	__typename?: "Mutation"
	setAvatar: {
		__typename?: "EmbeddedPicture"
		key: string
		url: string
		createdAt: any
		updatedAt: any
		variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
	}
}

export type SignPdfUploadMutationVariables = Exact<{ [key: string]: never }>

export type SignPdfUploadMutation = { __typename?: "Mutation"; signPdfUpload: { __typename?: "UploadSignatureResponse"; signedUrl: string; key: string } }

export type SignPictureUploadMutationVariables = Exact<{
	format: Scalars["String"]
}>

export type SignPictureUploadMutation = {
	__typename?: "Mutation"
	signPictureUpload: { __typename?: "UploadSignatureResponse"; signedUrl: string; key: string }
}

export type SignVideoUploadMutationVariables = Exact<{
	format: Scalars["String"]
}>

export type SignVideoUploadMutation = { __typename?: "Mutation"; signVideoUpload: { __typename?: "UploadSignatureResponse"; signedUrl: string; key: string } }

export type UnblockTagForPaytankMutationVariables = Exact<{ [key: string]: never }>

export type UnblockTagForPaytankMutation = { __typename?: "Mutation"; unblockTagForPaytank: boolean }

export type AllAttendancesOfMeQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllAttendancesOfMeQuery = {
	__typename?: "Query"
	allAttendancesOfMe: {
		__typename?: "PaginatedStudentAttendancesResponse"
		total: number
		totalPages: number
		hasNextPage: boolean
		hasPrevPage: boolean
		currentPage: number
		attendances?: Array<{
			__typename?: "ExtendedStudentAttendance"
			_id: string
			studentId: string
			classId: string
			classSectionId: string
			inAt: any
			outAt: any
			rssi: number
			temperature: number
			isFinal?: boolean | null
			isInRecorded?: boolean | null
			isOutRecorded?: boolean | null
			isInManual?: boolean | null
			isOutManual?: boolean | null
			isLate?: boolean | null
			isAbsent?: boolean | null
			lateByMinutes?: number | null
			isAttendanceMarkedByClassTeacher?: boolean | null
			createdAt: any
			updatedAt: any
			student: {
				__typename?: "ExtendedStudent"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				pointsBalance?: number | null
				paytankBalance?: number | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
				classSection: {
					__typename?: "ClassSection"
					_id: string
					classId: string
					name: string
					classTeacherId?: string | null
					createdAt: any
					updatedAt: any
				}
				tag?: {
					__typename?: "Tag"
					_id: string
					tagHexId: string
					tagDecimalId: string
					expiryAt: any
					isBlockedForPaytank?: boolean | null
					createdAt: any
					updatedAt: any
				} | null
				house?: {
					__typename?: "House"
					_id: string
					name: string
					colorHex: string
					captainId?: string | null
					pointsBalance?: number | null
					createdAt: any
					updatedAt: any
				} | null
				father?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				mother?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian1?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian2?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				busRoute?: {
					__typename?: "BusRoute"
					_id: string
					routeName: string
					vehicleNo?: string | null
					busDriverName: string
					busDriverPhone?: string | null
					busConductorName: string
					busConductorPhone?: string | null
					createdAt: any
					updatedAt: any
				} | null
				cafeteriaRegistration?: {
					__typename?: "CafeteriaRegistrationLog"
					_id: string
					studentId: string
					schoolSessionId: string
					since: any
					until: any
					createdAt: any
					updatedAt: any
				} | null
				upcomingCafeteriaRegistration?: {
					__typename?: "CafeteriaRegistrationLog"
					_id: string
					studentId: string
					schoolSessionId: string
					since: any
					until: any
					createdAt: any
					updatedAt: any
				} | null
			}
			class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
			classSection: {
				__typename?: "ClassSection"
				_id: string
				classId: string
				name: string
				classTeacherId?: string | null
				createdAt: any
				updatedAt: any
			}
			timing: {
				__typename?: "AttendanceTiming"
				inAt: string
				inStartBeforeMinutes: number
				notifyTeacherAfterMinutes: number
				outAt: string
				outStartBeforeMinutes: number
			}
			attendanceMarkedBy?: {
				__typename?: "ExtendedAuthor"
				authorType: AuthorTypes
				studentId?: string | null
				staffId?: string | null
				schoolId?: string | null
				student?: {
					__typename?: "Student"
					_id: string
					name: string
					username: string
					email?: string | null
					phone: string
					admissionId: string
					admissionDate?: any | null
					admissionType?: AdmissionTypes | null
					classId: string
					classSectionId: string
					tagId?: string | null
					houseId?: string | null
					isALS?: boolean | null
					isELS?: boolean | null
					status?: string | null
					nationality?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					isWhitelisted?: boolean | null
					transportMode?: string | null
					busRouteId?: string | null
					pointsBalance?: number | null
					paytankBalance?: number | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
					father?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					mother?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					guardian1?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					guardian2?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
				} | null
				staff?: {
					__typename?: "Staff"
					_id: string
					name: string
					email?: string | null
					phone?: string | null
					staffId: string
					designation: string
					username: string
					departmentId: string
					tagId?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					subjectIds?: Array<string> | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				school?: {
					__typename?: "School"
					_id: string
					name: string
					schoolCode: string
					affiliationCode: string
					email: string
					phone: string
					createdAt: any
					updatedAt: any
					location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
					cover?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			} | null
		}> | null
	}
}

export type AllCircularsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllCircularsQuery = {
	__typename?: "Query"
	allCirculars: {
		__typename?: "PaginatedCircularsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		circulars?: Array<{
			__typename?: "Circular"
			_id: string
			title: string
			message: string
			targets: Array<UserTargets>
			createdAt: any
			updatedAt: any
		}> | null
	}
}

export type AllFeedPostsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllFeedPostsQuery = {
	__typename?: "Query"
	allFeedPosts: {
		__typename?: "PaginatedFeedPostsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		feedPosts?: Array<{
			__typename?: "ExtendedFeedPost"
			_id: string
			text?: string | null
			isApproved?: boolean | null
			isLiked?: boolean | null
			createdAt: any
			updatedAt: any
			author: {
				__typename?: "ExtendedAuthor"
				authorType: AuthorTypes
				studentId?: string | null
				staffId?: string | null
				schoolId?: string | null
				student?: {
					__typename?: "Student"
					_id: string
					name: string
					username: string
					email?: string | null
					phone: string
					admissionId: string
					admissionDate?: any | null
					admissionType?: AdmissionTypes | null
					classId: string
					classSectionId: string
					tagId?: string | null
					houseId?: string | null
					isALS?: boolean | null
					isELS?: boolean | null
					status?: string | null
					nationality?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					isWhitelisted?: boolean | null
					transportMode?: string | null
					busRouteId?: string | null
					pointsBalance?: number | null
					paytankBalance?: number | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
					father?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					mother?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					guardian1?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					guardian2?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
				} | null
				staff?: {
					__typename?: "Staff"
					_id: string
					name: string
					email?: string | null
					phone?: string | null
					staffId: string
					designation: string
					username: string
					departmentId: string
					tagId?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					subjectIds?: Array<string> | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				school?: {
					__typename?: "School"
					_id: string
					name: string
					schoolCode: string
					affiliationCode: string
					email: string
					phone: string
					createdAt: any
					updatedAt: any
					location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
					cover?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			}
			links?: { __typename?: "Links"; youtube?: Array<string> | null; custom?: Array<string> | null } | null
			attachments?: {
				__typename?: "MediaAttachments"
				pictures?: Array<{
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				}> | null
				videos?: Array<{
					__typename?: "EmbeddedVideo"
					key: string
					url: string
					thumbnail?: string | null
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
				}> | null
				pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
			} | null
			likes?: Array<{
				__typename?: "FeedPostLike"
				createdAt: any
				updatedAt: any
				author: { __typename?: "Author"; authorType: AuthorTypes; studentId?: string | null; staffId?: string | null; schoolId?: string | null }
			}> | null
		}> | null
	}
}

export type AllGalleryQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllGalleryQuery = {
	__typename?: "Query"
	allGallery: {
		__typename?: "PaginatedGalleryResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		gallery?: Array<{
			__typename?: "Gallery"
			_id: string
			title: string
			description: string
			createdAt: any
			updatedAt: any
			attachments: {
				__typename?: "MediaAttachments"
				pictures?: Array<{
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				}> | null
				videos?: Array<{
					__typename?: "EmbeddedVideo"
					key: string
					url: string
					thumbnail?: string | null
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
				}> | null
				pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
			}
		}> | null
	}
}

export type AllHolidaysQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllHolidaysQuery = {
	__typename?: "Query"
	allHolidays: {
		__typename?: "PaginatedHolidaysResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		holidays?: Array<{
			__typename?: "Holiday"
			_id: string
			name: string
			eventType?: string | null
			startDate: any
			endDate: any
			createdAt: any
			updatedAt: any
		}> | null
	}
}

export type AllPaytankOrdersQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllPaytankOrdersQuery = {
	__typename?: "Query"
	allPaytankOrders: {
		__typename?: "PaginatedPaytankOrdersResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		paytankOrders?: Array<{
			__typename?: "ExtendedPaytankOrder"
			_id: string
			type: PaytankOrderTypes
			merchantId?: string | null
			studentId: string
			amount: number
			description?: string | null
			status: PaymentOrderStatus
			failureReason?: PaytankFailureReasons | null
			orderId?: string | null
			paymentId?: string | null
			refundId?: string | null
			refundAmount?: number | null
			refundCreatedAt?: any | null
			refundProcessedAt?: any | null
			refundFailedAt?: any | null
			createdAt: any
			updatedAt: any
			merchant?: {
				__typename?: "PaytankMerchant"
				_id: string
				name: string
				description?: string | null
				username: string
				typeId: string
				createdAt: any
				updatedAt: any
			} | null
			student: {
				__typename?: "Student"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				pointsBalance?: number | null
				paytankBalance?: number | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				father?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				mother?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian1?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian2?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			}
		}> | null
	}
}

export type AllPointsRedeemItemsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllPointsRedeemItemsQuery = {
	__typename?: "Query"
	allPointsRedeemItems: {
		__typename?: "PaginatedPointsRedeemItemsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		pointsRedeemItems?: Array<{
			__typename?: "PointsRedeemItem"
			_id: string
			name: string
			description: string
			points: number
			createdAt: any
			updatedAt: any
		}> | null
	}
}

export type AllResourcesQueryVariables = Exact<{
	classId?: InputMaybe<Scalars["String"]>
	classSectionId?: InputMaybe<Scalars["String"]>
	subjectId?: InputMaybe<Scalars["String"]>
	topicId?: InputMaybe<Scalars["String"]>
	subTopic?: InputMaybe<Scalars["String"]>
}>

export type AllResourcesQuery = {
	__typename?: "Query"
	allResources: Array<{
		__typename?: "ExtendedResource"
		_id: string
		targets: Array<UserTargets>
		name: string
		description: string
		classId: string
		classSectionId?: string | null
		subjectId: string
		topicId: string
		subTopic?: string | null
		createdAt: any
		updatedAt: any
		author: {
			__typename?: "ExtendedAuthor"
			authorType: AuthorTypes
			studentId?: string | null
			staffId?: string | null
			schoolId?: string | null
			student?: {
				__typename?: "Student"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				pointsBalance?: number | null
				paytankBalance?: number | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				father?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				mother?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian1?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian2?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			} | null
			staff?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			school?: {
				__typename?: "School"
				_id: string
				name: string
				schoolCode: string
				affiliationCode: string
				email: string
				phone: string
				createdAt: any
				updatedAt: any
				location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				cover?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}
		class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
		classSection?: {
			__typename?: "ClassSection"
			_id: string
			classId: string
			name: string
			classTeacherId?: string | null
			createdAt: any
			updatedAt: any
		} | null
		subject: { __typename?: "Subject"; _id: string; name: string; createdAt: any; updatedAt: any }
		topic: { __typename?: "Topic"; _id: string; name: string; subTopics?: Array<string> | null; createdAt: any; updatedAt: any }
		attachments: {
			__typename?: "MediaAttachments"
			pictures?: Array<{
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			}> | null
			videos?: Array<{
				__typename?: "EmbeddedVideo"
				key: string
				url: string
				thumbnail?: string | null
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
			}> | null
			pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
		}
	}>
}

export type AllStaffsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type AllStaffsQuery = {
	__typename?: "Query"
	allStaffs: {
		__typename?: "PaginatedStaffResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		staffs?: Array<{
			__typename?: "ExtendedStaff"
			_id: string
			name: string
			email?: string | null
			phone?: string | null
			username: string
			staffId: string
			designation: string
			departmentId: string
			tagId?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			subjectIds?: Array<string> | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			department: {
				__typename?: "Department"
				_id: string
				name: string
				type: DepartmentTypes
				createdAt: any
				updatedAt: any
				attendanceTiming: {
					__typename?: "StaffAttendanceTiming"
					inAt: string
					inStartBeforeMinutes: number
					outAt: string
					outStartBeforeMinutes: number
				}
			}
			tag?: {
				__typename?: "Tag"
				_id: string
				tagHexId: string
				tagDecimalId: string
				expiryAt: any
				isBlockedForPaytank?: boolean | null
				createdAt: any
				updatedAt: any
			} | null
			subjects: Array<{ __typename?: "Subject"; _id: string; name: string; createdAt: any; updatedAt: any }>
			roles?: Array<{
				__typename?: "ExtendedStaffRole"
				role: StaffRoles
				classId?: string | null
				classSectionId?: string | null
				assignedAt: any
				removedAt?: any | null
				class?: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any } | null
				classSection?: {
					__typename?: "ClassSection"
					_id: string
					classId: string
					name: string
					classTeacherId?: string | null
					createdAt: any
					updatedAt: any
				} | null
			}> | null
			pastRoles?: Array<{
				__typename?: "ExtendedStaffRole"
				role: StaffRoles
				classId?: string | null
				classSectionId?: string | null
				assignedAt: any
				removedAt?: any | null
				class?: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any } | null
				classSection?: {
					__typename?: "ClassSection"
					_id: string
					classId: string
					name: string
					classTeacherId?: string | null
					createdAt: any
					updatedAt: any
				} | null
			}> | null
		}> | null
	}
}

export type AllStudentsQueryVariables = Exact<{
	pagination: PaginatedInput
	classId?: InputMaybe<Scalars["String"]>
	classSectionId?: InputMaybe<Scalars["String"]>
}>

export type AllStudentsQuery = {
	__typename?: "Query"
	allStudents: {
		__typename?: "PaginatedStudentsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		students?: Array<{
			__typename?: "ExtendedStudent"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
			classSection: {
				__typename?: "ClassSection"
				_id: string
				classId: string
				name: string
				classTeacherId?: string | null
				createdAt: any
				updatedAt: any
			}
			tag?: {
				__typename?: "Tag"
				_id: string
				tagHexId: string
				tagDecimalId: string
				expiryAt: any
				isBlockedForPaytank?: boolean | null
				createdAt: any
				updatedAt: any
			} | null
			house?: {
				__typename?: "House"
				_id: string
				name: string
				colorHex: string
				captainId?: string | null
				pointsBalance?: number | null
				createdAt: any
				updatedAt: any
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			busRoute?: {
				__typename?: "BusRoute"
				_id: string
				routeName: string
				vehicleNo?: string | null
				busDriverName: string
				busDriverPhone?: string | null
				busConductorName: string
				busConductorPhone?: string | null
				createdAt: any
				updatedAt: any
			} | null
			cafeteriaRegistration?: {
				__typename?: "CafeteriaRegistrationLog"
				_id: string
				studentId: string
				schoolSessionId: string
				since: any
				until: any
				createdAt: any
				updatedAt: any
			} | null
			upcomingCafeteriaRegistration?: {
				__typename?: "CafeteriaRegistrationLog"
				_id: string
				studentId: string
				schoolSessionId: string
				since: any
				until: any
				createdAt: any
				updatedAt: any
			} | null
		}> | null
	}
}

export type AllSubjectsQueryVariables = Exact<{ [key: string]: never }>

export type AllSubjectsQuery = {
	__typename?: "Query"
	allSubjects: Array<{ __typename?: "Subject"; _id: string; name: string; createdAt: any; updatedAt: any }>
}

export type AllTopicsQueryVariables = Exact<{ [key: string]: never }>

export type AllTopicsQuery = {
	__typename?: "Query"
	allTopics: Array<{ __typename?: "Topic"; _id: string; name: string; subTopics?: Array<string> | null; createdAt: any; updatedAt: any }>
}

export type AllTrendingSkillsQueryVariables = Exact<{
	forYou: Scalars["Boolean"]
	topicId?: InputMaybe<Scalars["String"]>
	pagination: PaginatedInput
}>

export type AllTrendingSkillsQuery = {
	__typename?: "Query"
	allTrendingSkills: {
		__typename?: "PaginatedTrendingSkillsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		trendingSkills?: Array<{
			__typename?: "ExtendedTrendingSkill"
			_id: string
			name: string
			description: string
			topicId: string
			minAge?: number | null
			maxAge?: number | null
			links: string
			createdAt: any
			updatedAt: any
			author: {
				__typename?: "ExtendedAuthor"
				authorType: AuthorTypes
				studentId?: string | null
				staffId?: string | null
				schoolId?: string | null
				student?: {
					__typename?: "Student"
					_id: string
					name: string
					username: string
					email?: string | null
					phone: string
					admissionId: string
					admissionDate?: any | null
					admissionType?: AdmissionTypes | null
					classId: string
					classSectionId: string
					tagId?: string | null
					houseId?: string | null
					isALS?: boolean | null
					isELS?: boolean | null
					status?: string | null
					nationality?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					isWhitelisted?: boolean | null
					transportMode?: string | null
					busRouteId?: string | null
					pointsBalance?: number | null
					paytankBalance?: number | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
					father?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					mother?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					guardian1?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					guardian2?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
				} | null
				staff?: {
					__typename?: "Staff"
					_id: string
					name: string
					email?: string | null
					phone?: string | null
					staffId: string
					designation: string
					username: string
					departmentId: string
					tagId?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					subjectIds?: Array<string> | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				school?: {
					__typename?: "School"
					_id: string
					name: string
					schoolCode: string
					affiliationCode: string
					email: string
					phone: string
					createdAt: any
					updatedAt: any
					location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
					cover?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			}
			topic: { __typename?: "Topic"; _id: string; name: string; subTopics?: Array<string> | null; createdAt: any; updatedAt: any }
		}> | null
	}
}

export type BusRouteByIdQueryVariables = Exact<{
	busRouteId: Scalars["String"]
}>

export type BusRouteByIdQuery = {
	__typename?: "Query"
	busRouteById: {
		__typename?: "BusRoute"
		_id: string
		routeName: string
		vehicleNo?: string | null
		busDriverName: string
		busDriverPhone?: string | null
		busConductorName: string
		busConductorPhone?: string | null
		createdAt: any
		updatedAt: any
	}
}

export type BusRoutesQueryVariables = Exact<{ [key: string]: never }>

export type BusRoutesQuery = {
	__typename?: "Query"
	busRoutes: Array<{
		__typename?: "BusRoute"
		_id: string
		routeName: string
		vehicleNo?: string | null
		busDriverName: string
		busDriverPhone?: string | null
		busConductorName: string
		busConductorPhone?: string | null
		createdAt: any
		updatedAt: any
	}>
}

export type CafeteriaRegistrationQuoteQueryVariables = Exact<{
	sessionIds: Array<Scalars["String"]> | Scalars["String"]
}>

export type CafeteriaRegistrationQuoteQuery = {
	__typename?: "Query"
	cafeteriaRegistrationQuote: {
		__typename?: "CafeteriaRegistrationQuote"
		_id: string
		studentId: string
		totalAmount: number
		createdAt: any
		updatedAt: any
		sessionWiseQuotes: Array<{
			__typename?: "CafeteriaRegistrationSessionQuote"
			schoolSessionId: string
			schoolSessionStartAt: any
			schoolSessionEndAt: any
			totalAmount: number
			breakup: Array<{ __typename?: "AmountBreakup"; label: string; amount: number; payable?: number | null }>
		}>
	}
}

export type CircularByIdQueryVariables = Exact<{
	circularId: Scalars["String"]
}>

export type CircularByIdQuery = {
	__typename?: "Query"
	circularById: { __typename?: "Circular"; _id: string; title: string; message: string; targets: Array<UserTargets>; createdAt: any; updatedAt: any }
}

export type ClassByIdQueryVariables = Exact<{
	classId: Scalars["String"]
}>

export type ClassByIdQuery = {
	__typename?: "Query"
	classById: {
		__typename?: "ExtendedClass"
		_id: string
		name: string
		createdAt: any
		updatedAt: any
		sections: Array<{
			__typename?: "ExtendedClassSection"
			_id: string
			classId: string
			name: string
			classTeacherId?: string | null
			createdAt: any
			updatedAt: any
			classTeacher?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}>
	}
}

export type ClassesQueryVariables = Exact<{ [key: string]: never }>

export type ClassesQuery = {
	__typename?: "Query"
	classes: Array<{
		__typename?: "ExtendedClass"
		_id: string
		name: string
		createdAt: any
		updatedAt: any
		sections: Array<{
			__typename?: "ExtendedClassSection"
			_id: string
			classId: string
			name: string
			classTeacherId?: string | null
			createdAt: any
			updatedAt: any
			classTeacher?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}>
	}>
}

export type CurrentAndUpcomingSessionQueryVariables = Exact<{ [key: string]: never }>

export type CurrentAndUpcomingSessionQuery = {
	__typename?: "Query"
	currentAndUpcomingSession: {
		__typename?: "SchoolSessionWithStatusResponse"
		currentSession?: {
			__typename?: "SchoolSession"
			_id: string
			name: string
			startMonth: number
			startDate: number
			endMonth: number
			endDate: number
			createdAt: any
			updatedAt: any
		} | null
		upcomingSession?: {
			__typename?: "SchoolSession"
			_id: string
			name: string
			startMonth: number
			startDate: number
			endMonth: number
			endDate: number
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type FeedPostByIdQueryVariables = Exact<{
	feedPostId: Scalars["String"]
}>

export type FeedPostByIdQuery = {
	__typename?: "Query"
	feedPostById: {
		__typename?: "ExtendedFeedPost"
		_id: string
		text?: string | null
		isApproved?: boolean | null
		isLiked?: boolean | null
		createdAt: any
		updatedAt: any
		author: {
			__typename?: "ExtendedAuthor"
			authorType: AuthorTypes
			studentId?: string | null
			staffId?: string | null
			schoolId?: string | null
			student?: {
				__typename?: "Student"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				pointsBalance?: number | null
				paytankBalance?: number | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				father?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				mother?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian1?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian2?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			} | null
			staff?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			school?: {
				__typename?: "School"
				_id: string
				name: string
				schoolCode: string
				affiliationCode: string
				email: string
				phone: string
				createdAt: any
				updatedAt: any
				location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				cover?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}
		links?: { __typename?: "Links"; youtube?: Array<string> | null; custom?: Array<string> | null } | null
		attachments?: {
			__typename?: "MediaAttachments"
			pictures?: Array<{
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			}> | null
			videos?: Array<{
				__typename?: "EmbeddedVideo"
				key: string
				url: string
				thumbnail?: string | null
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
			}> | null
			pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
		} | null
		likes?: Array<{
			__typename?: "FeedPostLike"
			createdAt: any
			updatedAt: any
			author: { __typename?: "Author"; authorType: AuthorTypes; studentId?: string | null; staffId?: string | null; schoolId?: string | null }
		}> | null
	}
}

export type GalleryByIdQueryVariables = Exact<{
	galleryId: Scalars["String"]
}>

export type GalleryByIdQuery = {
	__typename?: "Query"
	galleryById: {
		__typename?: "Gallery"
		_id: string
		title: string
		description: string
		createdAt: any
		updatedAt: any
		attachments: {
			__typename?: "MediaAttachments"
			pictures?: Array<{
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			}> | null
			videos?: Array<{
				__typename?: "EmbeddedVideo"
				key: string
				url: string
				thumbnail?: string | null
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
			}> | null
			pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
		}
	}
}

export type HousesWithMostPointsQueryVariables = Exact<{ [key: string]: never }>

export type HousesWithMostPointsQuery = {
	__typename?: "Query"
	housesWithMostPoints: Array<{
		__typename?: "House"
		_id: string
		name: string
		colorHex: string
		captainId?: string | null
		pointsBalance?: number | null
		createdAt: any
		updatedAt: any
	}>
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
	__typename?: "Query"
	me?: {
		__typename?: "ExtendedStudent"
		_id: string
		name: string
		username: string
		email?: string | null
		phone: string
		admissionId: string
		admissionDate?: any | null
		admissionType?: AdmissionTypes | null
		classId: string
		classSectionId: string
		tagId?: string | null
		houseId?: string | null
		isALS?: boolean | null
		isELS?: boolean | null
		status?: string | null
		nationality?: string | null
		gender?: Genders | null
		dateOfBirth?: any | null
		address?: string | null
		bloodGroup?: BloodGroups | null
		isWhitelisted?: boolean | null
		transportMode?: string | null
		busRouteId?: string | null
		pointsBalance?: number | null
		paytankBalance?: number | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
		class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
		classSection: {
			__typename?: "ClassSection"
			_id: string
			classId: string
			name: string
			classTeacherId?: string | null
			createdAt: any
			updatedAt: any
		}
		tag?: {
			__typename?: "Tag"
			_id: string
			tagHexId: string
			tagDecimalId: string
			expiryAt: any
			isBlockedForPaytank?: boolean | null
			createdAt: any
			updatedAt: any
		} | null
		house?: {
			__typename?: "House"
			_id: string
			name: string
			colorHex: string
			captainId?: string | null
			pointsBalance?: number | null
			createdAt: any
			updatedAt: any
		} | null
		father?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		mother?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		guardian1?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		guardian2?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		busRoute?: {
			__typename?: "BusRoute"
			_id: string
			routeName: string
			vehicleNo?: string | null
			busDriverName: string
			busDriverPhone?: string | null
			busConductorName: string
			busConductorPhone?: string | null
			createdAt: any
			updatedAt: any
		} | null
		cafeteriaRegistration?: {
			__typename?: "CafeteriaRegistrationLog"
			_id: string
			studentId: string
			schoolSessionId: string
			since: any
			until: any
			createdAt: any
			updatedAt: any
		} | null
		upcomingCafeteriaRegistration?: {
			__typename?: "CafeteriaRegistrationLog"
			_id: string
			studentId: string
			schoolSessionId: string
			since: any
			until: any
			createdAt: any
			updatedAt: any
		} | null
	} | null
}

export type MyClassTeacherQueryVariables = Exact<{ [key: string]: never }>

export type MyClassTeacherQuery = {
	__typename?: "Query"
	myClassTeacher: {
		__typename?: "ExtendedStaff"
		_id: string
		name: string
		email?: string | null
		phone?: string | null
		username: string
		staffId: string
		designation: string
		departmentId: string
		tagId?: string | null
		gender?: Genders | null
		dateOfBirth?: any | null
		address?: string | null
		bloodGroup?: BloodGroups | null
		subjectIds?: Array<string> | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
		department: {
			__typename?: "Department"
			_id: string
			name: string
			type: DepartmentTypes
			createdAt: any
			updatedAt: any
			attendanceTiming: { __typename?: "StaffAttendanceTiming"; inAt: string; inStartBeforeMinutes: number; outAt: string; outStartBeforeMinutes: number }
		}
		tag?: {
			__typename?: "Tag"
			_id: string
			tagHexId: string
			tagDecimalId: string
			expiryAt: any
			isBlockedForPaytank?: boolean | null
			createdAt: any
			updatedAt: any
		} | null
		subjects: Array<{ __typename?: "Subject"; _id: string; name: string; createdAt: any; updatedAt: any }>
		roles?: Array<{
			__typename?: "ExtendedStaffRole"
			role: StaffRoles
			classId?: string | null
			classSectionId?: string | null
			assignedAt: any
			removedAt?: any | null
			class?: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any } | null
			classSection?: {
				__typename?: "ClassSection"
				_id: string
				classId: string
				name: string
				classTeacherId?: string | null
				createdAt: any
				updatedAt: any
			} | null
		}> | null
		pastRoles?: Array<{
			__typename?: "ExtendedStaffRole"
			role: StaffRoles
			classId?: string | null
			classSectionId?: string | null
			assignedAt: any
			removedAt?: any | null
			class?: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any } | null
			classSection?: {
				__typename?: "ClassSection"
				_id: string
				classId: string
				name: string
				classTeacherId?: string | null
				createdAt: any
				updatedAt: any
			} | null
		}> | null
	}
}

export type MyFeedPostsQueryVariables = Exact<{
	pagination: PaginatedInput
}>

export type MyFeedPostsQuery = {
	__typename?: "Query"
	myFeedPosts: {
		__typename?: "PaginatedFeedPostsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		feedPosts?: Array<{
			__typename?: "ExtendedFeedPost"
			_id: string
			text?: string | null
			isApproved?: boolean | null
			isLiked?: boolean | null
			createdAt: any
			updatedAt: any
			author: {
				__typename?: "ExtendedAuthor"
				authorType: AuthorTypes
				studentId?: string | null
				staffId?: string | null
				schoolId?: string | null
				student?: {
					__typename?: "Student"
					_id: string
					name: string
					username: string
					email?: string | null
					phone: string
					admissionId: string
					admissionDate?: any | null
					admissionType?: AdmissionTypes | null
					classId: string
					classSectionId: string
					tagId?: string | null
					houseId?: string | null
					isALS?: boolean | null
					isELS?: boolean | null
					status?: string | null
					nationality?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					isWhitelisted?: boolean | null
					transportMode?: string | null
					busRouteId?: string | null
					pointsBalance?: number | null
					paytankBalance?: number | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
					father?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					mother?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					guardian1?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					guardian2?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
				} | null
				staff?: {
					__typename?: "Staff"
					_id: string
					name: string
					email?: string | null
					phone?: string | null
					staffId: string
					designation: string
					username: string
					departmentId: string
					tagId?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					subjectIds?: Array<string> | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				school?: {
					__typename?: "School"
					_id: string
					name: string
					schoolCode: string
					affiliationCode: string
					email: string
					phone: string
					createdAt: any
					updatedAt: any
					location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
					cover?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			}
			links?: { __typename?: "Links"; youtube?: Array<string> | null; custom?: Array<string> | null } | null
			attachments?: {
				__typename?: "MediaAttachments"
				pictures?: Array<{
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				}> | null
				videos?: Array<{
					__typename?: "EmbeddedVideo"
					key: string
					url: string
					thumbnail?: string | null
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
				}> | null
				pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
			} | null
			likes?: Array<{
				__typename?: "FeedPostLike"
				createdAt: any
				updatedAt: any
				author: { __typename?: "Author"; authorType: AuthorTypes; studentId?: string | null; staffId?: string | null; schoolId?: string | null }
			}> | null
		}> | null
	}
}

export type MyPenaltiesQueryVariables = Exact<{ [key: string]: never }>

export type MyPenaltiesQuery = {
	__typename?: "Query"
	myPenalties: Array<{
		__typename?: "ExtendedPenalty"
		_id: string
		penaltyItemIds: Array<string>
		totalPoints: number
		createdAt: any
		updatedAt: any
		from: {
			__typename?: "ExtendedAuthor"
			authorType: AuthorTypes
			studentId?: string | null
			staffId?: string | null
			schoolId?: string | null
			student?: {
				__typename?: "Student"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				pointsBalance?: number | null
				paytankBalance?: number | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				father?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				mother?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian1?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian2?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			} | null
			staff?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			school?: {
				__typename?: "School"
				_id: string
				name: string
				schoolCode: string
				affiliationCode: string
				email: string
				phone: string
				createdAt: any
				updatedAt: any
				location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				cover?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}
		to: Array<{
			__typename?: "ExtendedAuthor"
			authorType: AuthorTypes
			studentId?: string | null
			staffId?: string | null
			schoolId?: string | null
			student?: {
				__typename?: "Student"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				pointsBalance?: number | null
				paytankBalance?: number | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				father?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				mother?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian1?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian2?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			} | null
			staff?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			school?: {
				__typename?: "School"
				_id: string
				name: string
				schoolCode: string
				affiliationCode: string
				email: string
				phone: string
				createdAt: any
				updatedAt: any
				location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				cover?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}>
		penaltyItems: Array<{
			__typename?: "ExtendedPenaltyItem"
			_id: string
			name: string
			description: string
			points: number
			categoryId: string
			createdAt: any
			updatedAt: any
			category: { __typename?: "PenaltyCategory"; _id: string; name: string; description: string; createdAt: any; updatedAt: any }
		}>
	}>
}

export type MyPointsRedeemTransactionsQueryVariables = Exact<{ [key: string]: never }>

export type MyPointsRedeemTransactionsQuery = {
	__typename?: "Query"
	myPointsRedeemTransactions: Array<{
		__typename?: "ExtendedPointsRedeemTransaction"
		_id: string
		pointsRedeemItemId: string
		isApproved?: boolean | null
		approvedAt?: any | null
		isRejected?: boolean | null
		rejectedAt?: any | null
		remarks?: string | null
		createdAt: any
		updatedAt: any
		author: {
			__typename?: "ExtendedAuthor"
			authorType: AuthorTypes
			studentId?: string | null
			staffId?: string | null
			schoolId?: string | null
			student?: {
				__typename?: "Student"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				pointsBalance?: number | null
				paytankBalance?: number | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				father?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				mother?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian1?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian2?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			} | null
			staff?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			school?: {
				__typename?: "School"
				_id: string
				name: string
				schoolCode: string
				affiliationCode: string
				email: string
				phone: string
				createdAt: any
				updatedAt: any
				location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				cover?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}
		pointsRedeemItem: { __typename?: "PointsRedeemItem"; _id: string; name: string; description: string; points: number; createdAt: any; updatedAt: any }
	}>
}

export type MyRewardsQueryVariables = Exact<{ [key: string]: never }>

export type MyRewardsQuery = {
	__typename?: "Query"
	myRewards: Array<{
		__typename?: "ExtendedReward"
		_id: string
		rewardItemIds: Array<string>
		totalPoints: number
		createdAt: any
		updatedAt: any
		from: {
			__typename?: "ExtendedAuthor"
			authorType: AuthorTypes
			studentId?: string | null
			staffId?: string | null
			schoolId?: string | null
			student?: {
				__typename?: "Student"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				pointsBalance?: number | null
				paytankBalance?: number | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				father?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				mother?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian1?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian2?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			} | null
			staff?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			school?: {
				__typename?: "School"
				_id: string
				name: string
				schoolCode: string
				affiliationCode: string
				email: string
				phone: string
				createdAt: any
				updatedAt: any
				location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				cover?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}
		to: Array<{
			__typename?: "ExtendedAuthor"
			authorType: AuthorTypes
			studentId?: string | null
			staffId?: string | null
			schoolId?: string | null
			student?: {
				__typename?: "Student"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				pointsBalance?: number | null
				paytankBalance?: number | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				father?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				mother?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian1?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian2?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			} | null
			staff?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			school?: {
				__typename?: "School"
				_id: string
				name: string
				schoolCode: string
				affiliationCode: string
				email: string
				phone: string
				createdAt: any
				updatedAt: any
				location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				cover?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}>
		rewardItems: Array<{
			__typename?: "ExtendedRewardItem"
			_id: string
			name: string
			description: string
			points: number
			categoryId: string
			createdAt: any
			updatedAt: any
			category: { __typename?: "RewardCategory"; _id: string; name: string; description: string; createdAt: any; updatedAt: any }
		}>
	}>
}

export type MySchoolQueryVariables = Exact<{ [key: string]: never }>

export type MySchoolQuery = {
	__typename?: "Query"
	mySchool?: {
		__typename?: "School"
		_id: string
		name: string
		schoolCode: string
		affiliationCode: string
		email: string
		phone: string
		createdAt: any
		updatedAt: any
		location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
		cover?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
	} | null
}

export type ResourceByIdQueryVariables = Exact<{
	resourceId: Scalars["String"]
}>

export type ResourceByIdQuery = {
	__typename?: "Query"
	resourceById: {
		__typename?: "ExtendedResource"
		_id: string
		targets: Array<UserTargets>
		name: string
		description: string
		classId: string
		classSectionId?: string | null
		subjectId: string
		topicId: string
		subTopic?: string | null
		createdAt: any
		updatedAt: any
		author: {
			__typename?: "ExtendedAuthor"
			authorType: AuthorTypes
			studentId?: string | null
			staffId?: string | null
			schoolId?: string | null
			student?: {
				__typename?: "Student"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				pointsBalance?: number | null
				paytankBalance?: number | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				father?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				mother?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian1?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian2?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			} | null
			staff?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			school?: {
				__typename?: "School"
				_id: string
				name: string
				schoolCode: string
				affiliationCode: string
				email: string
				phone: string
				createdAt: any
				updatedAt: any
				location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				cover?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}
		class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
		classSection?: {
			__typename?: "ClassSection"
			_id: string
			classId: string
			name: string
			classTeacherId?: string | null
			createdAt: any
			updatedAt: any
		} | null
		subject: { __typename?: "Subject"; _id: string; name: string; createdAt: any; updatedAt: any }
		topic: { __typename?: "Topic"; _id: string; name: string; subTopics?: Array<string> | null; createdAt: any; updatedAt: any }
		attachments: {
			__typename?: "MediaAttachments"
			pictures?: Array<{
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			}> | null
			videos?: Array<{
				__typename?: "EmbeddedVideo"
				key: string
				url: string
				thumbnail?: string | null
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
			}> | null
			pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
		}
	}
}

export type SchoolStatisticsQueryVariables = Exact<{ [key: string]: never }>

export type SchoolStatisticsQuery = {
	__typename?: "Query"
	schoolStatistics: {
		__typename?: "StatisticsResponse"
		students?: number | null
		nonTeachingStaff?: number | null
		teachingStaff?: number | null
		classes?: number | null
		subjects?: number | null
	}
}

export type SearchCircularQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type SearchCircularQuery = {
	__typename?: "Query"
	searchCircular: {
		__typename?: "PaginatedCircularsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		circulars?: Array<{
			__typename?: "Circular"
			_id: string
			title: string
			message: string
			targets: Array<UserTargets>
			createdAt: any
			updatedAt: any
		}> | null
	}
}

export type SearchGalleryQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type SearchGalleryQuery = {
	__typename?: "Query"
	searchGallery: {
		__typename?: "PaginatedGalleryResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		gallery?: Array<{
			__typename?: "Gallery"
			_id: string
			title: string
			description: string
			createdAt: any
			updatedAt: any
			attachments: {
				__typename?: "MediaAttachments"
				pictures?: Array<{
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				}> | null
				videos?: Array<{
					__typename?: "EmbeddedVideo"
					key: string
					url: string
					thumbnail?: string | null
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
				}> | null
				pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
			}
		}> | null
	}
}

export type SearchResourceQueryVariables = Exact<{
	keyword: Scalars["String"]
	classId?: InputMaybe<Scalars["String"]>
	classSectionId?: InputMaybe<Scalars["String"]>
	subjectId?: InputMaybe<Scalars["String"]>
	topicId?: InputMaybe<Scalars["String"]>
	subTopic?: InputMaybe<Scalars["String"]>
}>

export type SearchResourceQuery = {
	__typename?: "Query"
	searchResource: Array<{
		__typename?: "ExtendedResource"
		_id: string
		targets: Array<UserTargets>
		name: string
		description: string
		classId: string
		classSectionId?: string | null
		subjectId: string
		topicId: string
		subTopic?: string | null
		createdAt: any
		updatedAt: any
		author: {
			__typename?: "ExtendedAuthor"
			authorType: AuthorTypes
			studentId?: string | null
			staffId?: string | null
			schoolId?: string | null
			student?: {
				__typename?: "Student"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				pointsBalance?: number | null
				paytankBalance?: number | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				father?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				mother?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian1?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian2?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			} | null
			staff?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			school?: {
				__typename?: "School"
				_id: string
				name: string
				schoolCode: string
				affiliationCode: string
				email: string
				phone: string
				createdAt: any
				updatedAt: any
				location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				cover?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}
		class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
		classSection?: {
			__typename?: "ClassSection"
			_id: string
			classId: string
			name: string
			classTeacherId?: string | null
			createdAt: any
			updatedAt: any
		} | null
		subject: { __typename?: "Subject"; _id: string; name: string; createdAt: any; updatedAt: any }
		topic: { __typename?: "Topic"; _id: string; name: string; subTopics?: Array<string> | null; createdAt: any; updatedAt: any }
		attachments: {
			__typename?: "MediaAttachments"
			pictures?: Array<{
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			}> | null
			videos?: Array<{
				__typename?: "EmbeddedVideo"
				key: string
				url: string
				thumbnail?: string | null
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "VideoVariants"; small?: string | null; large?: string | null } | null
			}> | null
			pdfs?: Array<{ __typename?: "EmbeddedPDF"; key: string; url: string; createdAt: any; updatedAt: any }> | null
		}
	}>
}

export type SearchStaffsQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type SearchStaffsQuery = {
	__typename?: "Query"
	searchStaffs: {
		__typename?: "PaginatedStaffResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		staffs?: Array<{
			__typename?: "ExtendedStaff"
			_id: string
			name: string
			email?: string | null
			phone?: string | null
			username: string
			staffId: string
			designation: string
			departmentId: string
			tagId?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			subjectIds?: Array<string> | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			department: {
				__typename?: "Department"
				_id: string
				name: string
				type: DepartmentTypes
				createdAt: any
				updatedAt: any
				attendanceTiming: {
					__typename?: "StaffAttendanceTiming"
					inAt: string
					inStartBeforeMinutes: number
					outAt: string
					outStartBeforeMinutes: number
				}
			}
			tag?: {
				__typename?: "Tag"
				_id: string
				tagHexId: string
				tagDecimalId: string
				expiryAt: any
				isBlockedForPaytank?: boolean | null
				createdAt: any
				updatedAt: any
			} | null
			subjects: Array<{ __typename?: "Subject"; _id: string; name: string; createdAt: any; updatedAt: any }>
			roles?: Array<{
				__typename?: "ExtendedStaffRole"
				role: StaffRoles
				classId?: string | null
				classSectionId?: string | null
				assignedAt: any
				removedAt?: any | null
				class?: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any } | null
				classSection?: {
					__typename?: "ClassSection"
					_id: string
					classId: string
					name: string
					classTeacherId?: string | null
					createdAt: any
					updatedAt: any
				} | null
			}> | null
			pastRoles?: Array<{
				__typename?: "ExtendedStaffRole"
				role: StaffRoles
				classId?: string | null
				classSectionId?: string | null
				assignedAt: any
				removedAt?: any | null
				class?: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any } | null
				classSection?: {
					__typename?: "ClassSection"
					_id: string
					classId: string
					name: string
					classTeacherId?: string | null
					createdAt: any
					updatedAt: any
				} | null
			}> | null
		}> | null
	}
}

export type SearchStudentsQueryVariables = Exact<{
	keyword: Scalars["String"]
	pagination: PaginatedInput
}>

export type SearchStudentsQuery = {
	__typename?: "Query"
	searchStudents: {
		__typename?: "PaginatedStudentsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		students?: Array<{
			__typename?: "ExtendedStudent"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
			classSection: {
				__typename?: "ClassSection"
				_id: string
				classId: string
				name: string
				classTeacherId?: string | null
				createdAt: any
				updatedAt: any
			}
			tag?: {
				__typename?: "Tag"
				_id: string
				tagHexId: string
				tagDecimalId: string
				expiryAt: any
				isBlockedForPaytank?: boolean | null
				createdAt: any
				updatedAt: any
			} | null
			house?: {
				__typename?: "House"
				_id: string
				name: string
				colorHex: string
				captainId?: string | null
				pointsBalance?: number | null
				createdAt: any
				updatedAt: any
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			busRoute?: {
				__typename?: "BusRoute"
				_id: string
				routeName: string
				vehicleNo?: string | null
				busDriverName: string
				busDriverPhone?: string | null
				busConductorName: string
				busConductorPhone?: string | null
				createdAt: any
				updatedAt: any
			} | null
			cafeteriaRegistration?: {
				__typename?: "CafeteriaRegistrationLog"
				_id: string
				studentId: string
				schoolSessionId: string
				since: any
				until: any
				createdAt: any
				updatedAt: any
			} | null
			upcomingCafeteriaRegistration?: {
				__typename?: "CafeteriaRegistrationLog"
				_id: string
				studentId: string
				schoolSessionId: string
				since: any
				until: any
				createdAt: any
				updatedAt: any
			} | null
		}> | null
	}
}

export type SearchTrendingSkillQueryVariables = Exact<{
	forYou: Scalars["Boolean"]
	keyword: Scalars["String"]
	topicId?: InputMaybe<Scalars["String"]>
	pagination: PaginatedInput
}>

export type SearchTrendingSkillQuery = {
	__typename?: "Query"
	searchTrendingSkill: {
		__typename?: "PaginatedTrendingSkillsResponse"
		hasPrevPage: boolean
		hasNextPage: boolean
		totalPages: number
		total: number
		currentPage: number
		trendingSkills?: Array<{
			__typename?: "ExtendedTrendingSkill"
			_id: string
			name: string
			description: string
			topicId: string
			minAge?: number | null
			maxAge?: number | null
			links: string
			createdAt: any
			updatedAt: any
			author: {
				__typename?: "ExtendedAuthor"
				authorType: AuthorTypes
				studentId?: string | null
				staffId?: string | null
				schoolId?: string | null
				student?: {
					__typename?: "Student"
					_id: string
					name: string
					username: string
					email?: string | null
					phone: string
					admissionId: string
					admissionDate?: any | null
					admissionType?: AdmissionTypes | null
					classId: string
					classSectionId: string
					tagId?: string | null
					houseId?: string | null
					isALS?: boolean | null
					isELS?: boolean | null
					status?: string | null
					nationality?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					isWhitelisted?: boolean | null
					transportMode?: string | null
					busRouteId?: string | null
					pointsBalance?: number | null
					paytankBalance?: number | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
					father?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					mother?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					guardian1?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
					guardian2?: {
						__typename?: "Parent"
						name?: string | null
						phone?: string | null
						email?: string | null
						nfcTagId?: string | null
						picture?: {
							__typename?: "EmbeddedPicture"
							key: string
							url: string
							createdAt: any
							updatedAt: any
							variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
						} | null
					} | null
				} | null
				staff?: {
					__typename?: "Staff"
					_id: string
					name: string
					email?: string | null
					phone?: string | null
					staffId: string
					designation: string
					username: string
					departmentId: string
					tagId?: string | null
					gender?: Genders | null
					dateOfBirth?: any | null
					address?: string | null
					bloodGroup?: BloodGroups | null
					subjectIds?: Array<string> | null
					createdAt: any
					updatedAt: any
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				school?: {
					__typename?: "School"
					_id: string
					name: string
					schoolCode: string
					affiliationCode: string
					email: string
					phone: string
					createdAt: any
					updatedAt: any
					location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
					cover?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			}
			topic: { __typename?: "Topic"; _id: string; name: string; subTopics?: Array<string> | null; createdAt: any; updatedAt: any }
		}> | null
	}
}

export type StaffByIdQueryVariables = Exact<{
	staffId: Scalars["String"]
}>

export type StaffByIdQuery = {
	__typename?: "Query"
	staffById: {
		__typename?: "ExtendedStaff"
		_id: string
		name: string
		email?: string | null
		phone?: string | null
		username: string
		staffId: string
		designation: string
		departmentId: string
		tagId?: string | null
		gender?: Genders | null
		dateOfBirth?: any | null
		address?: string | null
		bloodGroup?: BloodGroups | null
		subjectIds?: Array<string> | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
		department: {
			__typename?: "Department"
			_id: string
			name: string
			type: DepartmentTypes
			createdAt: any
			updatedAt: any
			attendanceTiming: { __typename?: "StaffAttendanceTiming"; inAt: string; inStartBeforeMinutes: number; outAt: string; outStartBeforeMinutes: number }
		}
		tag?: {
			__typename?: "Tag"
			_id: string
			tagHexId: string
			tagDecimalId: string
			expiryAt: any
			isBlockedForPaytank?: boolean | null
			createdAt: any
			updatedAt: any
		} | null
		subjects: Array<{ __typename?: "Subject"; _id: string; name: string; createdAt: any; updatedAt: any }>
		roles?: Array<{
			__typename?: "ExtendedStaffRole"
			role: StaffRoles
			classId?: string | null
			classSectionId?: string | null
			assignedAt: any
			removedAt?: any | null
			class?: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any } | null
			classSection?: {
				__typename?: "ClassSection"
				_id: string
				classId: string
				name: string
				classTeacherId?: string | null
				createdAt: any
				updatedAt: any
			} | null
		}> | null
		pastRoles?: Array<{
			__typename?: "ExtendedStaffRole"
			role: StaffRoles
			classId?: string | null
			classSectionId?: string | null
			assignedAt: any
			removedAt?: any | null
			class?: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any } | null
			classSection?: {
				__typename?: "ClassSection"
				_id: string
				classId: string
				name: string
				classTeacherId?: string | null
				createdAt: any
				updatedAt: any
			} | null
		}> | null
	}
}

export type StudentByIdQueryVariables = Exact<{
	studentId: Scalars["String"]
}>

export type StudentByIdQuery = {
	__typename?: "Query"
	studentById: {
		__typename?: "ExtendedStudent"
		_id: string
		name: string
		username: string
		email?: string | null
		phone: string
		admissionId: string
		admissionDate?: any | null
		admissionType?: AdmissionTypes | null
		classId: string
		classSectionId: string
		tagId?: string | null
		houseId?: string | null
		isALS?: boolean | null
		isELS?: boolean | null
		status?: string | null
		nationality?: string | null
		gender?: Genders | null
		dateOfBirth?: any | null
		address?: string | null
		bloodGroup?: BloodGroups | null
		isWhitelisted?: boolean | null
		transportMode?: string | null
		busRouteId?: string | null
		pointsBalance?: number | null
		paytankBalance?: number | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
		class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
		classSection: {
			__typename?: "ClassSection"
			_id: string
			classId: string
			name: string
			classTeacherId?: string | null
			createdAt: any
			updatedAt: any
		}
		tag?: {
			__typename?: "Tag"
			_id: string
			tagHexId: string
			tagDecimalId: string
			expiryAt: any
			isBlockedForPaytank?: boolean | null
			createdAt: any
			updatedAt: any
		} | null
		house?: {
			__typename?: "House"
			_id: string
			name: string
			colorHex: string
			captainId?: string | null
			pointsBalance?: number | null
			createdAt: any
			updatedAt: any
		} | null
		father?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		mother?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		guardian1?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		guardian2?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		busRoute?: {
			__typename?: "BusRoute"
			_id: string
			routeName: string
			vehicleNo?: string | null
			busDriverName: string
			busDriverPhone?: string | null
			busConductorName: string
			busConductorPhone?: string | null
			createdAt: any
			updatedAt: any
		} | null
		cafeteriaRegistration?: {
			__typename?: "CafeteriaRegistrationLog"
			_id: string
			studentId: string
			schoolSessionId: string
			since: any
			until: any
			createdAt: any
			updatedAt: any
		} | null
		upcomingCafeteriaRegistration?: {
			__typename?: "CafeteriaRegistrationLog"
			_id: string
			studentId: string
			schoolSessionId: string
			since: any
			until: any
			createdAt: any
			updatedAt: any
		} | null
	}
}

export type StudentsWithMostPointsQueryVariables = Exact<{ [key: string]: never }>

export type StudentsWithMostPointsQuery = {
	__typename?: "Query"
	studentsWithMostPoints: Array<{
		__typename?: "Student"
		_id: string
		name: string
		username: string
		email?: string | null
		phone: string
		admissionId: string
		admissionDate?: any | null
		admissionType?: AdmissionTypes | null
		classId: string
		classSectionId: string
		tagId?: string | null
		houseId?: string | null
		isALS?: boolean | null
		isELS?: boolean | null
		status?: string | null
		nationality?: string | null
		gender?: Genders | null
		dateOfBirth?: any | null
		address?: string | null
		bloodGroup?: BloodGroups | null
		isWhitelisted?: boolean | null
		transportMode?: string | null
		busRouteId?: string | null
		pointsBalance?: number | null
		paytankBalance?: number | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
		father?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		mother?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		guardian1?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		guardian2?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
	}>
}

export type TodayAttendanceOfMeQueryVariables = Exact<{ [key: string]: never }>

export type TodayAttendanceOfMeQuery = {
	__typename?: "Query"
	todayAttendanceOfMe?: {
		__typename?: "ExtendedStudentAttendance"
		_id: string
		studentId: string
		classId: string
		classSectionId: string
		inAt: any
		outAt: any
		rssi: number
		temperature: number
		isFinal?: boolean | null
		isInRecorded?: boolean | null
		isOutRecorded?: boolean | null
		isInManual?: boolean | null
		isOutManual?: boolean | null
		isLate?: boolean | null
		isAbsent?: boolean | null
		lateByMinutes?: number | null
		isAttendanceMarkedByClassTeacher?: boolean | null
		createdAt: any
		updatedAt: any
		student: {
			__typename?: "ExtendedStudent"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
			classSection: {
				__typename?: "ClassSection"
				_id: string
				classId: string
				name: string
				classTeacherId?: string | null
				createdAt: any
				updatedAt: any
			}
			tag?: {
				__typename?: "Tag"
				_id: string
				tagHexId: string
				tagDecimalId: string
				expiryAt: any
				isBlockedForPaytank?: boolean | null
				createdAt: any
				updatedAt: any
			} | null
			house?: {
				__typename?: "House"
				_id: string
				name: string
				colorHex: string
				captainId?: string | null
				pointsBalance?: number | null
				createdAt: any
				updatedAt: any
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			busRoute?: {
				__typename?: "BusRoute"
				_id: string
				routeName: string
				vehicleNo?: string | null
				busDriverName: string
				busDriverPhone?: string | null
				busConductorName: string
				busConductorPhone?: string | null
				createdAt: any
				updatedAt: any
			} | null
			cafeteriaRegistration?: {
				__typename?: "CafeteriaRegistrationLog"
				_id: string
				studentId: string
				schoolSessionId: string
				since: any
				until: any
				createdAt: any
				updatedAt: any
			} | null
			upcomingCafeteriaRegistration?: {
				__typename?: "CafeteriaRegistrationLog"
				_id: string
				studentId: string
				schoolSessionId: string
				since: any
				until: any
				createdAt: any
				updatedAt: any
			} | null
		}
		class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
		classSection: {
			__typename?: "ClassSection"
			_id: string
			classId: string
			name: string
			classTeacherId?: string | null
			createdAt: any
			updatedAt: any
		}
		timing: {
			__typename?: "AttendanceTiming"
			inAt: string
			inStartBeforeMinutes: number
			notifyTeacherAfterMinutes: number
			outAt: string
			outStartBeforeMinutes: number
		}
		attendanceMarkedBy?: {
			__typename?: "ExtendedAuthor"
			authorType: AuthorTypes
			studentId?: string | null
			staffId?: string | null
			schoolId?: string | null
			student?: {
				__typename?: "Student"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				pointsBalance?: number | null
				paytankBalance?: number | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				father?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				mother?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian1?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian2?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			} | null
			staff?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			school?: {
				__typename?: "School"
				_id: string
				name: string
				schoolCode: string
				affiliationCode: string
				email: string
				phone: string
				createdAt: any
				updatedAt: any
				location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				cover?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		} | null
	} | null
}

export type TodayBusAttendanceOfMeQueryVariables = Exact<{ [key: string]: never }>

export type TodayBusAttendanceOfMeQuery = {
	__typename?: "Query"
	todayBusAttendanceOfMe?: {
		__typename?: "StudentBusAttendance"
		_id: string
		studentId: string
		homePickupAt?: any | null
		schoolDropAt?: any | null
		schoolPickupAt?: any | null
		homeDropAt?: any | null
		createdAt: any
		updatedAt: any
	} | null
}

export type TrendingSkillByIdQueryVariables = Exact<{
	trendingSkillId: Scalars["String"]
}>

export type TrendingSkillByIdQuery = {
	__typename?: "Query"
	trendingSkillById: {
		__typename?: "ExtendedTrendingSkill"
		_id: string
		name: string
		description: string
		topicId: string
		minAge?: number | null
		maxAge?: number | null
		links: string
		createdAt: any
		updatedAt: any
		author: {
			__typename?: "ExtendedAuthor"
			authorType: AuthorTypes
			studentId?: string | null
			staffId?: string | null
			schoolId?: string | null
			student?: {
				__typename?: "Student"
				_id: string
				name: string
				username: string
				email?: string | null
				phone: string
				admissionId: string
				admissionDate?: any | null
				admissionType?: AdmissionTypes | null
				classId: string
				classSectionId: string
				tagId?: string | null
				houseId?: string | null
				isALS?: boolean | null
				isELS?: boolean | null
				status?: string | null
				nationality?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				isWhitelisted?: boolean | null
				transportMode?: string | null
				busRouteId?: string | null
				pointsBalance?: number | null
				paytankBalance?: number | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				father?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				mother?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian1?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
				guardian2?: {
					__typename?: "Parent"
					name?: string | null
					phone?: string | null
					email?: string | null
					nfcTagId?: string | null
					picture?: {
						__typename?: "EmbeddedPicture"
						key: string
						url: string
						createdAt: any
						updatedAt: any
						variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
					} | null
				} | null
			} | null
			staff?: {
				__typename?: "Staff"
				_id: string
				name: string
				email?: string | null
				phone?: string | null
				staffId: string
				designation: string
				username: string
				departmentId: string
				tagId?: string | null
				gender?: Genders | null
				dateOfBirth?: any | null
				address?: string | null
				bloodGroup?: BloodGroups | null
				subjectIds?: Array<string> | null
				createdAt: any
				updatedAt: any
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			school?: {
				__typename?: "School"
				_id: string
				name: string
				schoolCode: string
				affiliationCode: string
				email: string
				phone: string
				createdAt: any
				updatedAt: any
				location: { __typename?: "Location"; city: string; state: string; pin: number; address: string }
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
				cover?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}
		topic: { __typename?: "Topic"; _id: string; name: string; subTopics?: Array<string> | null; createdAt: any; updatedAt: any }
	}
}

export type MeUpdateSubscriptionVariables = Exact<{
	studentId: Scalars["String"]
}>

export type MeUpdateSubscription = {
	__typename?: "Subscription"
	meUpdate?: {
		__typename?: "ExtendedStudent"
		_id: string
		name: string
		username: string
		email?: string | null
		phone: string
		admissionId: string
		admissionDate?: any | null
		admissionType?: AdmissionTypes | null
		classId: string
		classSectionId: string
		tagId?: string | null
		houseId?: string | null
		isALS?: boolean | null
		isELS?: boolean | null
		status?: string | null
		nationality?: string | null
		gender?: Genders | null
		dateOfBirth?: any | null
		address?: string | null
		bloodGroup?: BloodGroups | null
		isWhitelisted?: boolean | null
		transportMode?: string | null
		busRouteId?: string | null
		pointsBalance?: number | null
		paytankBalance?: number | null
		createdAt: any
		updatedAt: any
		picture?: {
			__typename?: "EmbeddedPicture"
			key: string
			url: string
			createdAt: any
			updatedAt: any
			variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
		} | null
		class: { __typename?: "Class"; _id: string; name: string; createdAt: any; updatedAt: any }
		classSection: {
			__typename?: "ClassSection"
			_id: string
			classId: string
			name: string
			classTeacherId?: string | null
			createdAt: any
			updatedAt: any
		}
		tag?: {
			__typename?: "Tag"
			_id: string
			tagHexId: string
			tagDecimalId: string
			expiryAt: any
			isBlockedForPaytank?: boolean | null
			createdAt: any
			updatedAt: any
		} | null
		house?: {
			__typename?: "House"
			_id: string
			name: string
			colorHex: string
			captainId?: string | null
			pointsBalance?: number | null
			createdAt: any
			updatedAt: any
		} | null
		father?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		mother?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		guardian1?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		guardian2?: {
			__typename?: "Parent"
			name?: string | null
			phone?: string | null
			email?: string | null
			nfcTagId?: string | null
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
		} | null
		busRoute?: {
			__typename?: "BusRoute"
			_id: string
			routeName: string
			vehicleNo?: string | null
			busDriverName: string
			busDriverPhone?: string | null
			busConductorName: string
			busConductorPhone?: string | null
			createdAt: any
			updatedAt: any
		} | null
		cafeteriaRegistration?: {
			__typename?: "CafeteriaRegistrationLog"
			_id: string
			studentId: string
			schoolSessionId: string
			since: any
			until: any
			createdAt: any
			updatedAt: any
		} | null
		upcomingCafeteriaRegistration?: {
			__typename?: "CafeteriaRegistrationLog"
			_id: string
			studentId: string
			schoolSessionId: string
			since: any
			until: any
			createdAt: any
			updatedAt: any
		} | null
	} | null
}

export type MyPaytankOrderSubscriptionVariables = Exact<{
	studentId: Scalars["String"]
}>

export type MyPaytankOrderSubscription = {
	__typename?: "Subscription"
	myPaytankOrder?: {
		__typename?: "ExtendedPaytankOrder"
		_id: string
		type: PaytankOrderTypes
		merchantId?: string | null
		studentId: string
		amount: number
		description?: string | null
		status: PaymentOrderStatus
		failureReason?: PaytankFailureReasons | null
		orderId?: string | null
		paymentId?: string | null
		refundId?: string | null
		refundAmount?: number | null
		refundCreatedAt?: any | null
		refundProcessedAt?: any | null
		refundFailedAt?: any | null
		createdAt: any
		updatedAt: any
		merchant?: {
			__typename?: "PaytankMerchant"
			_id: string
			name: string
			description?: string | null
			username: string
			typeId: string
			createdAt: any
			updatedAt: any
		} | null
		student: {
			__typename?: "Student"
			_id: string
			name: string
			username: string
			email?: string | null
			phone: string
			admissionId: string
			admissionDate?: any | null
			admissionType?: AdmissionTypes | null
			classId: string
			classSectionId: string
			tagId?: string | null
			houseId?: string | null
			isALS?: boolean | null
			isELS?: boolean | null
			status?: string | null
			nationality?: string | null
			gender?: Genders | null
			dateOfBirth?: any | null
			address?: string | null
			bloodGroup?: BloodGroups | null
			isWhitelisted?: boolean | null
			transportMode?: string | null
			busRouteId?: string | null
			pointsBalance?: number | null
			paytankBalance?: number | null
			createdAt: any
			updatedAt: any
			picture?: {
				__typename?: "EmbeddedPicture"
				key: string
				url: string
				createdAt: any
				updatedAt: any
				variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
			} | null
			father?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			mother?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian1?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
			guardian2?: {
				__typename?: "Parent"
				name?: string | null
				phone?: string | null
				email?: string | null
				nfcTagId?: string | null
				picture?: {
					__typename?: "EmbeddedPicture"
					key: string
					url: string
					createdAt: any
					updatedAt: any
					variants?: { __typename?: "PictureVariants"; thumbnail?: string | null; small?: string | null; large?: string | null } | null
				} | null
			} | null
		}
	} | null
}

export type TodayAttendanceUpdateOfMeSubscriptionVariables = Exact<{
	studentId: Scalars["String"]
}>

export type TodayAttendanceUpdateOfMeSubscription = {
	__typename?: "Subscription"
	todayAttendanceUpdateOfMe: {
		__typename?: "StudentAttendance"
		_id: string
		studentId: string
		inAt: any
		outAt: any
		rssi: number
		temperature: number
		isFinal?: boolean | null
		isInRecorded?: boolean | null
		isOutRecorded?: boolean | null
		isInManual?: boolean | null
		isOutManual?: boolean | null
		isLate?: boolean | null
		lateByMinutes?: number | null
		isAttendanceMarkedByClassTeacher?: boolean | null
		createdAt: any
		updatedAt: any
		timing: {
			__typename?: "AttendanceTiming"
			inAt: string
			inStartBeforeMinutes: number
			notifyTeacherAfterMinutes: number
			outAt: string
			outStartBeforeMinutes: number
		}
	}
}

export type TodayBusAttendanceUpdateOfMeSubscriptionVariables = Exact<{
	studentId: Scalars["String"]
}>

export type TodayBusAttendanceUpdateOfMeSubscription = {
	__typename?: "Subscription"
	todayBusAttendanceUpdateOfMe: {
		__typename?: "StudentBusAttendance"
		_id: string
		studentId: string
		homePickupAt?: any | null
		schoolDropAt?: any | null
		schoolPickupAt?: any | null
		homeDropAt?: any | null
		createdAt: any
		updatedAt: any
	}
}

export const CafeteriaRegistrationOrderFragmentDoc = gql`
	fragment CafeteriaRegistrationOrder on CafeteriaRegistrationOrder {
		_id
		studentId
		amount
		status
		failureReason
		quoteId
		orderId
		paymentId
		createdAt
		updatedAt
	}
`
export const AmountBreakupFragmentDoc = gql`
	fragment AmountBreakup on AmountBreakup {
		label
		amount
		payable
	}
`
export const CafeteriaRegistrationSessionQuoteFragmentDoc = gql`
	fragment CafeteriaRegistrationSessionQuote on CafeteriaRegistrationSessionQuote {
		schoolSessionId
		schoolSessionStartAt
		schoolSessionEndAt
		totalAmount
		breakup {
			...AmountBreakup
		}
	}
	${AmountBreakupFragmentDoc}
`
export const CafeteriaRegistrationQuoteFragmentDoc = gql`
	fragment CafeteriaRegistrationQuote on CafeteriaRegistrationQuote {
		_id
		studentId
		sessionWiseQuotes {
			...CafeteriaRegistrationSessionQuote
		}
		totalAmount
		createdAt
		updatedAt
	}
	${CafeteriaRegistrationSessionQuoteFragmentDoc}
`
export const CircularFragmentDoc = gql`
	fragment Circular on Circular {
		_id
		title
		message
		targets
		createdAt
		updatedAt
	}
`
export const EmbeddedPictureFragmentDoc = gql`
	fragment EmbeddedPicture on EmbeddedPicture {
		key
		url
		variants {
			thumbnail
			small
			large
		}
		createdAt
		updatedAt
	}
`
export const StaffFragmentDoc = gql`
	fragment Staff on Staff {
		_id
		name
		email
		phone
		picture {
			...EmbeddedPicture
		}
		staffId
		designation
		username
		departmentId
		tagId
		gender
		dateOfBirth
		address
		bloodGroup
		subjectIds
		createdAt
		updatedAt
	}
	${EmbeddedPictureFragmentDoc}
`
export const ExtendedClassSectionFragmentDoc = gql`
	fragment ExtendedClassSection on ExtendedClassSection {
		_id
		classId
		name
		classTeacherId
		classTeacher {
			...Staff
		}
		createdAt
		updatedAt
	}
	${StaffFragmentDoc}
`
export const ExtendedClassFragmentDoc = gql`
	fragment ExtendedClass on ExtendedClass {
		_id
		name
		sections {
			...ExtendedClassSection
		}
		createdAt
		updatedAt
	}
	${ExtendedClassSectionFragmentDoc}
`
export const ParentFragmentDoc = gql`
	fragment Parent on Parent {
		name
		phone
		email
		picture {
			...EmbeddedPicture
		}
		nfcTagId
	}
	${EmbeddedPictureFragmentDoc}
`
export const StudentFragmentDoc = gql`
	fragment Student on Student {
		_id
		name
		username
		email
		phone
		admissionId
		admissionDate
		admissionType
		picture {
			...EmbeddedPicture
		}
		classId
		classSectionId
		tagId
		houseId
		isALS
		isELS
		status
		nationality
		gender
		dateOfBirth
		address
		bloodGroup
		father {
			...Parent
		}
		mother {
			...Parent
		}
		guardian1 {
			...Parent
		}
		guardian2 {
			...Parent
		}
		isWhitelisted
		transportMode
		busRouteId
		pointsBalance
		paytankBalance
		createdAt
		updatedAt
	}
	${EmbeddedPictureFragmentDoc}
	${ParentFragmentDoc}
`
export const SchoolFragmentDoc = gql`
	fragment School on School {
		_id
		name
		schoolCode
		affiliationCode
		email
		phone
		location {
			city
			state
			pin
			address
		}
		picture {
			...EmbeddedPicture
		}
		cover {
			...EmbeddedPicture
		}
		createdAt
		updatedAt
	}
	${EmbeddedPictureFragmentDoc}
`
export const ExtendedAuthorFragmentDoc = gql`
	fragment ExtendedAuthor on ExtendedAuthor {
		authorType
		studentId
		student {
			...Student
		}
		staffId
		staff {
			...Staff
		}
		schoolId
		school {
			...School
		}
	}
	${StudentFragmentDoc}
	${StaffFragmentDoc}
	${SchoolFragmentDoc}
`
export const LinksFragmentDoc = gql`
	fragment Links on Links {
		youtube
		custom
	}
`
export const EmbeddedVideoFragmentDoc = gql`
	fragment EmbeddedVideo on EmbeddedVideo {
		key
		url
		thumbnail
		variants {
			small
			large
		}
		createdAt
		updatedAt
	}
`
export const EmbeddedPdfFragmentDoc = gql`
	fragment EmbeddedPDF on EmbeddedPDF {
		key
		url
		createdAt
		updatedAt
	}
`
export const MediaAttachmentsFragmentDoc = gql`
	fragment MediaAttachments on MediaAttachments {
		pictures {
			...EmbeddedPicture
		}
		videos {
			...EmbeddedVideo
		}
		pdfs {
			...EmbeddedPDF
		}
	}
	${EmbeddedPictureFragmentDoc}
	${EmbeddedVideoFragmentDoc}
	${EmbeddedPdfFragmentDoc}
`
export const AuthorFragmentDoc = gql`
	fragment Author on Author {
		authorType
		studentId
		staffId
		schoolId
	}
`
export const FeedPostLikeFragmentDoc = gql`
	fragment FeedPostLike on FeedPostLike {
		author {
			...Author
		}
		createdAt
		updatedAt
	}
	${AuthorFragmentDoc}
`
export const ExtendedFeedPostFragmentDoc = gql`
	fragment ExtendedFeedPost on ExtendedFeedPost {
		_id
		author {
			...ExtendedAuthor
		}
		text
		links {
			...Links
		}
		attachments {
			...MediaAttachments
		}
		likes {
			...FeedPostLike
		}
		isApproved
		isLiked
		createdAt
		updatedAt
	}
	${ExtendedAuthorFragmentDoc}
	${LinksFragmentDoc}
	${MediaAttachmentsFragmentDoc}
	${FeedPostLikeFragmentDoc}
`
export const PaytankMerchantFragmentDoc = gql`
	fragment PaytankMerchant on PaytankMerchant {
		_id
		name
		description
		username
		typeId
		createdAt
		updatedAt
	}
`
export const ExtendedPaytankOrderFragmentDoc = gql`
	fragment ExtendedPaytankOrder on ExtendedPaytankOrder {
		_id
		type
		merchantId
		merchant {
			...PaytankMerchant
		}
		studentId
		student {
			...Student
		}
		amount
		description
		status
		failureReason
		orderId
		paymentId
		refundId
		refundAmount
		refundCreatedAt
		refundProcessedAt
		refundFailedAt
		createdAt
		updatedAt
	}
	${PaytankMerchantFragmentDoc}
	${StudentFragmentDoc}
`
export const PenaltyCategoryFragmentDoc = gql`
	fragment PenaltyCategory on PenaltyCategory {
		_id
		name
		description
		createdAt
		updatedAt
	}
`
export const ExtendedPenaltyItemFragmentDoc = gql`
	fragment ExtendedPenaltyItem on ExtendedPenaltyItem {
		_id
		name
		description
		points
		categoryId
		category {
			...PenaltyCategory
		}
		createdAt
		updatedAt
	}
	${PenaltyCategoryFragmentDoc}
`
export const ExtendedPenaltyFragmentDoc = gql`
	fragment ExtendedPenalty on ExtendedPenalty {
		_id
		from {
			...ExtendedAuthor
		}
		to {
			...ExtendedAuthor
		}
		penaltyItemIds
		penaltyItems {
			...ExtendedPenaltyItem
		}
		totalPoints
		createdAt
		updatedAt
	}
	${ExtendedAuthorFragmentDoc}
	${ExtendedPenaltyItemFragmentDoc}
`
export const PointsRedeemItemFragmentDoc = gql`
	fragment PointsRedeemItem on PointsRedeemItem {
		_id
		name
		description
		points
		createdAt
		updatedAt
	}
`
export const ExtendedPointsRedeemTransactionFragmentDoc = gql`
	fragment ExtendedPointsRedeemTransaction on ExtendedPointsRedeemTransaction {
		_id
		author {
			...ExtendedAuthor
		}
		pointsRedeemItemId
		pointsRedeemItem {
			...PointsRedeemItem
		}
		isApproved
		approvedAt
		isRejected
		rejectedAt
		remarks
		createdAt
		updatedAt
	}
	${ExtendedAuthorFragmentDoc}
	${PointsRedeemItemFragmentDoc}
`
export const ClassFragmentDoc = gql`
	fragment Class on Class {
		_id
		name
		createdAt
		updatedAt
	}
`
export const ClassSectionFragmentDoc = gql`
	fragment ClassSection on ClassSection {
		_id
		classId
		name
		classTeacherId
		createdAt
		updatedAt
	}
`
export const SubjectFragmentDoc = gql`
	fragment Subject on Subject {
		_id
		name
		createdAt
		updatedAt
	}
`
export const TopicFragmentDoc = gql`
	fragment Topic on Topic {
		_id
		name
		subTopics
		createdAt
		updatedAt
	}
`
export const ExtendedResourceFragmentDoc = gql`
	fragment ExtendedResource on ExtendedResource {
		_id
		author {
			...ExtendedAuthor
		}
		targets
		name
		description
		classId
		class {
			...Class
		}
		classSectionId
		classSection {
			...ClassSection
		}
		subjectId
		subject {
			...Subject
		}
		topicId
		topic {
			...Topic
		}
		subTopic
		attachments {
			...MediaAttachments
		}
		createdAt
		updatedAt
	}
	${ExtendedAuthorFragmentDoc}
	${ClassFragmentDoc}
	${ClassSectionFragmentDoc}
	${SubjectFragmentDoc}
	${TopicFragmentDoc}
	${MediaAttachmentsFragmentDoc}
`
export const RewardCategoryFragmentDoc = gql`
	fragment RewardCategory on RewardCategory {
		_id
		name
		description
		createdAt
		updatedAt
	}
`
export const ExtendedRewardItemFragmentDoc = gql`
	fragment ExtendedRewardItem on ExtendedRewardItem {
		_id
		name
		description
		points
		categoryId
		category {
			...RewardCategory
		}
		createdAt
		updatedAt
	}
	${RewardCategoryFragmentDoc}
`
export const ExtendedRewardFragmentDoc = gql`
	fragment ExtendedReward on ExtendedReward {
		_id
		from {
			...ExtendedAuthor
		}
		to {
			...ExtendedAuthor
		}
		rewardItemIds
		rewardItems {
			...ExtendedRewardItem
		}
		totalPoints
		createdAt
		updatedAt
	}
	${ExtendedAuthorFragmentDoc}
	${ExtendedRewardItemFragmentDoc}
`
export const StaffAttendanceTimingFragmentDoc = gql`
	fragment StaffAttendanceTiming on StaffAttendanceTiming {
		inAt
		inStartBeforeMinutes
		outAt
		outStartBeforeMinutes
	}
`
export const DepartmentFragmentDoc = gql`
	fragment Department on Department {
		_id
		name
		type
		attendanceTiming {
			...StaffAttendanceTiming
		}
		createdAt
		updatedAt
	}
	${StaffAttendanceTimingFragmentDoc}
`
export const TagFragmentDoc = gql`
	fragment Tag on Tag {
		_id
		tagHexId
		tagDecimalId
		expiryAt
		isBlockedForPaytank
		createdAt
		updatedAt
	}
`
export const ExtendedStaffRoleFragmentDoc = gql`
	fragment ExtendedStaffRole on ExtendedStaffRole {
		role
		classId
		class {
			...Class
		}
		classSectionId
		classSection {
			...ClassSection
		}
		assignedAt
		removedAt
	}
	${ClassFragmentDoc}
	${ClassSectionFragmentDoc}
`
export const ExtendedStaffFragmentDoc = gql`
	fragment ExtendedStaff on ExtendedStaff {
		_id
		name
		email
		phone
		picture {
			...EmbeddedPicture
		}
		username
		staffId
		designation
		departmentId
		department {
			...Department
		}
		tagId
		tag {
			...Tag
		}
		gender
		dateOfBirth
		address
		bloodGroup
		subjectIds
		subjects {
			...Subject
		}
		roles {
			...ExtendedStaffRole
		}
		pastRoles {
			...ExtendedStaffRole
		}
		createdAt
		updatedAt
	}
	${EmbeddedPictureFragmentDoc}
	${DepartmentFragmentDoc}
	${TagFragmentDoc}
	${SubjectFragmentDoc}
	${ExtendedStaffRoleFragmentDoc}
`
export const HouseFragmentDoc = gql`
	fragment House on House {
		_id
		name
		colorHex
		captainId
		pointsBalance
		createdAt
		updatedAt
	}
`
export const BusRouteFragmentDoc = gql`
	fragment BusRoute on BusRoute {
		_id
		routeName
		vehicleNo
		busDriverName
		busDriverPhone
		busConductorName
		busConductorPhone
		createdAt
		updatedAt
	}
`
export const CafeteriaRegistrationLogFragmentDoc = gql`
	fragment CafeteriaRegistrationLog on CafeteriaRegistrationLog {
		_id
		studentId
		schoolSessionId
		since
		until
		createdAt
		updatedAt
	}
`
export const ExtendedStudentFragmentDoc = gql`
	fragment ExtendedStudent on ExtendedStudent {
		_id
		name
		username
		email
		phone
		admissionId
		admissionDate
		admissionType
		picture {
			...EmbeddedPicture
		}
		classId
		class {
			...Class
		}
		classSectionId
		classSection {
			...ClassSection
		}
		tagId
		tag {
			...Tag
		}
		houseId
		house {
			...House
		}
		isALS
		isELS
		status
		nationality
		gender
		dateOfBirth
		address
		bloodGroup
		father {
			...Parent
		}
		mother {
			...Parent
		}
		guardian1 {
			...Parent
		}
		guardian2 {
			...Parent
		}
		isWhitelisted
		transportMode
		busRouteId
		busRoute {
			...BusRoute
		}
		pointsBalance
		paytankBalance
		cafeteriaRegistration {
			...CafeteriaRegistrationLog
		}
		upcomingCafeteriaRegistration {
			...CafeteriaRegistrationLog
		}
		createdAt
		updatedAt
	}
	${EmbeddedPictureFragmentDoc}
	${ClassFragmentDoc}
	${ClassSectionFragmentDoc}
	${TagFragmentDoc}
	${HouseFragmentDoc}
	${ParentFragmentDoc}
	${BusRouteFragmentDoc}
	${CafeteriaRegistrationLogFragmentDoc}
`
export const AttendanceTimingFragmentDoc = gql`
	fragment AttendanceTiming on AttendanceTiming {
		inAt
		inStartBeforeMinutes
		notifyTeacherAfterMinutes
		outAt
		outStartBeforeMinutes
	}
`
export const ExtendedStudentAttendanceFragmentDoc = gql`
	fragment ExtendedStudentAttendance on ExtendedStudentAttendance {
		_id
		studentId
		student {
			...ExtendedStudent
		}
		classId
		class {
			...Class
		}
		classSectionId
		classSection {
			...ClassSection
		}
		inAt
		outAt
		rssi
		temperature
		isFinal
		isInRecorded
		isOutRecorded
		isInManual
		isOutManual
		timing {
			...AttendanceTiming
		}
		isLate
		isAbsent
		lateByMinutes
		attendanceMarkedBy {
			...ExtendedAuthor
		}
		isAttendanceMarkedByClassTeacher
		createdAt
		updatedAt
	}
	${ExtendedStudentFragmentDoc}
	${ClassFragmentDoc}
	${ClassSectionFragmentDoc}
	${AttendanceTimingFragmentDoc}
	${ExtendedAuthorFragmentDoc}
`
export const ExtendedTrendingSkillFragmentDoc = gql`
	fragment ExtendedTrendingSkill on ExtendedTrendingSkill {
		_id
		author {
			...ExtendedAuthor
		}
		name
		description
		topicId
		topic {
			...Topic
		}
		minAge
		maxAge
		links
		createdAt
		updatedAt
	}
	${ExtendedAuthorFragmentDoc}
	${TopicFragmentDoc}
`
export const FieldErrorFragmentDoc = gql`
	fragment FieldError on FieldError {
		field
		error
	}
`
export const GalleryFragmentDoc = gql`
	fragment Gallery on Gallery {
		_id
		title
		description
		attachments {
			...MediaAttachments
		}
		createdAt
		updatedAt
	}
	${MediaAttachmentsFragmentDoc}
`
export const HolidayFragmentDoc = gql`
	fragment Holiday on Holiday {
		_id
		name
		eventType
		startDate
		endDate
		createdAt
		updatedAt
	}
`
export const PointsRedeemTransactionFragmentDoc = gql`
	fragment PointsRedeemTransaction on PointsRedeemTransaction {
		_id
		author {
			...Author
		}
		pointsRedeemItemId
		isApproved
		approvedAt
		isRejected
		rejectedAt
		remarks
		createdAt
		updatedAt
	}
	${AuthorFragmentDoc}
`
export const SchoolSessionFragmentDoc = gql`
	fragment SchoolSession on SchoolSession {
		_id
		name
		startMonth
		startDate
		endMonth
		endDate
		createdAt
		updatedAt
	}
`
export const StudentAttendanceFragmentDoc = gql`
	fragment StudentAttendance on StudentAttendance {
		_id
		studentId
		inAt
		outAt
		rssi
		temperature
		isFinal
		isInRecorded
		isOutRecorded
		isInManual
		isOutManual
		timing {
			...AttendanceTiming
		}
		isLate
		lateByMinutes
		isAttendanceMarkedByClassTeacher
		createdAt
		updatedAt
	}
	${AttendanceTimingFragmentDoc}
`
export const StudentBusAttendanceFragmentDoc = gql`
	fragment StudentBusAttendance on StudentBusAttendance {
		_id
		studentId
		homePickupAt
		schoolDropAt
		schoolPickupAt
		homeDropAt
		createdAt
		updatedAt
	}
`
export const BlockTagForPaytankDocument = gql`
	mutation BlockTagForPaytank {
		blockTagForPaytank
	}
`

export function useBlockTagForPaytankMutation() {
	return Urql.useMutation<BlockTagForPaytankMutation, BlockTagForPaytankMutationVariables>(BlockTagForPaytankDocument)
}
export const ChangePasswordDocument = gql`
	mutation ChangePassword($currentPassword: String!, $newPassword: String!, $shouldLogOutOnOtherDevices: Boolean) {
		changePassword(currentPassword: $currentPassword, newPassword: $newPassword, shouldLogOutOnOtherDevices: $shouldLogOutOnOtherDevices)
	}
`

export function useChangePasswordMutation() {
	return Urql.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument)
}
export const CreateAddPaytankFundsOrderDocument = gql`
	mutation createAddPaytankFundsOrder($amount: Float!, $description: String) {
		createAddPaytankFundsOrder(amount: $amount, description: $description) {
			...ExtendedPaytankOrder
		}
	}
	${ExtendedPaytankOrderFragmentDoc}
`

export function useCreateAddPaytankFundsOrderMutation() {
	return Urql.useMutation<CreateAddPaytankFundsOrderMutation, CreateAddPaytankFundsOrderMutationVariables>(CreateAddPaytankFundsOrderDocument)
}
export const CreateCafeteriaRegistrationOrderDocument = gql`
	mutation createCafeteriaRegistrationOrder($quoteId: String!) {
		createCafeteriaRegistrationOrder(quoteId: $quoteId) {
			...CafeteriaRegistrationOrder
		}
	}
	${CafeteriaRegistrationOrderFragmentDoc}
`

export function useCreateCafeteriaRegistrationOrderMutation() {
	return Urql.useMutation<CreateCafeteriaRegistrationOrderMutation, CreateCafeteriaRegistrationOrderMutationVariables>(
		CreateCafeteriaRegistrationOrderDocument
	)
}
export const CreateFeedPostDocument = gql`
	mutation CreateFeedPost($input: FeedPostInput!) {
		createFeedPost(input: $input) {
			errors {
				field
				error
			}
			feedPost {
				...ExtendedFeedPost
			}
		}
	}
	${ExtendedFeedPostFragmentDoc}
`

export function useCreateFeedPostMutation() {
	return Urql.useMutation<CreateFeedPostMutation, CreateFeedPostMutationVariables>(CreateFeedPostDocument)
}
export const DeleteFeedPostDocument = gql`
	mutation DeleteFeedPost($feedPostId: String!) {
		deleteFeedPost(feedPostId: $feedPostId)
	}
`

export function useDeleteFeedPostMutation() {
	return Urql.useMutation<DeleteFeedPostMutation, DeleteFeedPostMutationVariables>(DeleteFeedPostDocument)
}
export const DislikeFeedPostDocument = gql`
	mutation DislikeFeedPost($feedPostId: String!) {
		dislikeFeedPost(feedPostId: $feedPostId) {
			...ExtendedFeedPost
		}
	}
	${ExtendedFeedPostFragmentDoc}
`

export function useDislikeFeedPostMutation() {
	return Urql.useMutation<DislikeFeedPostMutation, DislikeFeedPostMutationVariables>(DislikeFeedPostDocument)
}
export const EditProfileDocument = gql`
	mutation EditProfile($input: EditStudentInput!) {
		editProfile(input: $input) {
			errors {
				field
				error
			}
			student {
				...ExtendedStudent
			}
		}
	}
	${ExtendedStudentFragmentDoc}
`

export function useEditProfileMutation() {
	return Urql.useMutation<EditProfileMutation, EditProfileMutationVariables>(EditProfileDocument)
}
export const FinishAddPaytankFundsOrderDocument = gql`
	mutation finishAddPaytankFundsOrder($input: FinishPaymentOrderInput!) {
		finishAddPaytankFundsOrder(input: $input) {
			...ExtendedPaytankOrder
		}
	}
	${ExtendedPaytankOrderFragmentDoc}
`

export function useFinishAddPaytankFundsOrderMutation() {
	return Urql.useMutation<FinishAddPaytankFundsOrderMutation, FinishAddPaytankFundsOrderMutationVariables>(FinishAddPaytankFundsOrderDocument)
}
export const FinishCafeteriaRegistrationOrderDocument = gql`
	mutation finishCafeteriaRegistrationOrder($input: FinishPaymentOrderInput!) {
		finishCafeteriaRegistrationOrder(input: $input) {
			...CafeteriaRegistrationOrder
		}
	}
	${CafeteriaRegistrationOrderFragmentDoc}
`

export function useFinishCafeteriaRegistrationOrderMutation() {
	return Urql.useMutation<FinishCafeteriaRegistrationOrderMutation, FinishCafeteriaRegistrationOrderMutationVariables>(
		FinishCafeteriaRegistrationOrderDocument
	)
}
export const FinishPdfUploadDocument = gql`
	mutation FinishPdfUpload($input: FinishUploadInput!) {
		finishPdfUpload(input: $input) {
			type
			url
		}
	}
`

export function useFinishPdfUploadMutation() {
	return Urql.useMutation<FinishPdfUploadMutation, FinishPdfUploadMutationVariables>(FinishPdfUploadDocument)
}
export const FinishPictureUploadDocument = gql`
	mutation FinishPictureUpload($input: FinishUploadInput!) {
		finishPictureUpload(input: $input) {
			type
			url
		}
	}
`

export function useFinishPictureUploadMutation() {
	return Urql.useMutation<FinishPictureUploadMutation, FinishPictureUploadMutationVariables>(FinishPictureUploadDocument)
}
export const FinishVideoUploadDocument = gql`
	mutation FinishVideoUpload($input: FinishUploadInput!) {
		finishVideoUpload(input: $input) {
			type
			url
		}
	}
`

export function useFinishVideoUploadMutation() {
	return Urql.useMutation<FinishVideoUploadMutation, FinishVideoUploadMutationVariables>(FinishVideoUploadDocument)
}
export const LikeFeedPostDocument = gql`
	mutation LikeFeedPost($feedPostId: String!) {
		likeFeedPost(feedPostId: $feedPostId) {
			...ExtendedFeedPost
		}
	}
	${ExtendedFeedPostFragmentDoc}
`

export function useLikeFeedPostMutation() {
	return Urql.useMutation<LikeFeedPostMutation, LikeFeedPostMutationVariables>(LikeFeedPostDocument)
}
export const LoginDocument = gql`
	mutation Login($username: String!, $password: String!) {
		login(username: $username, password: $password) {
			errors {
				field
				error
			}
			student {
				...ExtendedStudent
			}
			sessionId
		}
	}
	${ExtendedStudentFragmentDoc}
`

export function useLoginMutation() {
	return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument)
}
export const LoginWithAzureSsoDocument = gql`
	mutation LoginWithAzureSSO($accessToken: String!, $tenantId: String!) {
		loginWithAzureSSO(accessToken: $accessToken, tenantId: $tenantId) {
			errors {
				field
				error
			}
			student {
				...ExtendedStudent
			}
			sessionId
		}
	}
	${ExtendedStudentFragmentDoc}
`

export function useLoginWithAzureSsoMutation() {
	return Urql.useMutation<LoginWithAzureSsoMutation, LoginWithAzureSsoMutationVariables>(LoginWithAzureSsoDocument)
}
export const LogoutDocument = gql`
	mutation Logout {
		logout
	}
`

export function useLogoutMutation() {
	return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument)
}
export const RedeemPointsDocument = gql`
	mutation RedeemPoints($pointsRedeemItemId: String!) {
		redeemPoints(pointsRedeemItemId: $pointsRedeemItemId) {
			...PointsRedeemTransaction
		}
	}
	${PointsRedeemTransactionFragmentDoc}
`

export function useRedeemPointsMutation() {
	return Urql.useMutation<RedeemPointsMutation, RedeemPointsMutationVariables>(RedeemPointsDocument)
}
export const SetAvatarDocument = gql`
	mutation SetAvatar($key: String!, $url: String!) {
		setAvatar(key: $key, url: $url) {
			...EmbeddedPicture
		}
	}
	${EmbeddedPictureFragmentDoc}
`

export function useSetAvatarMutation() {
	return Urql.useMutation<SetAvatarMutation, SetAvatarMutationVariables>(SetAvatarDocument)
}
export const SignPdfUploadDocument = gql`
	mutation SignPdfUpload {
		signPdfUpload {
			signedUrl
			key
		}
	}
`

export function useSignPdfUploadMutation() {
	return Urql.useMutation<SignPdfUploadMutation, SignPdfUploadMutationVariables>(SignPdfUploadDocument)
}
export const SignPictureUploadDocument = gql`
	mutation SignPictureUpload($format: String!) {
		signPictureUpload(format: $format) {
			signedUrl
			key
		}
	}
`

export function useSignPictureUploadMutation() {
	return Urql.useMutation<SignPictureUploadMutation, SignPictureUploadMutationVariables>(SignPictureUploadDocument)
}
export const SignVideoUploadDocument = gql`
	mutation SignVideoUpload($format: String!) {
		signVideoUpload(format: $format) {
			signedUrl
			key
		}
	}
`

export function useSignVideoUploadMutation() {
	return Urql.useMutation<SignVideoUploadMutation, SignVideoUploadMutationVariables>(SignVideoUploadDocument)
}
export const UnblockTagForPaytankDocument = gql`
	mutation UnblockTagForPaytank {
		unblockTagForPaytank
	}
`

export function useUnblockTagForPaytankMutation() {
	return Urql.useMutation<UnblockTagForPaytankMutation, UnblockTagForPaytankMutationVariables>(UnblockTagForPaytankDocument)
}
export const AllAttendancesOfMeDocument = gql`
	query AllAttendancesOfMe($pagination: PaginatedInput!) {
		allAttendancesOfMe(pagination: $pagination) {
			total
			totalPages
			hasNextPage
			hasPrevPage
			currentPage
			attendances {
				...ExtendedStudentAttendance
			}
		}
	}
	${ExtendedStudentAttendanceFragmentDoc}
`

export function useAllAttendancesOfMeQuery(options: Omit<Urql.UseQueryArgs<AllAttendancesOfMeQueryVariables>, "query">) {
	return Urql.useQuery<AllAttendancesOfMeQuery>({ query: AllAttendancesOfMeDocument, ...options })
}
export const AllCircularsDocument = gql`
	query AllCirculars($pagination: PaginatedInput!) {
		allCirculars(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			circulars {
				...Circular
			}
		}
	}
	${CircularFragmentDoc}
`

export function useAllCircularsQuery(options: Omit<Urql.UseQueryArgs<AllCircularsQueryVariables>, "query">) {
	return Urql.useQuery<AllCircularsQuery>({ query: AllCircularsDocument, ...options })
}
export const AllFeedPostsDocument = gql`
	query AllFeedPosts($pagination: PaginatedInput!) {
		allFeedPosts(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			feedPosts {
				...ExtendedFeedPost
			}
		}
	}
	${ExtendedFeedPostFragmentDoc}
`

export function useAllFeedPostsQuery(options: Omit<Urql.UseQueryArgs<AllFeedPostsQueryVariables>, "query">) {
	return Urql.useQuery<AllFeedPostsQuery>({ query: AllFeedPostsDocument, ...options })
}
export const AllGalleryDocument = gql`
	query AllGallery($pagination: PaginatedInput!) {
		allGallery(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			gallery {
				...Gallery
			}
		}
	}
	${GalleryFragmentDoc}
`

export function useAllGalleryQuery(options: Omit<Urql.UseQueryArgs<AllGalleryQueryVariables>, "query">) {
	return Urql.useQuery<AllGalleryQuery>({ query: AllGalleryDocument, ...options })
}
export const AllHolidaysDocument = gql`
	query AllHolidays($pagination: PaginatedInput!) {
		allHolidays(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			holidays {
				...Holiday
			}
		}
	}
	${HolidayFragmentDoc}
`

export function useAllHolidaysQuery(options: Omit<Urql.UseQueryArgs<AllHolidaysQueryVariables>, "query">) {
	return Urql.useQuery<AllHolidaysQuery>({ query: AllHolidaysDocument, ...options })
}
export const AllPaytankOrdersDocument = gql`
	query AllPaytankOrders($pagination: PaginatedInput!) {
		allPaytankOrders(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			paytankOrders {
				...ExtendedPaytankOrder
			}
		}
	}
	${ExtendedPaytankOrderFragmentDoc}
`

export function useAllPaytankOrdersQuery(options: Omit<Urql.UseQueryArgs<AllPaytankOrdersQueryVariables>, "query">) {
	return Urql.useQuery<AllPaytankOrdersQuery>({ query: AllPaytankOrdersDocument, ...options })
}
export const AllPointsRedeemItemsDocument = gql`
	query AllPointsRedeemItems($pagination: PaginatedInput!) {
		allPointsRedeemItems(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			pointsRedeemItems {
				...PointsRedeemItem
			}
		}
	}
	${PointsRedeemItemFragmentDoc}
`

export function useAllPointsRedeemItemsQuery(options: Omit<Urql.UseQueryArgs<AllPointsRedeemItemsQueryVariables>, "query">) {
	return Urql.useQuery<AllPointsRedeemItemsQuery>({ query: AllPointsRedeemItemsDocument, ...options })
}
export const AllResourcesDocument = gql`
	query AllResources($classId: String, $classSectionId: String, $subjectId: String, $topicId: String, $subTopic: String) {
		allResources(classId: $classId, classSectionId: $classSectionId, subjectId: $subjectId, topicId: $topicId, subTopic: $subTopic) {
			...ExtendedResource
		}
	}
	${ExtendedResourceFragmentDoc}
`

export function useAllResourcesQuery(options?: Omit<Urql.UseQueryArgs<AllResourcesQueryVariables>, "query">) {
	return Urql.useQuery<AllResourcesQuery>({ query: AllResourcesDocument, ...options })
}
export const AllStaffsDocument = gql`
	query AllStaffs($pagination: PaginatedInput!) {
		allStaffs(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			staffs {
				...ExtendedStaff
			}
		}
	}
	${ExtendedStaffFragmentDoc}
`

export function useAllStaffsQuery(options: Omit<Urql.UseQueryArgs<AllStaffsQueryVariables>, "query">) {
	return Urql.useQuery<AllStaffsQuery>({ query: AllStaffsDocument, ...options })
}
export const AllStudentsDocument = gql`
	query AllStudents($pagination: PaginatedInput!, $classId: String, $classSectionId: String) {
		allStudents(pagination: $pagination, classId: $classId, classSectionId: $classSectionId) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			students {
				...ExtendedStudent
			}
		}
	}
	${ExtendedStudentFragmentDoc}
`

export function useAllStudentsQuery(options: Omit<Urql.UseQueryArgs<AllStudentsQueryVariables>, "query">) {
	return Urql.useQuery<AllStudentsQuery>({ query: AllStudentsDocument, ...options })
}
export const AllSubjectsDocument = gql`
	query AllSubjects {
		allSubjects {
			...Subject
		}
	}
	${SubjectFragmentDoc}
`

export function useAllSubjectsQuery(options?: Omit<Urql.UseQueryArgs<AllSubjectsQueryVariables>, "query">) {
	return Urql.useQuery<AllSubjectsQuery>({ query: AllSubjectsDocument, ...options })
}
export const AllTopicsDocument = gql`
	query AllTopics {
		allTopics {
			...Topic
		}
	}
	${TopicFragmentDoc}
`

export function useAllTopicsQuery(options?: Omit<Urql.UseQueryArgs<AllTopicsQueryVariables>, "query">) {
	return Urql.useQuery<AllTopicsQuery>({ query: AllTopicsDocument, ...options })
}
export const AllTrendingSkillsDocument = gql`
	query AllTrendingSkills($forYou: Boolean!, $topicId: String, $pagination: PaginatedInput!) {
		allTrendingSkills(forYou: $forYou, topicId: $topicId, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			trendingSkills {
				...ExtendedTrendingSkill
			}
		}
	}
	${ExtendedTrendingSkillFragmentDoc}
`

export function useAllTrendingSkillsQuery(options: Omit<Urql.UseQueryArgs<AllTrendingSkillsQueryVariables>, "query">) {
	return Urql.useQuery<AllTrendingSkillsQuery>({ query: AllTrendingSkillsDocument, ...options })
}
export const BusRouteByIdDocument = gql`
	query BusRouteById($busRouteId: String!) {
		busRouteById(busRouteId: $busRouteId) {
			...BusRoute
		}
	}
	${BusRouteFragmentDoc}
`

export function useBusRouteByIdQuery(options: Omit<Urql.UseQueryArgs<BusRouteByIdQueryVariables>, "query">) {
	return Urql.useQuery<BusRouteByIdQuery>({ query: BusRouteByIdDocument, ...options })
}
export const BusRoutesDocument = gql`
	query BusRoutes {
		busRoutes {
			...BusRoute
		}
	}
	${BusRouteFragmentDoc}
`

export function useBusRoutesQuery(options?: Omit<Urql.UseQueryArgs<BusRoutesQueryVariables>, "query">) {
	return Urql.useQuery<BusRoutesQuery>({ query: BusRoutesDocument, ...options })
}
export const CafeteriaRegistrationQuoteDocument = gql`
	query CafeteriaRegistrationQuote($sessionIds: [String!]!) {
		cafeteriaRegistrationQuote(sessionIds: $sessionIds) {
			...CafeteriaRegistrationQuote
		}
	}
	${CafeteriaRegistrationQuoteFragmentDoc}
`

export function useCafeteriaRegistrationQuoteQuery(options: Omit<Urql.UseQueryArgs<CafeteriaRegistrationQuoteQueryVariables>, "query">) {
	return Urql.useQuery<CafeteriaRegistrationQuoteQuery>({ query: CafeteriaRegistrationQuoteDocument, ...options })
}
export const CircularByIdDocument = gql`
	query CircularById($circularId: String!) {
		circularById(circularId: $circularId) {
			...Circular
		}
	}
	${CircularFragmentDoc}
`

export function useCircularByIdQuery(options: Omit<Urql.UseQueryArgs<CircularByIdQueryVariables>, "query">) {
	return Urql.useQuery<CircularByIdQuery>({ query: CircularByIdDocument, ...options })
}
export const ClassByIdDocument = gql`
	query ClassById($classId: String!) {
		classById(classId: $classId) {
			...ExtendedClass
		}
	}
	${ExtendedClassFragmentDoc}
`

export function useClassByIdQuery(options: Omit<Urql.UseQueryArgs<ClassByIdQueryVariables>, "query">) {
	return Urql.useQuery<ClassByIdQuery>({ query: ClassByIdDocument, ...options })
}
export const ClassesDocument = gql`
	query Classes {
		classes {
			...ExtendedClass
		}
	}
	${ExtendedClassFragmentDoc}
`

export function useClassesQuery(options?: Omit<Urql.UseQueryArgs<ClassesQueryVariables>, "query">) {
	return Urql.useQuery<ClassesQuery>({ query: ClassesDocument, ...options })
}
export const CurrentAndUpcomingSessionDocument = gql`
	query CurrentAndUpcomingSession {
		currentAndUpcomingSession {
			currentSession {
				...SchoolSession
			}
			upcomingSession {
				...SchoolSession
			}
		}
	}
	${SchoolSessionFragmentDoc}
`

export function useCurrentAndUpcomingSessionQuery(options?: Omit<Urql.UseQueryArgs<CurrentAndUpcomingSessionQueryVariables>, "query">) {
	return Urql.useQuery<CurrentAndUpcomingSessionQuery>({ query: CurrentAndUpcomingSessionDocument, ...options })
}
export const FeedPostByIdDocument = gql`
	query FeedPostById($feedPostId: String!) {
		feedPostById(feedPostId: $feedPostId) {
			...ExtendedFeedPost
		}
	}
	${ExtendedFeedPostFragmentDoc}
`

export function useFeedPostByIdQuery(options: Omit<Urql.UseQueryArgs<FeedPostByIdQueryVariables>, "query">) {
	return Urql.useQuery<FeedPostByIdQuery>({ query: FeedPostByIdDocument, ...options })
}
export const GalleryByIdDocument = gql`
	query GalleryById($galleryId: String!) {
		galleryById(galleryId: $galleryId) {
			...Gallery
		}
	}
	${GalleryFragmentDoc}
`

export function useGalleryByIdQuery(options: Omit<Urql.UseQueryArgs<GalleryByIdQueryVariables>, "query">) {
	return Urql.useQuery<GalleryByIdQuery>({ query: GalleryByIdDocument, ...options })
}
export const HousesWithMostPointsDocument = gql`
	query HousesWithMostPoints {
		housesWithMostPoints {
			...House
		}
	}
	${HouseFragmentDoc}
`

export function useHousesWithMostPointsQuery(options?: Omit<Urql.UseQueryArgs<HousesWithMostPointsQueryVariables>, "query">) {
	return Urql.useQuery<HousesWithMostPointsQuery>({ query: HousesWithMostPointsDocument, ...options })
}
export const MeDocument = gql`
	query Me {
		me {
			...ExtendedStudent
		}
	}
	${ExtendedStudentFragmentDoc}
`

export function useMeQuery(options?: Omit<Urql.UseQueryArgs<MeQueryVariables>, "query">) {
	return Urql.useQuery<MeQuery>({ query: MeDocument, ...options })
}
export const MyClassTeacherDocument = gql`
	query MyClassTeacher {
		myClassTeacher {
			...ExtendedStaff
		}
	}
	${ExtendedStaffFragmentDoc}
`

export function useMyClassTeacherQuery(options?: Omit<Urql.UseQueryArgs<MyClassTeacherQueryVariables>, "query">) {
	return Urql.useQuery<MyClassTeacherQuery>({ query: MyClassTeacherDocument, ...options })
}
export const MyFeedPostsDocument = gql`
	query MyFeedPosts($pagination: PaginatedInput!) {
		myFeedPosts(pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			feedPosts {
				...ExtendedFeedPost
			}
		}
	}
	${ExtendedFeedPostFragmentDoc}
`

export function useMyFeedPostsQuery(options: Omit<Urql.UseQueryArgs<MyFeedPostsQueryVariables>, "query">) {
	return Urql.useQuery<MyFeedPostsQuery>({ query: MyFeedPostsDocument, ...options })
}
export const MyPenaltiesDocument = gql`
	query MyPenalties {
		myPenalties {
			...ExtendedPenalty
		}
	}
	${ExtendedPenaltyFragmentDoc}
`

export function useMyPenaltiesQuery(options?: Omit<Urql.UseQueryArgs<MyPenaltiesQueryVariables>, "query">) {
	return Urql.useQuery<MyPenaltiesQuery>({ query: MyPenaltiesDocument, ...options })
}
export const MyPointsRedeemTransactionsDocument = gql`
	query MyPointsRedeemTransactions {
		myPointsRedeemTransactions {
			...ExtendedPointsRedeemTransaction
		}
	}
	${ExtendedPointsRedeemTransactionFragmentDoc}
`

export function useMyPointsRedeemTransactionsQuery(options?: Omit<Urql.UseQueryArgs<MyPointsRedeemTransactionsQueryVariables>, "query">) {
	return Urql.useQuery<MyPointsRedeemTransactionsQuery>({ query: MyPointsRedeemTransactionsDocument, ...options })
}
export const MyRewardsDocument = gql`
	query MyRewards {
		myRewards {
			...ExtendedReward
		}
	}
	${ExtendedRewardFragmentDoc}
`

export function useMyRewardsQuery(options?: Omit<Urql.UseQueryArgs<MyRewardsQueryVariables>, "query">) {
	return Urql.useQuery<MyRewardsQuery>({ query: MyRewardsDocument, ...options })
}
export const MySchoolDocument = gql`
	query MySchool {
		mySchool {
			...School
		}
	}
	${SchoolFragmentDoc}
`

export function useMySchoolQuery(options?: Omit<Urql.UseQueryArgs<MySchoolQueryVariables>, "query">) {
	return Urql.useQuery<MySchoolQuery>({ query: MySchoolDocument, ...options })
}
export const ResourceByIdDocument = gql`
	query ResourceById($resourceId: String!) {
		resourceById(resourceId: $resourceId) {
			...ExtendedResource
		}
	}
	${ExtendedResourceFragmentDoc}
`

export function useResourceByIdQuery(options: Omit<Urql.UseQueryArgs<ResourceByIdQueryVariables>, "query">) {
	return Urql.useQuery<ResourceByIdQuery>({ query: ResourceByIdDocument, ...options })
}
export const SchoolStatisticsDocument = gql`
	query SchoolStatistics {
		schoolStatistics {
			students
			nonTeachingStaff
			teachingStaff
			classes
			subjects
		}
	}
`

export function useSchoolStatisticsQuery(options?: Omit<Urql.UseQueryArgs<SchoolStatisticsQueryVariables>, "query">) {
	return Urql.useQuery<SchoolStatisticsQuery>({ query: SchoolStatisticsDocument, ...options })
}
export const SearchCircularDocument = gql`
	query SearchCircular($keyword: String!, $pagination: PaginatedInput!) {
		searchCircular(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			circulars {
				...Circular
			}
		}
	}
	${CircularFragmentDoc}
`

export function useSearchCircularQuery(options: Omit<Urql.UseQueryArgs<SearchCircularQueryVariables>, "query">) {
	return Urql.useQuery<SearchCircularQuery>({ query: SearchCircularDocument, ...options })
}
export const SearchGalleryDocument = gql`
	query SearchGallery($keyword: String!, $pagination: PaginatedInput!) {
		searchGallery(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			gallery {
				...Gallery
			}
		}
	}
	${GalleryFragmentDoc}
`

export function useSearchGalleryQuery(options: Omit<Urql.UseQueryArgs<SearchGalleryQueryVariables>, "query">) {
	return Urql.useQuery<SearchGalleryQuery>({ query: SearchGalleryDocument, ...options })
}
export const SearchResourceDocument = gql`
	query SearchResource($keyword: String!, $classId: String, $classSectionId: String, $subjectId: String, $topicId: String, $subTopic: String) {
		searchResource(keyword: $keyword, classId: $classId, classSectionId: $classSectionId, subjectId: $subjectId, topicId: $topicId, subTopic: $subTopic) {
			...ExtendedResource
		}
	}
	${ExtendedResourceFragmentDoc}
`

export function useSearchResourceQuery(options: Omit<Urql.UseQueryArgs<SearchResourceQueryVariables>, "query">) {
	return Urql.useQuery<SearchResourceQuery>({ query: SearchResourceDocument, ...options })
}
export const SearchStaffsDocument = gql`
	query SearchStaffs($keyword: String!, $pagination: PaginatedInput!) {
		searchStaffs(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			staffs {
				...ExtendedStaff
			}
		}
	}
	${ExtendedStaffFragmentDoc}
`

export function useSearchStaffsQuery(options: Omit<Urql.UseQueryArgs<SearchStaffsQueryVariables>, "query">) {
	return Urql.useQuery<SearchStaffsQuery>({ query: SearchStaffsDocument, ...options })
}
export const SearchStudentsDocument = gql`
	query SearchStudents($keyword: String!, $pagination: PaginatedInput!) {
		searchStudents(keyword: $keyword, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			students {
				...ExtendedStudent
			}
		}
	}
	${ExtendedStudentFragmentDoc}
`

export function useSearchStudentsQuery(options: Omit<Urql.UseQueryArgs<SearchStudentsQueryVariables>, "query">) {
	return Urql.useQuery<SearchStudentsQuery>({ query: SearchStudentsDocument, ...options })
}
export const SearchTrendingSkillDocument = gql`
	query SearchTrendingSkill($forYou: Boolean!, $keyword: String!, $topicId: String, $pagination: PaginatedInput!) {
		searchTrendingSkill(forYou: $forYou, keyword: $keyword, topicId: $topicId, pagination: $pagination) {
			hasPrevPage
			hasNextPage
			totalPages
			total
			currentPage
			trendingSkills {
				...ExtendedTrendingSkill
			}
		}
	}
	${ExtendedTrendingSkillFragmentDoc}
`

export function useSearchTrendingSkillQuery(options: Omit<Urql.UseQueryArgs<SearchTrendingSkillQueryVariables>, "query">) {
	return Urql.useQuery<SearchTrendingSkillQuery>({ query: SearchTrendingSkillDocument, ...options })
}
export const StaffByIdDocument = gql`
	query StaffById($staffId: String!) {
		staffById(staffId: $staffId) {
			...ExtendedStaff
		}
	}
	${ExtendedStaffFragmentDoc}
`

export function useStaffByIdQuery(options: Omit<Urql.UseQueryArgs<StaffByIdQueryVariables>, "query">) {
	return Urql.useQuery<StaffByIdQuery>({ query: StaffByIdDocument, ...options })
}
export const StudentByIdDocument = gql`
	query StudentById($studentId: String!) {
		studentById(studentId: $studentId) {
			...ExtendedStudent
		}
	}
	${ExtendedStudentFragmentDoc}
`

export function useStudentByIdQuery(options: Omit<Urql.UseQueryArgs<StudentByIdQueryVariables>, "query">) {
	return Urql.useQuery<StudentByIdQuery>({ query: StudentByIdDocument, ...options })
}
export const StudentsWithMostPointsDocument = gql`
	query StudentsWithMostPoints {
		studentsWithMostPoints {
			...Student
		}
	}
	${StudentFragmentDoc}
`

export function useStudentsWithMostPointsQuery(options?: Omit<Urql.UseQueryArgs<StudentsWithMostPointsQueryVariables>, "query">) {
	return Urql.useQuery<StudentsWithMostPointsQuery>({ query: StudentsWithMostPointsDocument, ...options })
}
export const TodayAttendanceOfMeDocument = gql`
	query TodayAttendanceOfMe {
		todayAttendanceOfMe {
			...ExtendedStudentAttendance
		}
	}
	${ExtendedStudentAttendanceFragmentDoc}
`

export function useTodayAttendanceOfMeQuery(options?: Omit<Urql.UseQueryArgs<TodayAttendanceOfMeQueryVariables>, "query">) {
	return Urql.useQuery<TodayAttendanceOfMeQuery>({ query: TodayAttendanceOfMeDocument, ...options })
}
export const TodayBusAttendanceOfMeDocument = gql`
	query TodayBusAttendanceOfMe {
		todayBusAttendanceOfMe {
			...StudentBusAttendance
		}
	}
	${StudentBusAttendanceFragmentDoc}
`

export function useTodayBusAttendanceOfMeQuery(options?: Omit<Urql.UseQueryArgs<TodayBusAttendanceOfMeQueryVariables>, "query">) {
	return Urql.useQuery<TodayBusAttendanceOfMeQuery>({ query: TodayBusAttendanceOfMeDocument, ...options })
}
export const TrendingSkillByIdDocument = gql`
	query TrendingSkillById($trendingSkillId: String!) {
		trendingSkillById(trendingSkillId: $trendingSkillId) {
			...ExtendedTrendingSkill
		}
	}
	${ExtendedTrendingSkillFragmentDoc}
`

export function useTrendingSkillByIdQuery(options: Omit<Urql.UseQueryArgs<TrendingSkillByIdQueryVariables>, "query">) {
	return Urql.useQuery<TrendingSkillByIdQuery>({ query: TrendingSkillByIdDocument, ...options })
}
export const MeUpdateDocument = gql`
	subscription meUpdate($studentId: String!) {
		meUpdate(studentId: $studentId) {
			...ExtendedStudent
		}
	}
	${ExtendedStudentFragmentDoc}
`

export function useMeUpdateSubscription<TData = MeUpdateSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<MeUpdateSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<MeUpdateSubscription, TData>
) {
	return Urql.useSubscription<MeUpdateSubscription, TData, MeUpdateSubscriptionVariables>({ query: MeUpdateDocument, ...options }, handler)
}
export const MyPaytankOrderDocument = gql`
	subscription myPaytankOrder($studentId: String!) {
		myPaytankOrder(studentId: $studentId) {
			...ExtendedPaytankOrder
		}
	}
	${ExtendedPaytankOrderFragmentDoc}
`

export function useMyPaytankOrderSubscription<TData = MyPaytankOrderSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<MyPaytankOrderSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<MyPaytankOrderSubscription, TData>
) {
	return Urql.useSubscription<MyPaytankOrderSubscription, TData, MyPaytankOrderSubscriptionVariables>({ query: MyPaytankOrderDocument, ...options }, handler)
}
export const TodayAttendanceUpdateOfMeDocument = gql`
	subscription TodayAttendanceUpdateOfMe($studentId: String!) {
		todayAttendanceUpdateOfMe(studentId: $studentId) {
			...StudentAttendance
		}
	}
	${StudentAttendanceFragmentDoc}
`

export function useTodayAttendanceUpdateOfMeSubscription<TData = TodayAttendanceUpdateOfMeSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<TodayAttendanceUpdateOfMeSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<TodayAttendanceUpdateOfMeSubscription, TData>
) {
	return Urql.useSubscription<TodayAttendanceUpdateOfMeSubscription, TData, TodayAttendanceUpdateOfMeSubscriptionVariables>(
		{ query: TodayAttendanceUpdateOfMeDocument, ...options },
		handler
	)
}
export const TodayBusAttendanceUpdateOfMeDocument = gql`
	subscription TodayBusAttendanceUpdateOfMe($studentId: String!) {
		todayBusAttendanceUpdateOfMe(studentId: $studentId) {
			...StudentBusAttendance
		}
	}
	${StudentBusAttendanceFragmentDoc}
`

export function useTodayBusAttendanceUpdateOfMeSubscription<TData = TodayBusAttendanceUpdateOfMeSubscription>(
	options: Omit<Urql.UseSubscriptionArgs<TodayBusAttendanceUpdateOfMeSubscriptionVariables>, "query"> = {},
	handler?: Urql.SubscriptionHandler<TodayBusAttendanceUpdateOfMeSubscription, TData>
) {
	return Urql.useSubscription<TodayBusAttendanceUpdateOfMeSubscription, TData, TodayBusAttendanceUpdateOfMeSubscriptionVariables>(
		{ query: TodayBusAttendanceUpdateOfMeDocument, ...options },
		handler
	)
}
