/* eslint-disable @typescript-eslint/indent */
import { Center, Grid, Heading, HStack, Icon, Spinner, Text, VStack } from "@chakra-ui/react"
import { faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import {
	useMeQuery,
	useTodayAttendanceOfMeQuery,
	useTodayAttendanceUpdateOfMeSubscription,
	useTodayBusAttendanceOfMeQuery,
	useTodayBusAttendanceUpdateOfMeSubscription,
} from "../../graphql"

export const TodayAttendanceOfMe: React.FC = () => {
	const [{ data, fetching, error }] = useTodayAttendanceOfMeQuery()

	const [{ data: meData }] = useMeQuery()

	useTodayAttendanceUpdateOfMeSubscription({ variables: { studentId: meData?.me?._id || "" }, pause: !meData?.me?._id })

	const [{ data: busAttendanceData, fetching: fetchingBusAttendance, error: busAttendanceError }] = useTodayBusAttendanceOfMeQuery()

	useTodayBusAttendanceUpdateOfMeSubscription({ variables: { studentId: meData?.me?._id || "" }, pause: !meData?.me?._id })

	return (
		<VStack>
			{fetching ? (
				<Center w="full" py="4">
					<Spinner color="text.400" />
				</Center>
			) : error ? (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="text.400">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : (
				<VStack w="full" maxW="xl" align="flex-start" spacing={{ lg: 5 }}>
					<Heading as="h2" fontSize="md" fontWeight="semisemibold" color="text.400">
						Today&apos;s attendance
					</Heading>
					<VStack w="full" maxW="xl" align="flex-start" bg="white.500" rounded="2xl" px="6" py="4" shadow="sm">
						{fetchingBusAttendance ? (
							<Center w="full" py="4">
								<Spinner color="text.400" />
							</Center>
						) : busAttendanceError ? (
							<Center w="full" py="4">
								<Text fontSize="md" fontWeight="semisemibold" color="text.400">
									{busAttendanceError.message.replace("[GraphQL] ", "")}
								</Text>
							</Center>
						) : (
							<Grid w="full" templateColumns="repeat(2, 1fr)" gap={4}>
								<VStack align="flex-start" spacing={0}>
									<Text fontSize="xs" color="text.400">
										Pickup from Home
									</Text>
									<HStack>
										<VStack align="flex-start" spacing={0}>
											<Text fontSize="sm" color="text.400" fontWeight="semibold">
												{busAttendanceData?.todayBusAttendanceOfMe?.homePickupAt
													? format(new Date(busAttendanceData.todayBusAttendanceOfMe.homePickupAt), "p")
													: "-"}
											</Text>
										</VStack>
									</HStack>
								</VStack>

								<VStack align="flex-start" spacing={0}>
									<Text fontSize="xs" color="text.400">
										Dropped at School
									</Text>
									<HStack>
										<VStack align="flex-start" spacing={0}>
											<VStack align="flex-start" spacing={0}>
												<Text fontSize="sm" color="text.400" fontWeight="semibold">
													{busAttendanceData?.todayBusAttendanceOfMe?.schoolDropAt
														? format(new Date(busAttendanceData.todayBusAttendanceOfMe.schoolDropAt), "p")
														: "-"}
												</Text>
											</VStack>
										</VStack>
									</HStack>
								</VStack>

								<VStack align="flex-start" spacing={0}>
									<Text fontSize="xs" color="text.400">
										Pickup from School
									</Text>
									<HStack>
										<VStack align="flex-start" spacing={0}>
											<Text fontSize="sm" color="text.400" fontWeight="semibold">
												{busAttendanceData?.todayBusAttendanceOfMe?.schoolPickupAt
													? format(new Date(busAttendanceData.todayBusAttendanceOfMe.schoolPickupAt), "p")
													: "-"}
											</Text>
										</VStack>
									</HStack>
								</VStack>

								<VStack align="flex-start" spacing={0}>
									<Text fontSize="xs" color="text.400">
										Dropped at Home
									</Text>
									<HStack>
										<VStack align="flex-start" spacing={0}>
											<VStack align="flex-start" spacing={0}>
												<Text fontSize="sm" color="text.400" fontWeight="semibold">
													{busAttendanceData?.todayBusAttendanceOfMe?.homeDropAt
														? format(new Date(busAttendanceData.todayBusAttendanceOfMe.homeDropAt), "p")
														: "-"}
												</Text>
											</VStack>
										</VStack>
									</HStack>
								</VStack>
							</Grid>
						)}
						<Grid w="full" templateColumns="repeat(2, 1fr)" gap={4}>
							<VStack align="flex-start" spacing={0}>
								<Text fontSize="xs" color="text.400">
									School In
								</Text>
								<HStack>
									<VStack align="flex-start" spacing={0}>
										<Text fontSize="sm" color="text.400" fontWeight="semibold">
											{data?.todayAttendanceOfMe && (data.todayAttendanceOfMe.isInRecorded || data.todayAttendanceOfMe.isInManual)
												? format(new Date(data.todayAttendanceOfMe.inAt), "p")
												: "-"}
										</Text>
										<Text fontSize="xs" color="text.100">
											{data?.todayAttendanceOfMe &&
												(data.todayAttendanceOfMe.isInManual
													? "set manually"
													: data.todayAttendanceOfMe.isInRecorded
													? "as recorded"
													: "")}
										</Text>
									</VStack>
								</HStack>
							</VStack>

							<VStack align="flex-start" spacing={0}>
								<Text fontSize="xs" color="text.400">
									School Out
								</Text>
								<HStack>
									{data?.todayAttendanceOfMe && data.todayAttendanceOfMe.isFinal ? (
										<VStack align="flex-start" spacing={0}>
											<Text fontSize="sm" color="text.400" fontWeight="semibold">
												{format(new Date(data.todayAttendanceOfMe.outAt), "p")}
											</Text>
											<Text fontSize="xs" color="text.100">
												{data.todayAttendanceOfMe.isOutManual
													? "set manually"
													: data.todayAttendanceOfMe.isOutRecorded
													? "as recorded"
													: ""}
											</Text>
										</VStack>
									) : (
										<Text fontSize="sm" color="text.400" fontWeight="semibold">
											-
										</Text>
									)}
								</HStack>
							</VStack>

							<VStack align="flex-start" spacing={0}>
								<Text fontSize="xs" color="text.400">
									Status
								</Text>

								{data?.todayAttendanceOfMe && data.todayAttendanceOfMe.isLate !== null ? (
									<HStack>
										{" "}
										<Icon
											color={data.todayAttendanceOfMe.isLate ? "red" : "green"}
											as={(props: any) => (
												<FontAwesomeIcon icon={data.todayAttendanceOfMe!.isLate ? faXmarkCircle : faCheckCircle} {...props} />
											)}
										/>
										<Text fontSize="sm" color="text.400" fontWeight="semibold">
											{data.todayAttendanceOfMe!.isLate
												? `Late ${
														data.todayAttendanceOfMe.lateByMinutes && data.todayAttendanceOfMe.lateByMinutes >= 0
															? `by ${data.todayAttendanceOfMe.lateByMinutes || 0} minutes`
															: ""
												  }`
												: "On time"}
										</Text>
									</HStack>
								) : (
									<Text>-</Text>
								)}
							</VStack>
						</Grid>
					</VStack>
				</VStack>
			)}
		</VStack>
	)
}
