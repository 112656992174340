import { Center } from "@chakra-ui/react"
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js"
import React from "react"
import { Bar } from "react-chartjs-2"
import { useHousesWithMostPointsQuery } from "../../graphql"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const TopHouses: React.FC = () => {
	const [{ data }] = useHousesWithMostPointsQuery()

	const labels = data?.housesWithMostPoints.map((s) => s.name) || []

	const _data = {
		labels,
		datasets: [
			{
				label: "House",
				data: data?.housesWithMostPoints.map((s) => s.pointsBalance || 0) || [],
				backgroundColor: "#8676E5AA",
			},
		],
	}
	return (
		<Center w="full" maxW={{ base: "sm", md: "lg" }}>
			<Bar
				options={{
					responsive: true,
					plugins: {
						title: {
							display: true,
							text: "Top Houses",
						},
					},
				}}
				data={_data}
			/>
		</Center>
	)
}
