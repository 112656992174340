import { useIsAuthenticated } from "@azure/msal-react"
import { Avatar, Button, Divider, Icon, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text, useToast, VStack } from "@chakra-ui/react"
import { faArrowRightFromBracket, faCoins, faLock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { SESSION_ID_KEY } from "../../constants"
import { useLogoutMutation, useMeQuery } from "../../graphql"
import { msalInstance } from "../../msal"

export const AccountMenu: React.FC<any> = ({ children }) => {
	const [{ data }] = useMeQuery()

	const [{ fetching }, logout] = useLogoutMutation()
	const toast = useToast()

	const navigate = useNavigate()

	const push = (path: string) => () => {
		navigate(path)
	}

	const isSSOAuthenticated = useIsAuthenticated()

	const handleLogout = async () => {
		const { error } = await logout()

		if (error) {
			return toast({
				title: "Logout Error",
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		localStorage.removeItem(SESSION_ID_KEY)

		if (isSSOAuthenticated) await msalInstance.logoutRedirect()

		return navigate("/", { replace: true })
	}

	return (
		<Popover isLazy placement="bottom-end">
			<PopoverTrigger>{children}</PopoverTrigger>
			<PopoverContent bg="whitesmoke" shadow="2xl" _focus={{ shadow: "2xl", outline: "none" }}>
				<PopoverBody p="0">
					<VStack w="full" fontSize="sm" py="4">
						<Avatar src={data?.me?.picture?.url} name={data?.me?.name} size="lg" />
						{data?.me && (
							<VStack>
								<Text>Signed in as</Text>
								<Button variant="link" onClick={push("/profile")}>
									{data.me.name}
								</Button>
							</VStack>
						)}

						<Button
							as={NavLink}
							to="/points"
							w="full"
							variant="ghost"
							colorScheme="primary"
							size="sm"
							rounded="none"
							leftIcon={<Icon fontSize="lg" as={(props: any) => <FontAwesomeIcon icon={faCoins} {...props} />} />}
						>
							<Text w="full" textAlign="left" fontWeight="normal">
								Points
							</Text>
						</Button>

						<VStack w="full" align="stretch">
							<Divider />
							<Button
								as={NavLink}
								to="/changePassword"
								w="full"
								variant="ghost"
								colorScheme="primary"
								size="sm"
								rounded="none"
								leftIcon={<Icon fontSize="lg" as={(props: any) => <FontAwesomeIcon icon={faLock} {...props} />} />}
							>
								<Text w="full" textAlign="left" fontWeight="normal">
									Change Password
								</Text>
							</Button>
							<Button
								w="full"
								variant="ghost"
								colorScheme="primary"
								size="sm"
								rounded="none"
								leftIcon={<Icon fontSize="lg" as={(props: any) => <FontAwesomeIcon icon={faArrowRightFromBracket} {...props} />} />}
								onClick={handleLogout}
								isLoading={fetching}
							>
								<Text w="full" textAlign="left" fontWeight="normal">
									Log Out
								</Text>
							</Button>
						</VStack>
					</VStack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}
