import {
	AspectRatio,
	Avatar,
	Button,
	Center,
	Container,
	Heading,
	HStack,
	Icon,
	Spinner,
	Stack,
	Tag,
	TagLabel,
	Text,
	useBreakpointValue,
	useDisclosure,
	VStack,
} from "@chakra-ui/react"
import { faBowlFood, faStar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { contrastColor } from "contrast-color"
import React from "react"
import { Watch } from "react-feather"
import {
	CreateCafeteriaRegistrationOrderModal,
	DashboardHeader,
	Nav,
	PaytankBalanceModal,
	StudentDetails,
	StudentPointsModal,
	SubscribePopover,
} from "../../components"
import { AllAttendancesOfMe } from "../../components/student/AllAttendancesOfMe"
import { TodayAttendanceOfMe } from "../../components/student/TodayAttendanceOfMe"
import { GRADIENT } from "../../constants"
import { useMeQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const Profile: React.FC = () => {
	const [{ data, fetching, error }] = useMeQuery()

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const { getButtonProps, isOpen, onClose } = useDisclosure()
	const { getButtonProps: getPaytankModalButtonProps, isOpen: isPaytankModalOpen, onClose: onPaytankModalClose } = useDisclosure()
	const {
		getButtonProps: cafeteriaRegistrationButtonProps,
		isOpen: isCafeteriaRegistrationModalOpen,
		onClose: onCafeteriaRegistrationModalClose,
	} = useDisclosure()

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semisemibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.me ? (
						<VStack w="full" align="stretch">
							<HStack w="full" align="flex-start" spacing={4}>
								<AspectRatio w="full" maxW={{ base: "24", md: "36" }} cursor="pointer" ratio={1}>
									<Avatar src={data.me.picture?.url} name={data.me.name} />
								</AspectRatio>

								<VStack align="flex-start">
									<VStack align="flex-start" spacing="0">
										<Heading as="h1" fontSize="xl" color="text.400">
											{data.me.name}
										</Heading>
										<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.400" maxW="xs">
											@{data.me.username}
										</Heading>
									</VStack>
									<HStack flexWrap="wrap">
										<Tag colorScheme="primary" variant="solid" rounded="xl" size="sm">
											<TagLabel>
												{data.me.class.name} - {data.me.classSection.name}
											</TagLabel>
										</Tag>
										{data.me.house && (
											<Tag
												pos="relative"
												size="sm"
												px="4"
												variant="solid"
												colorScheme="orange"
												bgColor={data.me.house?.colorHex}
												color={contrastColor({ bgColor: data.me.house?.colorHex })}
											>
												<TagLabel>House {data.me.house.name}</TagLabel>
												{data.me.house?.captainId === data.me._id && (
													<>
														<Icon
															as={(props: any) => <FontAwesomeIcon icon={faStar} {...props} />}
															pos="absolute"
															top="-1.5"
															right="-1.5"
															fontSize="24"
															color={contrastColor({ bgColor: data.me.house?.colorHex })}
														/>
														<Icon
															as={(props: any) => <FontAwesomeIcon icon={faStar} {...props} />}
															pos="absolute"
															top="-1"
															right="-1"
															fontSize="20"
															color={data.me.house?.colorHex}
														/>
													</>
												)}
											</Tag>
										)}
										<Tag colorScheme="primary" variant="solid" rounded="xl" size="sm">
											<HStack>
												<TagLabel>{data.me.tag ? data.me.tag.tagDecimalId : "No Tag"}</TagLabel> <Icon as={Watch} />
											</HStack>
										</Tag>
									</HStack>
									<HStack flexWrap="wrap">
										<SubscribePopover student={data.me} />
										<Button size="xs" variant="outline" colorScheme="primary" {...getButtonProps()}>
											{data.me.pointsBalance || 0} points
										</Button>
										{isOpen && <StudentPointsModal isOpen={isOpen} onClose={onClose} student={data.me} />}
										<Button size="xs" variant="outline" colorScheme="primary" {...getPaytankModalButtonProps()}>
											₹{data.me.paytankBalance || 0} Paytank Balance
										</Button>
										{isPaytankModalOpen && (
											<PaytankBalanceModal isOpen={isPaytankModalOpen} onClose={onPaytankModalClose} student={data.me} />
										)}
									</HStack>

									<Button
										size="xs"
										colorScheme="primary"
										rightIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faBowlFood} {...props} />} />}
										{...cafeteriaRegistrationButtonProps()}
									>
										Cafeteria
									</Button>

									{isCafeteriaRegistrationModalOpen && (
										<CreateCafeteriaRegistrationOrderModal
											isOpen={isCafeteriaRegistrationModalOpen}
											onClose={onCafeteriaRegistrationModalClose}
											student={data.me}
										/>
									)}
								</VStack>
							</HStack>
							<Stack direction={{ base: "column", lg: "row" }} w="full" align="stretch" spacing={6}>
								<StudentDetails student={data.me} />
								<VStack w="full" maxW="xl" align="stretch" spacing={6}>
									<TodayAttendanceOfMe />
									<AllAttendancesOfMe />
								</VStack>
							</Stack>
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semisemibold" color="text.400">
								Couldn&apos;t find the profile.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
