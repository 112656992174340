/* eslint-disable @typescript-eslint/indent */
import {
	AspectRatio,
	chakra,
	Heading,
	HStack,
	Image,
	LinkBox,
	LinkOverlay,
	Text,
	VStack,
	Grid,
	Center,
	Box,
	Img,
	Icon,
	Flex,
	Tag,
	TagLabel,
} from "@chakra-ui/react"
import { faFilePdf } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatDistanceToNow } from "date-fns"
import React, { useMemo, useState } from "react"
import { Download } from "react-feather"
import { NavLink } from "react-router-dom"
import { AuthorTypes, ExtendedResourceFragment } from "../../graphql"

export type ResourceProps = {
	resource: ExtendedResourceFragment
	isLinkable?: boolean
}

export const Resource: React.FC<ResourceProps> = ({ resource, isLinkable = true }) => {
	const author = useMemo<{
		title: string
		subtitle: string
		picture: string
		link: string
	}>(
		() => ({
			title:
				resource.author.authorType === AuthorTypes.Student
					? `${resource.author.student?.name}`
					: resource.author.authorType === AuthorTypes.School
					? `${resource.author.school?.name}`
					: resource.author.authorType === AuthorTypes.Staff
					? `${resource.author.staff?.name}`
					: "",
			subtitle:
				resource.author.authorType === AuthorTypes.Student
					? `@${resource.author.student?.username}`
					: resource.author.authorType === AuthorTypes.School
					? `${resource.author.school?.location.city}, ${resource.author.school?.location.state}, ${resource.author.school?.location.address} - ${resource.author.school?.location.pin}`
					: resource.author.authorType === AuthorTypes.Staff
					? `@${resource.author.staff?.username}`
					: "",
			picture:
				resource.author.authorType === AuthorTypes.Student
					? resource.author.student?.picture?.variants?.thumbnail || resource.author.student?.picture?.url || ""
					: resource.author.authorType === AuthorTypes.School
					? resource.author.school?.picture?.variants?.thumbnail || resource.author.school?.picture?.url || ""
					: resource.author.authorType === AuthorTypes.Staff
					? resource.author.staff?.picture?.variants?.thumbnail || resource.author.staff?.picture?.url || ""
					: "",
			link:
				resource.author.authorType === AuthorTypes.Student && resource.author.student
					? `/students/${resource.author.student._id}`
					: resource.author.authorType === AuthorTypes.School
					? "/dashboard"
					: resource.author.authorType === AuthorTypes.Staff
					? `/staff/${resource.author.staffId}`
					: "",
		}),
		[resource]
	)

	return (
		<>
			<VStack w="full" maxW="lg" align="flex-start" shadow="sm" rounded="xl" py={2} px={4} bgColor="#fcfcf4">
				<VStack w="full" align="flex-start" spacing={0}>
					<HStack w="full" justify="space-between">
						<HStack flex={1} spacing={0}>
							<AspectRatio ratio={1} w="12" rounded="xl" shadow="xl">
								<Image rounded="xl" src={author.picture || "https://picsum.photos/200"} alt={author.title} />
							</AspectRatio>
							<VStack w="full" align="flex-start" spacing={0} pl={2}>
								<Heading fontSize="sm" fontWeight="semibold">
									{author.title}
								</Heading>
								{author.subtitle && (
									<Heading fontSize="xs" fontWeight="normal" color="blackAlpha.700">
										{author.subtitle}
									</Heading>
								)}
							</VStack>
						</HStack>
					</HStack>
				</VStack>

				<VStack as={isLinkable ? LinkBox : "div"} w="full" align="stretch">
					{isLinkable && <LinkOverlay as={NavLink} to={`/resources/${resource._id}`} />}
					<Text fontSize="12" color="blackAlpha.700">
						<chakra.span>{resource.author.authorType}</chakra.span>
						<chakra.span fontWeight="bold"> • </chakra.span>
						<chakra.span>
							{formatDistanceToNow(new Date(resource.createdAt), {
								addSuffix: true,
							})}
						</chakra.span>
					</Text>
					{resource.name && (
						<Text fontSize="x-large" fontWeight="medium" whiteSpace="pre-line">
							{resource.name}
						</Text>
					)}
					{resource.description && (
						<Text fontSize="md" whiteSpace="pre-line">
							{resource.description}
						</Text>
					)}
					{resource.attachments?.pdfs?.length ? (
						<Grid templateColumns={getGridTemplateColumns(resource.attachments.pdfs.length || 0)} gap={1}>
							{resource.attachments.pdfs.slice(0, 4).map((pdf, index) => (
								<AspectRatio
									pos="relative"
									key={index}
									bg="primary.100"
									rounded="xl"
									ratio={1}
									cursor="pointer"
									onClick={() => window.open(pdf.url, "#")}
									zIndex={1}
								>
									<>
										<Center rounded="xl" bg="primary.100">
											<Icon color="primary.600" fontSize="5xl" as={(props: any) => <FontAwesomeIcon icon={faFilePdf} {...props} />} />
										</Center>

										{index === 3 && (resource.attachments?.pdfs?.length || 0) > 4 && (
											<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
												<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
													+{(resource.attachments?.pdfs?.length || 0) - 4}
												</Text>
											</Center>
										)}
									</>
								</AspectRatio>
							))}
						</Grid>
					) : resource.attachments?.videos?.length ? (
						<Grid templateColumns={getGridTemplateColumns(resource.attachments.videos.length)} gap={1}>
							{resource.attachments.videos.slice(0, 4).map((video, index) => (
								<AspectRatio pos="relative" key={index} bg="primary.100" rounded="xl" ratio={1} zIndex={1}>
									<>
										<video style={{ borderRadius: "12px" }} width="100%" src={video.url} controls />
										{index === 3 && (resource.attachments?.videos?.length || 0) > 4 && (
											<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
												<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
													+{(resource.attachments?.videos?.length || 0) - 4}
												</Text>
											</Center>
										)}
									</>
								</AspectRatio>
							))}
						</Grid>
					) : resource.attachments?.pictures?.length ? (
						<Grid templateColumns={getGridTemplateColumns(resource.attachments.pictures.length)} gap={1}>
							{resource.attachments.pictures.slice(0, 4).map((picture, index) => {
								const [isHovered, setIsHovered] = useState(false)

								return (
									<AspectRatio
										key={index}
										pos="relative"
										ratio={1}
										zIndex={1}
										onMouseEnter={() => setIsHovered(true)}
										onMouseLeave={() => setIsHovered(false)}
									>
										<>
											<Box position="relative" w="full" bg="white" rounded="xl">
												<Img w="full" rounded="xl" src={picture.url} style={{ objectFit: "cover" }} />
												{!isLinkable && (
													<a href={picture.url} target="_blank" rel="noreferrer">
														<Box
															position="absolute"
															inset="0"
															bgColor={isHovered ? "blackAlpha.600" : "transparent"}
															cursor="pointer"
															rounded="xl"
														>
															{isHovered && (
																<Center pos="absolute" bottom="0" left="0" right="0">
																	<HStack color="white">
																		<Text fontSize="md" fontWeight="semibold">
																			Download
																		</Text>
																		<Icon as={Download} fontSize="md" />
																	</HStack>
																</Center>
															)}
														</Box>
													</a>
												)}
											</Box>

											{index === 3 && (resource.attachments?.pictures?.length || 0) > 4 && (
												<Center rounded="xl" pos="absolute" inset="0" bg="blackAlpha.700">
													<Text color="white" fontSize={{ base: "4xl", md: "5xl" }}>
														+{(resource.attachments?.pictures?.length || 0) - 4}
													</Text>
												</Center>
											)}
										</>
									</AspectRatio>
								)
							})}
						</Grid>
					) : (
						<Text></Text>
					)}
				</VStack>

				{!isLinkable && (
					<>
						<HStack w="full">
							<Heading fontSize="sm" color="text.400">
								Target
							</Heading>
							<Flex flexWrap="wrap" px="1">
								{resource.targets.map((target) => (
									<Tag key={target} m="0.5" size="sm" colorScheme="purple">
										<TagLabel>{target}</TagLabel>
									</Tag>
								))}
							</Flex>
						</HStack>

						<HStack w="full">
							<Heading fontSize="sm" color="text.400">
								Class
							</Heading>
							<Flex flexWrap="wrap" px="1">
								<Tag m="0.5" size="sm" colorScheme="purple">
									<TagLabel textTransform="capitalize">
										{resource.class.name}
										{resource.classSection?.name ? " - " + resource.classSection.name : ""}
									</TagLabel>
								</Tag>
							</Flex>
						</HStack>

						<HStack w="full">
							<Heading fontSize="sm" color="text.400">
								Subject
							</Heading>
							<Flex flexWrap="wrap" px="1">
								<Tag m="0.5" size="sm" colorScheme="purple">
									<TagLabel textTransform="capitalize">{resource.subject.name}</TagLabel>
								</Tag>
							</Flex>
						</HStack>

						<HStack w="full">
							<Heading fontSize="sm" color="text.400">
								Topic
							</Heading>
							<Flex flexWrap="wrap" px="1">
								<Tag m="0.5" size="sm" colorScheme="purple">
									<TagLabel textTransform="capitalize">{resource.topic.name}</TagLabel>
								</Tag>
							</Flex>
						</HStack>

						{resource.subTopic && (
							<HStack w="full">
								<Heading fontSize="sm" color="text.400">
									Sub Topic
								</Heading>
								<Flex flexWrap="wrap" px="1">
									<Tag m="0.5" size="sm" colorScheme="purple">
										<TagLabel textTransform="capitalize">{resource.subTopic}</TagLabel>
									</Tag>
								</Flex>
							</HStack>
						)}
					</>
				)}
			</VStack>
		</>
	)
}

const getGridTemplateColumns = (length: number) => {
	if (length < 2) {
		return "1fr"
	}

	return "repeat(2, 1fr)"
}
