import { Center, Heading, HStack, Select, Spinner, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import {
	AllStudentsQueryVariables,
	ClassFragment,
	ClassSectionFragment,
	ExtendedClassFragment,
	ExtendedClassSectionFragment,
	useAllStudentsQuery,
	useClassByIdQuery,
	useClassesQuery,
	useMeQuery,
} from "../../graphql"
import { StudentsTable } from "./StudentsTable"

export const AllStudents: React.FC = () => {
	const [{ data: classesData, fetching: fetchingClasses, error: classesError }] = useClassesQuery()

	const [schoolClass, setSchoolClass] = useState<ExtendedClassFragment | undefined>()

	const [classSection, setClassSection] = useState<ExtendedClassSectionFragment>()

	const [{ data: meData }] = useMeQuery()

	const [myClass, setMyClass] = useState<ClassFragment>()
	const [myClassSection, setMyClassSection] = useState<ClassSectionFragment>()

	const [{ data: myClassData }] = useClassByIdQuery({ variables: { classId: myClass?._id || "" }, pause: !myClass?._id })

	useEffect(() => {
		if (meData?.me && myClass?._id !== meData.me.classId) {
			setMyClass(meData.me.class)
			setMyClassSection(meData.me.classSection)
		}
	}, [meData])

	useEffect(() => {
		if (myClassData?.classById && myClass && myClassSection && (schoolClass?._id !== myClass?._id || classSection?._id !== myClassSection?._id)) {
			setSchoolClass(myClassData.classById)

			const _classSection = myClassData.classById.sections.find((s) => s._id === myClassSection._id)

			if (_classSection) {
				setClassSection(_classSection)
			}
		}
	}, [myClassData])

	useEffect(() => {
		if (classSection && !schoolClass?.sections.map((s) => s._id).includes(classSection._id)) {
			setClassSection(undefined)
		}
	}, [schoolClass])

	const [pagination, setPagination] = useState<AllStudentsQueryVariables["pagination"]>({ limit: 10, page: 1 })

	const [{ data, fetching }] = useAllStudentsQuery({
		variables: { pagination, classId: schoolClass?._id, classSectionId: classSection?._id },
	})

	const prevPage = () => {
		if (data?.allStudents.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.allStudents.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	const isMobile = useBreakpointValue({ base: true, sm: false })

	return (
		<VStack w="full" align="stretch">
			<HStack w="full" justify="space-between">
				<Heading as="h2" fontSize={isMobile ? "sm" : "md"} fontWeight="normal" color="text.500">
					Showing {data?.allStudents.total || "all"} students of{" "}
					<strong>
						{schoolClass?.name || "all"} {classSection?.name || ""}
					</strong>{" "}
					class{schoolClass ? "" : "es"}
				</Heading>
				{fetchingClasses ? (
					<Center>
						<VStack w="full" color="blackAlpha.800">
							<Text>Loading classes</Text>
							<Spinner size="sm" />
						</VStack>
					</Center>
				) : classesError ? (
					<Center>
						<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
							Failed loading classes, try reloading the page
						</Text>
					</Center>
				) : classesData?.classes.length ? (
					<HStack spacing={0}>
						<Select
							bg="white.500"
							roundedStart="xl"
							roundedEnd={schoolClass && schoolClass.sections?.length ? "none" : "xl"}
							border="none"
							_focus={{ border: "none" }}
							w="full"
							maxW="xs"
							placeholder="All"
							value={JSON.stringify(schoolClass)}
							onChange={(e) => {
								setSchoolClass(e.target.value ? JSON.parse(e.target.value) : undefined)
							}}
						>
							{classesData?.classes.map((c) => (
								<option key={c._id} value={JSON.stringify(c)}>
									{c.name}
								</option>
							))}
						</Select>
						{schoolClass && schoolClass.sections?.length && (
							<Select
								bg="white.500"
								roundedStart="none"
								roundedEnd="xl"
								border="none"
								_focus={{ border: "none" }}
								w="full"
								maxW="xs"
								placeholder="All"
								value={JSON.stringify(classSection)}
								onChange={(e) => {
									setClassSection(e.target.value ? JSON.parse(e.target.value) : undefined)
								}}
							>
								{schoolClass.sections.map((s) => (
									<option key={s.name} value={JSON.stringify(s)}>
										{s.name}
									</option>
								))}
							</Select>
						)}
					</HStack>
				) : (
					<Center>
						<Text fontSize="md" fontWeight="semibold" color="text.400">
							Couldn&apos;t find any classes.
						</Text>
					</Center>
				)}
			</HStack>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.allStudents.students && data?.allStudents.students.length > 0 ? (
				<StudentsTable
					students={data.allStudents.students}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.allStudents.hasPrevPage}
					hasNextPage={data.allStudents.hasNextPage}
					currentPage={data.allStudents.currentPage}
					totalPages={data.allStudents.totalPages}
				/>
			) : (
				<Center py="4">
					<Text fontSize="md" fontWeight="semibold" color="text.400">
						Couldn&apos;t find any students.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
