import { Center, Container, Heading, HStack, Spinner, Tag, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import React from "react"
import { useParams } from "react-router-dom"
import YouTube from "react-youtube"
import { DashboardHeader, Nav } from "../../components"
import { GRADIENT } from "../../constants"
import { useTrendingSkillByIdQuery } from "../../graphql"
import { useDrawer } from "../../hooks"

export const TrendingSkill: React.FC = () => {
	const { trendingSkillId = "" } = useParams<{ trendingSkillId: string }>()

	const [{ data, fetching, error }] = useTrendingSkillByIdQuery({
		variables: { trendingSkillId },
	})

	const { isShown } = useDrawer()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const opts = {
		height: isMobile ? "300px" : "300px",
		width: isMobile ? "350px" : "500px",
	}

	const youtube_parser = (url: any) => {
		const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
		const match = url.match(regExp)
		return match && match[7].length == 11 ? match[7] : false
	}

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 4}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					{fetching ? (
						<Center w="full" py="4">
							<Spinner color="text.400" />
						</Center>
					) : error ? (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.trendingSkillById ? (
						<VStack w="full" align="flex-start" spacing="4">
							<HStack w="full" py="4" align="flex-start">
								<VStack align="flex-start">
									<HStack spacing={6}>
										<Heading as="h1" fontSize="xl" color="text.500">
											{data.trendingSkillById.name}
										</Heading>
									</HStack>
									<HStack>
										<Tag m="1" colorScheme="purple" textTransform="capitalize">
											{data.trendingSkillById.topic.name}
										</Tag>
										<Tag m="1" colorScheme="purple">
											Age: {data.trendingSkillById.minAge}-{data.trendingSkillById.maxAge}
										</Tag>
									</HStack>
								</VStack>
							</HStack>

							<YouTube videoId={youtube_parser(data.trendingSkillById.links)} opts={opts} />

							{data.trendingSkillById.description && (
								<Text w={isMobile ? "full" : "lg"} fontSize="sm" whiteSpace="pre-line">
									{data.trendingSkillById.description}
								</Text>
							)}
						</VStack>
					) : (
						<Center w="full" py="4">
							<Text fontSize="md" fontWeight="semibold" color="text.400">
								Couldn&apos;t find the topic.
							</Text>
						</Center>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
