import { Button, Heading, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { ExtendedStudentFragment, PointsRedeemItemFragment, useRedeemPointsMutation } from "../../graphql"
import { PointsRedeemItemSelector } from "./PointsRedeemItemSelector"

export type RedeemPointsModalProps = {
	isOpen: boolean
	onClose: () => void
	student: ExtendedStudentFragment
}

export const RedeemPointsModal: React.FC<RedeemPointsModalProps> = ({ isOpen, onClose, student }) => {
	const [selectedItem, setSelectedItem] = useState<PointsRedeemItemFragment | undefined>(undefined)

	const [{ fetching, error, data }, redeemPoints] = useRedeemPointsMutation()

	const handleRedeemPoints = async () => {
		if (!selectedItem) {
			return
		}

		await redeemPoints({ pointsRedeemItemId: selectedItem._id })

		onClose()
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered scrollBehavior="inside">
			<ModalOverlay />

			<ModalContent bgColor="white.400">
				<ModalHeader>
					<VStack w="full" align="center" spacing={0}>
						<Heading fontSize="2xl" color="purple.500">
							Redeem Points
						</Heading>
						{selectedItem ? (
							<Text fontSize="sm" color="blackAlpha.600">
								You will have {(student.pointsBalance || 0) - selectedItem.points} points
							</Text>
						) : (
							<Text fontSize="sm" color="blackAlpha.600">
								You have {student.pointsBalance || 0} points
							</Text>
						)}
					</VStack>
				</ModalHeader>
				<ModalBody>
					<VStack alignItems="center">
						<VStack w="full" align="stretch">
							<PointsRedeemItemSelector onChange={setSelectedItem} student={student} />
							{error ? (
								<Text fontSize="sm" color="red.500" textAlign="center">
									{error.message.replace("[GraphQL] ", "")}
								</Text>
							) : (
								data &&
								!data.redeemPoints && (
									<Text fontSize="sm" color="red.500" textAlign="center">
										Some error occurred
									</Text>
								)
							)}
						</VStack>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<Button w="full" colorScheme="primary" isLoading={fetching} isDisabled={!selectedItem} onClick={handleRedeemPoints}>
						Redeem
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
