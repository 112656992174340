import { Center, Spinner } from "@chakra-ui/react"
import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { useMeQuery } from "../graphql/generated"
import { ChangePassword, ClassTeacher, Feed, Gallery, Holidays, LandingPage, Points, Profile, Resources, School, TrendingSkills } from "../pages"
import { FeedPostPage } from "../pages/Feed/FeedPost"
import { UnApprovedFeedPost } from "../pages/Feed/UnApprovedFeedPost"
import { GalleryById } from "../pages/Gallery/Gallery"
import { PrivacyPolicy } from "../pages/PrivacyPolicy"
import { RefundAndCancellationPolicy } from "../pages/RefundAndCancellationPolicy"
import { ResourcePage } from "../pages/Resources/Resource"
import { Staffs } from "../pages/Staffs"
import { Staff } from "../pages/Staffs/Staff"
import { Students } from "../pages/Students"
import { Student } from "../pages/Students/Student"
import { TermsAndConditions } from "../pages/TermsAndConditions"
import { TrendingSkill } from "../pages/TrendingSkills/TrendingSkill"
import { Uaoth } from "../pages/Uaoth"

export const RootRouter: React.FC = () => {
	const [{ data, fetching }] = useMeQuery()

	if (fetching) {
		return (
			<Center w="full" h="100vh">
				<Spinner />
			</Center>
		)
	}

	if (!data?.me) {
		return (
			<Routes>
				<Route path="/" element={<LandingPage />} />
				<Route path="/uaoth" element={<Uaoth />} />
				<Route path="/termsAndConditions" element={<TermsAndConditions />} />
				<Route path="/privacy" element={<PrivacyPolicy />} />
				<Route path="/refundAndCancellation" element={<RefundAndCancellationPolicy />} />
				<Route path="*" element={<Navigate to="/" replace />} />
			</Routes>
		)
	}

	return (
		<Routes>
			<Route path="/" element={<Navigate to="/profile" replace />} />
			<Route path="/uaoth" element={<Uaoth />} />
			<Route path="/feed/:feedPostId" element={<FeedPostPage />} />
			<Route path="/feed/unApproved" element={<UnApprovedFeedPost />} />
			<Route path="/feed" element={<Feed />} />
			<Route path="/school" element={<School />} />
			<Route path="/classTeacher" element={<ClassTeacher />} />
			<Route path="/school" element={<School />} />
			<Route path="/profile" element={<Profile />} />
			<Route path="/staff/:staffId" element={<Staff />} />
			<Route path="/staff" element={<Staffs />} />
			<Route path="/students/:studentId" element={<Student />} />
			<Route path="/students" element={<Students />} />
			<Route path="/changePassword" element={<ChangePassword />} />
			<Route path="/resources/:resourceId" element={<ResourcePage />} />
			<Route path="/resources" element={<Resources />} />
			<Route path="/trendingSkills/:trendingSkillId" element={<TrendingSkill />} />
			<Route path="/trendingSkills" element={<TrendingSkills />} />
			<Route path="/calendar" element={<Holidays />} />
			<Route path="/gallery/:galleryId" element={<GalleryById />} />
			<Route path="/gallery" element={<Gallery />} />
			<Route path="/points" element={<Points />} />
		</Routes>
	)
}
