import { Box, ButtonGroup, HStack, Icon, IconButton, Link, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedStaffFragment } from "../../graphql"
import { StaffTag } from "./StaffTag"

export type StaffsTableProps = {
	staffs: ExtendedStaffFragment[]
	prevPage: () => void
	nextPage: () => void
	hasPrevPage: boolean
	hasNextPage: boolean
	currentPage: number
	totalPages: number
}

export const StaffsTable: React.FC<StaffsTableProps> = ({ staffs, prevPage, nextPage, hasPrevPage, hasNextPage, currentPage, totalPages }) => {
	return (
		<VStack w="full" spacing="0">
			<HStack alignSelf="flex-end">
				<Text fontSize="xs" color="text.400">
					{currentPage}/{totalPages}
				</Text>
				<ButtonGroup size="xs" p="4">
					<IconButton aria-label="prev-btn" onClick={prevPage} isDisabled={!hasPrevPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowLeft} {...props} />} color="text.500" />
					</IconButton>
					<IconButton aria-label="next-btn" onClick={nextPage} isDisabled={!hasNextPage} colorScheme="whiteAlpha">
						<Icon as={(props: any) => <FontAwesomeIcon icon={faArrowRight} {...props} />} color="text.500" />
					</IconButton>
				</ButtonGroup>
			</HStack>
			<Box w="full" overflow="auto">
				<Table overflow="hidden" colorScheme="blackAlpha" bg="white" rounded="xl">
					<Thead>
						<Tr>
							<Th textTransform="capitalize">Staff</Th>
							<Th textTransform="capitalize">Department</Th>
							<Th textTransform="capitalize">Tag</Th>
							<Th textTransform="capitalize">Phone</Th>
							<Th textTransform="capitalize">Email</Th>
							<Th textTransform="capitalize">Linked since</Th>
						</Tr>
					</Thead>
					<Tbody>
						{staffs.map((staff) => (
							<Tr key={staff._id} _hover={{ bg: "gray.100" }} transition="background-color 200ms ease-in" cursor="pointer" fontSize="sm">
								<Td>
									<StaffTag staff={staff} />
								</Td>
								<Td>
									<Link as={NavLink} to={`/departments/${staff.department._id}`}>
										{staff.department.name}
									</Link>
								</Td>
								<Td>{staff.tag?.tagDecimalId || "-"}</Td>
								<Td>{staff.phone}</Td>
								<Td>{staff.email || "-"}</Td>
								<Td>{staff.createdAt && format(new Date(staff.createdAt), "PP")}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</VStack>
	)
}
