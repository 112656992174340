import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useMyPointsRedeemTransactionsQuery } from "../../graphql"
import { StudentPointsRedeemTransactionListItem } from "./StudentPointsRedeemTransactionListItem"

export const StudentPointsRedeemTransactionList: React.FC = () => {
	const [{ data, fetching, error }] = useMyPointsRedeemTransactionsQuery()

	return (
		<VStack w="full" align="stretch">
			{fetching ? (
				<Center w="full" py="4">
					<Spinner color="text.400" />
				</Center>
			) : error ? (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="text.400">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.myPointsRedeemTransactions.length ? (
				<VStack spacing={4}>
					{data.myPointsRedeemTransactions.map((transaction) => (
						<StudentPointsRedeemTransactionListItem key={transaction._id} transaction={transaction} />
					))}
				</VStack>
			) : (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="text.400">
						Couldn&apos;t find any transactions.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
