import { Center, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useState } from "react"
import { AllAttendancesOfMeQueryVariables, useAllAttendancesOfMeQuery } from "../../graphql"
import { StudentAttendancesTable } from "./StudentAttendancesTable"

export const AllAttendancesOfMe: React.FC = () => {
	const [pagination, setPagination] = useState<AllAttendancesOfMeQueryVariables["pagination"]>({ limit: 30, page: 1 })

	const [{ data, fetching }] = useAllAttendancesOfMeQuery({
		variables: { pagination },
	})

	const prevPage = () => {
		if (data?.allAttendancesOfMe.hasPrevPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) - 1 }))
		}
	}

	const nextPage = () => {
		if (data?.allAttendancesOfMe.hasNextPage) {
			setPagination((prev) => ({ ...prev, page: (prev.page || 0) + 1 }))
		}
	}

	return (
		<VStack w="full" align="stretch">
			<Heading as="h2" fontSize="sm" fontWeight="normal" color="text.500">
				Showing {data?.allAttendancesOfMe.attendances?.length || 0} of {data?.allAttendancesOfMe.total || "all"} attendances
			</Heading>
			{fetching ? (
				<Center py="4">
					<Spinner color="text.400" />
				</Center>
			) : data?.allAttendancesOfMe.attendances && data?.allAttendancesOfMe.attendances.length > 0 ? (
				<StudentAttendancesTable
					attendances={data.allAttendancesOfMe.attendances}
					prevPage={prevPage}
					nextPage={nextPage}
					hasPrevPage={data.allAttendancesOfMe.hasPrevPage}
					hasNextPage={data.allAttendancesOfMe.hasNextPage}
					currentPage={data.allAttendancesOfMe.currentPage}
					totalPages={data.allAttendancesOfMe.totalPages}
				/>
			) : (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="red.600">
						Attendances not available
					</Text>
				</Center>
			)}
		</VStack>
	)
}
