import { Button, FormControl, FormErrorIcon, FormErrorMessage, FormLabel, Icon, Input, SlideFade, Text, useToast, VStack } from "@chakra-ui/react"
import { FormikHelpers, useFormik } from "formik"
import React from "react"
import { ArrowRight } from "react-feather"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { SESSION_ID_KEY } from "../../constants"
import { LoginMutationVariables, useLoginMutation } from "../../graphql"

const validationSchema = Yup.object({
	username: Yup.string().required().label("Username"),
	password: Yup.string().required().label("Password"),
})

export const LoginForm: React.FC = () => {
	const [{ fetching }, login] = useLoginMutation()

	const toast = useToast()

	const navigate = useNavigate()

	const handleSubmit = async ({ username, password }: LoginMutationVariables, helpers: FormikHelpers<LoginMutationVariables>) => {
		const { error, data } = await login({ username, password })

		if (error) {
			return toast({
				title: "Login Error",
				description: error.message,
				status: "error",
			})
		}

		if (data?.login.errors) {
			return data?.login.errors.forEach(({ field, error }) => {
				return helpers.setFieldError(field, error)
			})
		}

		if (data?.login.sessionId) {
			localStorage.setItem(SESSION_ID_KEY, data.login.sessionId)
		}

		return navigate("/", { replace: true })
	}

	const formik = useFormik<LoginMutationVariables>({
		initialValues: { username: "", password: "" },
		validationSchema,
		onSubmit: handleSubmit,
	})

	return (
		<form onSubmit={formik.handleSubmit}>
			<VStack align="flex-start" spacing="4">
				<FormControl isInvalid={Boolean(formik.errors.username && formik.touched.username)}>
					<SlideFade in={Boolean(formik.values.username)} unmountOnExit>
						<FormLabel htmlFor="email" fontSize="sm">
							Username
						</FormLabel>
					</SlideFade>
					<Input
						placeholder="Username"
						border="none"
						_focus={{ border: "none" }}
						maxW="sm"
						bg="white.500"
						rounded="xl"
						py="1"
						autoComplete="off"
						{...formik.getFieldProps("username")}
					/>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{formik.errors.username}</Text>
					</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.errors.password && formik.touched.password)}>
					<SlideFade in={Boolean(formik.values.password)} unmountOnExit>
						<FormLabel htmlFor="password" fontSize="sm">
							Password
						</FormLabel>
					</SlideFade>
					<Input
						type="password"
						placeholder="Password"
						border="none"
						_focus={{ border: "none" }}
						maxW="sm"
						bg="white.500"
						rounded="xl"
						py="1"
						{...formik.getFieldProps("password")}
					/>
					<FormErrorMessage>
						<FormErrorIcon />
						<Text>{formik.errors.password}</Text>
					</FormErrorMessage>
				</FormControl>
				<Button type="submit" colorScheme="primary" size="md" w="full" rightIcon={<Icon as={ArrowRight} />} isLoading={fetching}>
					Sign in
				</Button>
			</VStack>
		</form>
	)
}
