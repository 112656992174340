import { Button, ButtonProps } from "@chakra-ui/react"
import React from "react"
import { NavLink, NavLinkProps, useLocation } from "react-router-dom"

export type NavButtonProps = ButtonProps & Pick<NavLinkProps, "to">

export const NavButton: React.FC<NavButtonProps> = ({ children, ...props }) => {
	const isActive = new RegExp(`^${props.to}(/|)`).test(useLocation().pathname)

	return (
		<Button
			as={NavLink}
			w="full"
			// maxW={{ base: "3xs", lg: "2xs" }}
			maxW={{ base: "3xs", lg: children!.toString().length > 12 ? "xs" : "2xs" }}
			rounded="none"
			roundedRight="2xl"
			py="4"
			pl={{ base: 4, lg: 24 }}
			justifyContent="flex-start"
			fontSize="md"
			fontWeight="bold"
			bg={isActive ? "white.500" : "transparent"}
			color={isActive ? "text.500" : "white.500"}
			_focus={{
				bg: "whiteAlpha.300",
				color: "text.500",
				shadow: "none",
			}}
			_hover={{
				bg: "whiteAlpha.300",
				color: "white.500",
				textDecor: "none",
			}}
			transition="all 200ms ease-in-out"
			size="lg"
			gap="8px"
			{...props}
		>
			{children}
		</Button>
	)
}
