import { HStack, Icon, VStack } from "@chakra-ui/react"
import { faBarChart, faBookReader, faCalendar, faCoins, faGrip, faPhotoVideo, faSchool, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Logo } from "../common"
import { NavButton } from "./NavButton"

export const Nav: React.FC = () => {
	return (
		<VStack
			pos="absolute"
			top="0"
			left="0"
			w={{ md: "280px", lg: "360px" }}
			h="100vh"
			bg="linear-gradient(165.59deg, #F7F6F4 0%, #F3F3F4 9.99%, #7F84EA 30.98%, #7F84EA 61.45%, #61C6FF 95.92%)"
			align="stretch"
			justify="space-between"
		>
			<VStack align="stretch" overflow="hidden">
				<HStack pl={{ base: 2, lg: 8 }} py="6">
					<Logo />
				</HStack>
				<VStack spacing={1} align="flex-start" overflow="auto" pt="16" pb="16">
					<NavButton to="/profile" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faUser} {...props} />} />}>
						Profile
					</NavButton>

					<NavButton to="/feed" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faGrip} {...props} />} />}>
						Feed
					</NavButton>

					<NavButton to="/school" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faSchool} {...props} />} />}>
						School
					</NavButton>

					<NavButton to="/points" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faCoins} {...props} />} />}>
						Points
					</NavButton>

					<NavButton to="/resources" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faBookReader} {...props} />} />}>
						Learning Resources
					</NavButton>
					<NavButton to="/trendingSkills" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faBarChart} {...props} />} />}>
						Trending Skills
					</NavButton>
					<NavButton to="/calendar" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faCalendar} {...props} />} />}>
						Calendar
					</NavButton>
					<NavButton to="/gallery" leftIcon={<Icon as={(props: any) => <FontAwesomeIcon icon={faPhotoVideo} {...props} />} />}>
						Gallery
					</NavButton>
				</VStack>
			</VStack>
		</VStack>
	)
}
