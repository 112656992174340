import { Avatar, Heading, HStack, Link, VStack } from "@chakra-ui/react"
import React from "react"
import { NavLink } from "react-router-dom"
import { ExtendedStudentFragment } from "../../graphql"

export type StudentTagProps = {
	student: ExtendedStudentFragment
}

export const StudentTag: React.FC<StudentTagProps> = ({ student }) => {
	return (
		<HStack as={(props: any) => <Link as={NavLink} {...props} />} to={`/students/${student._id}`}>
			<Avatar src={student.picture?.url} name={student.name} size="sm" />
			<VStack w="full" align="stretch" spacing={0}>
				<Heading fontSize="sm" lineHeight="100%">
					{student.name}
				</Heading>
				<Heading fontSize="xs" color="blackAlpha.600">
					@{student.username}
				</Heading>
			</VStack>
		</HStack>
	)
}
