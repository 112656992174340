import {
	Center,
	Container,
	Heading,
	HStack,
	Icon,
	Input,
	InputGroup,
	InputRightAddon,
	Select,
	Spinner,
	Stack,
	Text,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Nav, DashboardHeader } from "../../components"
import { AllResources, SearchResultResources } from "../../components/resource"
import { GRADIENT } from "../../constants"
import {
	ClassFragment,
	ClassSectionFragment,
	ExtendedClassFragment,
	ExtendedClassSectionFragment,
	SubjectFragment,
	TopicFragment,
	useAllSubjectsQuery,
	useAllTopicsQuery,
	useClassByIdQuery,
	useClassesQuery,
	useMeQuery,
} from "../../graphql"
import { useDrawer } from "../../hooks"

export const Resources: React.FC = () => {
	const { isShown } = useDrawer()

	const [searchKeyword, setSearchKeyword] = useState("")

	const [{ data: classesData, fetching: fetchingClasses, error: classesError }] = useClassesQuery()

	const [{ data: subjectsData, fetching: fetchingSubjects, error: subjectsError }] = useAllSubjectsQuery()

	const [{ data: topicsData }] = useAllTopicsQuery()

	const [{ data: meData }] = useMeQuery()

	const [schoolClass, setSchoolClass] = useState<ExtendedClassFragment | undefined>()

	const [classSection, setClassSection] = useState<ExtendedClassSectionFragment>()

	const [subject, setSubject] = useState<SubjectFragment>()

	const [topic, setTopic] = useState<TopicFragment>()

	const [subTopic, setSubTopic] = useState<string>()

	const isMobile = useBreakpointValue({ base: true, sm: false })

	const [myClass, setMyClass] = useState<ClassFragment>()
	const [myClassSection, setMyClassSection] = useState<ClassSectionFragment>()

	useEffect(() => {
		if (meData?.me && meData.me.class && meData.me.classSection && myClass?._id !== meData.me.classId) {
			setMyClass(meData.me.class)
			setMyClassSection(meData.me.classSection)
		}
	}, [meData])

	const [{ data: myClassData }] = useClassByIdQuery({ variables: { classId: myClass?._id || "" }, pause: !myClass?._id })

	useEffect(() => {
		if (myClassData?.classById && myClass && myClassSection && (schoolClass?._id !== myClass?._id || classSection?._id !== myClassSection?._id)) {
			setSchoolClass(myClassData.classById)

			const _classSection = myClassData.classById.sections.find((s) => s._id === myClassSection._id)

			if (_classSection) {
				setClassSection(_classSection)
			}
		}
	}, [myClassData])

	useEffect(() => {
		if (classSection && !schoolClass?.sections.map((s) => s._id).includes(classSection._id)) {
			setClassSection(undefined)
		}
	}, [schoolClass])

	return (
		<Container minH="100vh" w="full" maxW="1440px" p="0">
			<HStack pos="relative" w="full">
				{!isMobile && <Nav />}
				<VStack
					pos="relative"
					marginStart={0}
					marginInlineStart="0 !important"
					marginLeft={{
						base: 0,
						sm: isShown ? "240px !important" : 0,
						lg: isShown ? "320px !important" : 0,
					}}
					transition="all 200ms ease-in-out"
					zIndex={9}
					w="full"
					h="100vh"
					roundedStart={!isMobile && isShown ? "3xl" : "none"}
					bg={GRADIENT}
					align="flex-start"
					overflowY="auto"
					p={isMobile ? 2 : 4}
					pb={isMobile ? 16 : 0}
					spacing={isMobile ? 4 : 6}
				>
					<DashboardHeader />

					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
						<VStack align="flex-start">
							<Heading as="h1" fontSize="xl" color="text.500">
								Resources
							</Heading>
							<Heading as="h2" fontSize="xs" fontWeight="normal" color="text.400" maxW="xs">
								{
									// TODO: add sub heading
								}
							</Heading>
						</VStack>
						<InputGroup maxW="sm" bg="white.500" rounded="xl" py="1">
							<Input
								placeholder="Search resources"
								border="none"
								_focus={{ border: "none" }}
								onChange={(e) => setSearchKeyword(e.target.value)}
							/>
							<InputRightAddon bg="transparent" border="none">
								<Icon as={(props: any) => <FontAwesomeIcon icon={faSearch} {...props} />} color="text.100" fontSize="lg" />
							</InputRightAddon>
						</InputGroup>
					</Stack>

					<Stack w="full" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between" spacing={4}>
						<Stack w="lg" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
							{fetchingClasses ? (
								<Center>
									<VStack w="full" color="blackAlpha.800">
										<Text>Loading classes</Text>
										<Spinner size="sm" />
									</VStack>
								</Center>
							) : classesError ? (
								<Center>
									<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
										Failed loading classes, try reloading the page
									</Text>
								</Center>
							) : classesData?.classes.length ? (
								<HStack spacing={0}>
									<VStack>
										<Text>Class</Text>
										<Select
											bg="white.500"
											roundedStart="xl"
											roundedEnd={schoolClass && schoolClass.sections?.length ? "none" : "xl"}
											border="none"
											_focus={{ border: "none" }}
											w="full"
											maxW="xs"
											placeholder="All"
											value={JSON.stringify(schoolClass)}
											onChange={(e) => {
												setSchoolClass(e.target.value ? JSON.parse(e.target.value) : undefined)
											}}
										>
											{classesData?.classes.map((c) => (
												<option key={c._id} value={JSON.stringify(c)}>
													{c.name}
												</option>
											))}
										</Select>
									</VStack>
									{schoolClass && schoolClass.sections?.length && (
										<VStack>
											<Text>Section</Text>
											<Select
												bg="white.500"
												roundedStart="none"
												roundedEnd="xl"
												border="none"
												_focus={{ border: "none" }}
												w="full"
												maxW="xs"
												placeholder="All"
												value={JSON.stringify(classSection)}
												onChange={(e) => {
													setClassSection(e.target.value ? JSON.parse(e.target.value) : undefined)
												}}
											>
												{schoolClass.sections.map((s) => (
													<option key={s.name} value={JSON.stringify(s)}>
														{s.name}
													</option>
												))}
											</Select>
										</VStack>
									)}
								</HStack>
							) : (
								<Center>
									<Text fontSize="md" fontWeight="semibold" color="text.400">
										Couldn&apos;t find any classes.
									</Text>
								</Center>
							)}
						</Stack>

						<Stack w="lg" direction={isMobile ? "column" : "row"} align="flex-start" justify="space-between">
							{fetchingSubjects ? (
								<Center>
									<VStack w="full" color="blackAlpha.800">
										<Text>Loading subjects</Text>
										<Spinner size="sm" />
									</VStack>
								</Center>
							) : subjectsError ? (
								<Center>
									<Text fontSize="md" fontWeight="semibold" color="text.400" textAlign="center">
										Failed loading subjects, try reloading the page
									</Text>
								</Center>
							) : subjectsData?.allSubjects.length ? (
								<HStack spacing={0}>
									<VStack>
										<Text>Subject</Text>
										<Select
											bg="white.500"
											roundedStart="xl"
											roundedEnd={subject ? "none" : "xl"}
											border="none"
											_focus={{ border: "none" }}
											w="full"
											maxW="xs"
											placeholder="All"
											value={JSON.stringify(subject)}
											onChange={(e) => {
												setSubject(e.target.value ? JSON.parse(e.target.value) : undefined)
											}}
										>
											{subjectsData?.allSubjects.map((s) => (
												<option key={s._id} value={JSON.stringify(s)}>
													{s.name}
												</option>
											))}
										</Select>
									</VStack>
									{subject && (
										<VStack>
											<Text>Topic</Text>
											<Select
												bg="white.500"
												roundedStart="none"
												roundedEnd={topic ? "none" : "xl"}
												border="none"
												_focus={{ border: "none" }}
												w="full"
												maxW="xs"
												placeholder="All"
												value={JSON.stringify(topic)}
												onChange={(e) => {
													setTopic(e.target.value ? JSON.parse(e.target.value) : undefined)
												}}
												textTransform="capitalize"
											>
												{topicsData?.allTopics.map((t) => (
													<option key={t.name} value={JSON.stringify(t)}>
														{t.name}
													</option>
												))}
											</Select>
										</VStack>
									)}
									{subject && topic && topic?.subTopics?.length && (
										<VStack>
											<Text>Sub Topic</Text>
											<Select
												bg="white.500"
												roundedStart="none"
												roundedEnd="xl"
												border="none"
												_focus={{ border: "none" }}
												w="full"
												maxW="xs"
												placeholder="All"
												value={JSON.stringify(subTopic)}
												onChange={(e) => {
													setSubTopic(e.target.value ? JSON.parse(e.target.value) : undefined)
												}}
												textTransform="capitalize"
											>
												{topic?.subTopics.map((t) => (
													<option key={t} value={JSON.stringify(t)}>
														{t}
													</option>
												))}
											</Select>
										</VStack>
									)}
								</HStack>
							) : (
								<Center>
									<Text fontSize="md" fontWeight="semibold" color="text.400">
										Couldn&apos;t find any topics.
									</Text>
								</Center>
							)}
						</Stack>
					</Stack>

					{searchKeyword ? (
						<SearchResultResources
							searchKeyword={searchKeyword}
							classId={schoolClass?._id}
							classSectionId={classSection?._id}
							subjectId={subject?._id}
							topicId={topic?._id}
							subTopic={subTopic}
						/>
					) : (
						<AllResources
							classId={schoolClass?._id}
							classSectionId={classSection?._id}
							subjectId={subject?._id}
							topicId={topic?._id}
							subTopic={subTopic}
						/>
					)}
				</VStack>
			</HStack>
		</Container>
	)
}
