import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useMyPenaltiesQuery } from "../../graphql"
import { StudentPenaltyListItem } from "./StudentPenaltyListItem"

export const StudentPenaltiesList: React.FC = () => {
	const [{ data, fetching, error }] = useMyPenaltiesQuery()

	return (
		<VStack w="full" align="stretch">
			{fetching ? (
				<Center w="full" py="4">
					<Spinner color="text.400" />
				</Center>
			) : error ? (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="text.400">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.myPenalties.length ? (
				<VStack spacing={4}>
					{data.myPenalties.map((penalty) => (
						<StudentPenaltyListItem key={penalty._id} penalty={penalty} />
					))}
				</VStack>
			) : (
				<Center w="full" py="4">
					<Text fontSize="md" fontWeight="semisemibold" color="text.400">
						Couldn&apos;t find any penalties.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
